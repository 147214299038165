import React from 'react'
import { ApiService } from '../../../../services/ApiService';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'primereact/button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoFileTraySharp } from 'react-icons/io5';
import UploadFile from '../../../../services/UploadFile';
import DeleteFile from '../../../../services/DeleteFile';

const FormModalAds = ({ Hide, modalAds_, setModalAds }) => {
    const api = new ApiService();
    let imagen = modalAds_?.url;
    let archivo = modalAds_?.doc;
    ///Apartado para la imagen del producto
    const [photoURL, setPhotoURL] = useState(modalAds_?.url)
    const [fileImagen, setFileImagen] = useState(null);

    ///Apartado para la ficha del producto
    const [AdjuntoUrl, setAdjuntoUrl] = useState(modalAds_?.doc);
    const [fileDocumento, setFileDocumento] = useState(null);


    ///Evento para cambiar la imagen del producto
    const handleChangeImagen = (e) => {
        const file = e.target.files[0]
        /// verificiaremos si se ha seleccionado una foto para la subida
        if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            setFileImagen(file);
            /// seteamos la imagen para que esta se muestre al momento del cambio
            setPhotoURL(URL.createObjectURL(file));
        } else if (!file) {
            toast.error('Elige una fotografía para continuar.')
        }
        /// validamos si es una foto
        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            toast.error('Formato de archivo no válido')
            return false;
        }
    }
    /// para manipular la ficha tecnica
    const handleChangeDoc = (e) => {
        const file = e.target.files[0]
        /// verificiaremos si se ha seleccionado una foto para la subida
        if (file && file.name.match(/\.(pdf)$/)) {
            setFileDocumento(file);
            /// seteamos la imagen para que esta se muestre al momento del cambio
            setAdjuntoUrl(URL.createObjectURL(file));
        } else if (!file) {
            toast.error('Elige un pdf para continuar.')
        }
        /// validamos si es una foto
        if (!file.name.match(/\.(pdf)$/)) {
            toast.error('Formato de archivo no válido')
            return false;
        }
    }



    ///para subir los archivos luego de la actualizacion en la db
    const SubmitChangeUploads = async () => {
        ///En caso la imagen se mantenga la dejaremos tal cual
        if (modalAds_?.url === photoURL) {

        } else {
            if (fileImagen) {
                const nombreImagen = fileImagen?.name;
                imagen = await UploadFile(fileImagen, `modal_ads/${nombreImagen}`);

                if (modalAds_?.url) {
                    ///Borramos la antigua imagen 
                    try {
                        const urlParts = modalAds_?.url?.split('/');

                        // Obtenemos la última parte de la URL que contiene el nombre del archivo
                        const filenameWithToken = urlParts[urlParts.length - 1];

                        // Usamos la función `split()` nuevamente para separar el nombre del archivo del token usando '?alt=media&token=' como separador
                        const prevImagen = filenameWithToken.split('?alt=media&token=')[0];
                        await DeleteFile(`modal_ads/${prevImagen}`)
                    } catch (error) {

                    }
                }
            }
        }
        ///haremos lo mismo con el documento
        if (modalAds_?.doc === AdjuntoUrl) {

        } else {
            if (fileDocumento) {
                const nombreDocumento = fileDocumento?.name;
                archivo = await UploadFile(fileDocumento, `modal_ads/${nombreDocumento}`);

                if (modalAds_?.doc) {
                    ///Borramos la antigua imagen 
                    try {
                        const urlParts = modalAds_?.doc?.split('/');

                        // Obtenemos la última parte de la URL que contiene el nombre del archivo
                        const filenameWithToken = urlParts[urlParts.length - 1];

                        // Usamos la función `split()` nuevamente para separar el nombre del archivo del token usando '?alt=media&token=' como separador
                        const prevDocumento = filenameWithToken.split('?alt=media&token=')[0];
                        await DeleteFile(`modal_ads/${prevDocumento}`)
                    } catch (error) {

                    }
                }

            }
        }

        ///Editamos el modal
        let modalEdit = {
            Id: modalAds_?.Id, url: imagen, doc: archivo
        };

        api.EditModalAd(modalEdit).then((response) => {
            toast.success(response);
            Hide();
        }).catch((error) => {
            toast.error("Ha ocurrido un error a la hora de modificar el modal de promociones");
            console.log(error);
        })
    }

    return (
        <div>
            <div className='container-form-user'>
                <div className='row mt-2 p-3'>
                    <div className="col-md-6 text-center">
                        <p className='fw-bold'>Imágen del modal de promoción</p>
                        <div>
                            <label htmlFor="ProductoImagen">
                                <input
                                    accept='image/*'
                                    id='ProductoImagen'
                                    type="file"
                                    style={{ display: 'none', cursor: 'pointer' }}
                                    onChange={handleChangeImagen}
                                />
                                <LazyLoadImage
                                    effect="opacity"
                                    alt="Account-picture"
                                    src={photoURL ? photoURL : process.env.PUBLIC_URL + '/vacio.jpg'}
                                    key={modalAds_?.id} width="300" height="300" />
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <p className='fw-bold texto-ficha'>Documento del modal</p>
                        <label htmlFor='ProductoFicha'>
                            <input
                                accept="application/pdf"
                                id='ProductoFicha'
                                type="file"
                                style={{ display: 'none', cursor: 'pointer' }}
                                onChange={handleChangeDoc}
                            />
                            <p className='text-success fw-bolder'><IoFileTraySharp className='me-2' />Documento PDF del modal</p>
                        </label>
                        {
                            AdjuntoUrl ? <div>
                                <iframe src={AdjuntoUrl} style={{ width: '400px', height: "300px" }} frameborder="0"></iframe>
                            </div> : ''
                        }
                    </div>
                </div>
            </div>
            <div className='footer-modal'>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => Hide()} className="p-button-text p-button-danger" />
                <Button label={modalAds_?.Id ? 'Editar' : 'Guardar'} onClick={() => SubmitChangeUploads()} icon="pi pi-check" autoFocus className='p-button-success' />
            </div>
        </div>
    )
}

export default FormModalAds
