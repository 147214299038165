import { FacebookAuthProvider, linkWithPopup } from 'firebase/auth';
import React from 'react'
import toast from 'react-hot-toast';
import { auth } from '../Firebase/firebaseConfig';

const LinkAccountFacebook = () => {
    return new Promise(async (resolve, reject) => {
        const providerGoogle = new FacebookAuthProvider();
        try {
            linkWithPopup(auth.currentUser, providerGoogle).then((result) => {
                // Accounts successfully linked.
                toast.success("Las cuentas se han enlazado correctamente.")
                console.log(result.user);
                resolve(result.user)
            }).catch((error) => {
                switch (error.code) {
                    case "auth/credential-already-in-use":
                        toast.error("Las cuentas ya se encuentran enlazadas o esta cuenta pertenece a alguien mas.")
                        break;
                    default:
                        toast.error("Ha ocurrido un error a la hora de enlazar las cuentas")
                        break;
                }
            });
        } catch (error) {
            reject(error);
        }
    })
}

export default LinkAccountFacebook