import { updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { IoCameraReverseOutline, IoCameraSharp, IoCloseOutline } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactModal from '../../components/ContactModal/ContactModal';
import db from '../../Firebase/firebaseConfig';
import { UserAuth } from '../../services/AuthContext';
import DeleteFile from '../../services/DeleteFile';
import ModalmodPic from './Left-side/ModalmodPic';
import ModalmodPortada from './Left-side/ModalmodPortada';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
///llamamos a la portada que estara por defecto
import portada from './portada2.jpg'
import { useGlobalState } from '../../services/GlobalState';


const HeaderAccount = ({ usuario, setUsuario }) => {
  const { user } = UserAuth();
  const [options, setOptions] = useState(false);
  const [optionsPortada, setOptionsPortada] = useState(false);
  /// Estado para el modal, abrir y cerrar
  const [Estadomodal, setEstadoModal] = useState(false);
  const [EstadomodalPortada, setEstadoModalPortada] = useState(false);
  /// estilo para la foto de portada del usuario

  /// seteamos la foto de portada como fondo para la portada de la cuenta
  const estiloAccount = {
    backgroundImage: `url(${usuario?.fotoPortada === '' && !usuario?.fotoPortada ? portada : usuario?.fotoPortada})`,
  }
  const ShowOptionsPhoto = () => {
    if (user?.emailVerified) {
      setOptions(!options);
    } else {
      toast.error('Verifique su cuenta primero para modificar su foto.')
    }
  }
  const ShowOptionsPortada = () => {
    if (user?.emailVerified) {
      setOptionsPortada(!optionsPortada)
    } else {
      toast.error('Verifique su cuenta primero para modificar su portada.')
    }
  }
  // funcion para modificar la foto del usuario
  const OpenModalModify = () => {
    setOptions(!options);
    setEstadoModal(!Estadomodal)
    document.body.style.overflow = 'hidden';
  }

  const OpenmodalModifyPortada = () => {
    setOptionsPortada(!optionsPortada)
    setEstadoModalPortada(!EstadomodalPortada)
    document.body.style.overflow = 'hidden';
  }

  /// funcion que borrara la foto de la cuenta
  const DeletePhoto = async () => {
    if (user?.photoURL || user?.photoURL != null) {
      const prevImage = user?.photoURL?.split(`${user?.uid}%2F`)[1]?.split('?')[0];
      if (prevImage) {
        /// nuestra referencia al usuario para la modificacion
        const userRef = doc(db, "users", user?.uid);
        try {
          await DeleteFile(`profile/${user?.uid}/${prevImage}`)
          /// hacemos la subida del archivo y el cambio de fotografia
          await updateProfile(user, {
            photoURL: ""
          });
          /// actualizamos en la base de datos
          await updateDoc(userRef, {
            "fotoPerfil": "",
          });
          /// seteamos en la variable de sesion y el estado global
          const new_user = { ...usuario, fotoPerfil: "" }
          setUsuario(new_user);
          localStorage.setItem("user", JSON.stringify(new_user));
          toast.success('Se ha eliminado la foto de la cuenta')
        } catch (error) {
          toast.error('Ha ocurrido un error en el proceso de borrado.')
        }
      } else {
        toast.error('Su cuenta no posee ninguna foto')
      }
    }
    setOptions(!options);
  }

  /// borrar la portada la cuenta
  const DeletePortada = async () => {
    console.log(usuario.fotoPortada);
    if (usuario?.fotoPortada && usuario?.fotoPortada !== '') {
      const prevImage = usuario?.fotoPortada?.split(`${usuario?.id}%2F`)[1]?.split('?')[0];
      if (prevImage) {
        /// nuestra referencia al usuario para la modificacion
        const userRef = doc(db, "users", usuario?.id);
        try {
          await DeleteFile(`profile/${usuario?.id}/${prevImage}`)
          /// hacemos la subida del archivo y el cambio de fotografia
          /// actualizamos en la base de datos
          await updateDoc(userRef, {
            "fotoPortada": "",
          });
          /// seteamos un nuevo objeto con la copia que tenemos con la nueva foto de portada
          const new_user = { ...usuario, fotoPortada: '' }
          setUsuario(new_user);
          localStorage.setItem("user", JSON.stringify(new_user));

          toast.success('Se ha eliminado la portada de la cuenta.')
        } catch (error) {
          toast.error('Ha ocurrido un error en el proceso de borrado.')
        }
      } else {
        toast.error('Su cuenta no posee ninguna portada.')
      }
    } else {
      toast.error('Su cuenta no posee ninguna portada.')
    }
    setOptionsPortada(!optionsPortada);
  }

  /// Opcion que mostrara un pequeño mensaje de advertencia de la accion
  const DeleteConfirmFoto = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Estás seguro de borrar la foto de tu cuenta?',
      acceptLabel: "Si, estoy seguro",
      acceptIcon: 'pi pi-check',
      rejectLabel: 'No, cancelar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => DeletePhoto(),
      reject: () => setOptions(!options),
    });
  }

  /// agregaremos una confirmacion a la hora de eliminar la portada
  const DeleteConfirmPortada = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Estás seguro de borrar la portada de tu cuenta?',
      acceptLabel: "Si, estoy seguro",
      acceptIcon: 'pi pi-check',
      rejectLabel: 'No, cancelar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => DeletePortada(),
      reject: () => setOptionsPortada(!optionsPortada)
    });
  }

  useEffect(() => {
    return (() => {
      usuario = null;
    })
  }, [])

  return (
    <>
      <div className="header-card-account" style={estiloAccount}>
        <ConfirmPopup />
        <IoCameraSharp color='#272727' size="2rem" className="btn-change-photoPortada" onClick={() => ShowOptionsPortada()}></IoCameraSharp>
        {
          optionsPortada ? <div className="select-menu" >
            <ul className="options-menu-Pportada">
              <div className='option-text optionphoto' onClick={() => OpenmodalModifyPortada()}>
                <span className="option-text"  >
                  <IoCameraReverseOutline color='#272727' size="1.2rem"></IoCameraReverseOutline> <span>Modificar portada
                  </span></span>
              </div>
              {
                usuario?.fotoPortada ?
                  <div id='EliminarPortada' className='option-text optionphoto' onClick={DeleteConfirmPortada}>
                    <span className="option-text"  >
                      <IoCloseOutline color='#272727' size="1.2rem" ></IoCloseOutline> Eliminar portada
                    </span>
                  </div>
                  : ''
              }
            </ul>
          </div> : ''
        }
        <div className='profilep-div'>
          <LazyLoadImage
            effect="opacity"
            alt="Account-picture"
            src={user?.photoURL && user?.photoURL != null && user?.photoURL != '' ? user?.photoURL : process.env.PUBLIC_URL + '/default-user.png'}
            key={user?.uid} className="account-photo" />
          <IoCameraSharp color='#272727' size="2rem" className="btn-change-photoP" onClick={() => ShowOptionsPhoto()}></IoCameraSharp>
          {
            options ? <div className="select-menu" >
              <ul className="options-menu-Pprofile">
                <div className='option-text optionphoto' onClick={() => OpenModalModify()}>
                  <span className="option-text"  >
                    <IoCameraReverseOutline color='#272727' size="1.2rem"></IoCameraReverseOutline> <span>Modificar foto
                    </span></span>
                </div>
                {
                  /// validaremos si el usuario tiene una foto
                  user?.photoURL ?
                    <div id='EliminarFoto' className='option-text optionphoto' onClick={DeleteConfirmFoto}>
                      <span className="option-text" >
                        <IoCloseOutline color='#272727' size="1.2rem"></IoCloseOutline> Eliminar foto
                      </span>
                    </div>
                    : ''
                }
              </ul>
            </div> : ''
          }
        </div>
      </div >


      {/* Modal para modificar la fotografia */}
      <ContactModal ContactModal
        estado={Estadomodal}
        CambiarEstado={setEstadoModal}
        Titulo="Modificar fotografía de la cuenta"
      >
        <ModalmodPic setEstadoModal={setEstadoModal} Estadomodal={Estadomodal}></ModalmodPic>
      </ContactModal >
      {/* Modal para modificar la portada de la cuenta */}
      <ContactModal ContactModal
        estado={EstadomodalPortada}
        CambiarEstado={setEstadoModalPortada}
        Titulo="Modificar portada de la cuenta"
      >
        <ModalmodPortada usuario={usuario} estado={EstadomodalPortada}
          CambiarEstado={setEstadoModalPortada} setUsuario={setUsuario}></ModalmodPortada>
      </ContactModal >
    </>

  )
}

export default HeaderAccount