import React, { useEffect, useState } from "react";
import SkeletonList from '../../skeletons/SkeletonList'
import db from '../../Firebase/firebaseConfig';
import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from 'react-router-dom';

const Filtersmall = ({ Active, setActive, setFiltrado, listaProductos, SetCategoria, Categoria, currentPage, setCurrentPage }) => {
    ///variables
    const [lista, setLista] = useState(null);
    /// Variable para saber donde estoy
    const path = useLocation();
    /// nuestra funcion que traera el listado
    const fetchCategories = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "categories"), orderBy("nombre"))

        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setLista(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    function setValues(id, nombre) {
        setActive(id)
        SetCategoria(nombre);
        setCurrentPage(1);
    }


    useEffect(() => {
        ///Seteamos las categorias
        fetchCategories();

        if (Active == 'all') {
            setFiltrado(listaProductos)
            return;
        }
        const filtrado = listaProductos.filter((productos) => productos.Categoria.includes(Active))
        setFiltrado(filtrado);
    }, [Active])


    return (
        <div>
            {/* Seccion encargada de un sliders de categorias cuando el disposito se haga pequeño */}
            <div className="cat-md">
                <p className="cat-md">Otras categorías para explorar</p>
                <Swiper slidesPerView={2.5}
                    spaceBetween={30}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                    }}
                    pag
                    modules={[Pagination, Autoplay]} className="Galeria-md">
                    <SwiperSlide>
                        <li onClick={() => setValues('all', 'Todos los productos disponibles')} className={Active == 'all' ? 'Active' : ''} >
                            <p>Todos </p>
                        </li>
                    </SwiperSlide>
                    {
                        /// traemos nuestra lista entera de categorias
                        lista && lista.map(categoria => {
                            return (
                                <SwiperSlide key={categoria.key}>
                                    <li onClick={() => setValues(categoria.key, categoria.nombre)} className={Active == categoria.key ? 'Active' : ''} key={categoria.key}>
                                        <p>{categoria.nombre}</p>
                                    </li>
                                </SwiperSlide>

                            )
                        })
                    }

                    {
                        !lista && [1, 2, 3, 4].map((n) => {
                            return (
                                <SwiperSlide key={n}>
                                    <SkeletonList key={n}></SkeletonList>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>



        </div>

    )
}

export default Filtersmall