import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import './ContactModal.css'
import { IoLogoInstagram } from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io5';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { AnimatePresence, motion } from "framer-motion"


const ContactModal = (props) => {
    /// funcion para cerrar el modal
    function CerrarModal() {
        props.CambiarEstado(false)
        document.body.style.overflow = 'unset';
    }

    return (
        <>
            <AnimatePresence>
                {
                    props.estado &&
                    // Hacemos referencia a la clase motion para animar el modal
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 0.3
                            }
                        }}
                        exit={{
                            opacity: 1,
                            transition: {
                                delay: 0.3
                            }
                        }}
                        className='Overlay'>
                        <motion.div
                            initial={{
                                y: -300,
                                opacity: 0
                            }}
                            animate={
                                {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.5
                                    }
                                }
                            }
                            exit={{
                                y: -300,
                                opacity: 0,
                                transition: {
                                    duration: 0.3,
                                }
                            }}
                            className='Contenedor-modal'>
                            <div className='Encabezado-modal'>
                                <h3>{props.Titulo}</h3>
                                <IoCloseCircleOutline onClick={() => { CerrarModal() }} className='BotonCerrar' size="1rem"></IoCloseCircleOutline>
                            </div>
                            <motion.div
                                initial={{
                                    x: 20,
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        delay: 0.3,
                                        duration: 0.3
                                    }
                                }}
                                exit={{
                                    x: 20,
                                    opacity: 0,
                                    transition: {
                                        delay: 0.3,
                                        duration: 0.3
                                    }
                                }}
                                className='contenido-form'>
                                {/* renderizaremos elementos dados por el children */}
                                {props.children}
                            </motion.div>
                            {/* En caso la propiedad footer venga activa se mostrara el footer */}
                            {
                                props.Footer ? <div className='contact-footer'>
                                    <a href='https://www.instagram.com/luzycolorpinturas/?igshid=1erdkk0yqmlu8' target="_blank"><p> <IoLogoInstagram size="1.2em" className='ms-3' /> luzycolorpinturas</p>  </a>
                                    <a href='https://www.facebook.com/luzycolorpinturas' target="_blank"><p> <IoLogoFacebook size="1.2em" className='ms-3' /> Luz Y Color</p></a>
                                    <a href='https://wa.me/76048556' target='_blank' ><p><IoLogoWhatsapp size="1.2em" className='ms-3' /> 7604-8556 </p></a>
                                </div> : ''
                            }

                        </motion.div>
                    </motion.div>
                }
            </AnimatePresence>

        </>
    )
}

export default ContactModal;