import { doc, getDoc } from 'firebase/firestore';
import db from '../Firebase/firebaseConfig';

/// traer la wishlist de un usuario en base de datos y seteada local ademas de traer los datos de un usuario en una promesa
const GetWishList = (usuario) => {
    return new Promise(async (resolve, reject) => {
        try {
            let user_ = {};
            const userRef = doc(db, "users", usuario?.uid);
            const docSnap = getDoc(userRef)
            user_ = ((await docSnap).data());
            resolve(user_)
        } catch (error) {
            reject(error)
        }
    })
}

export default GetWishList