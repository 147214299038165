import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonCardAd = () => {
    return (
        <div className="skeleton-wrapper-card-ad light">
            <SkeletonElement type="card-ad"></SkeletonElement>
            <Shimmer />
        </div>

    )
}
export default SkeletonCardAd;