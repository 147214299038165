import React from 'react'
import getProyectos from '../../../Actions/getProyectos'
import { ApiService } from '../../../services/ApiService';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import toast from 'react-hot-toast';
import { IoSearchCircleOutline, IoSearchOutline } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DataTable } from 'primereact/datatable';
import SkeletonProyectos from './components/SkeletonProyectos';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import FormProyecto from './components/FormProyecto';

const Proyectos = () => {
  const { proyectos, setProyectos, getDataProyecto } = getProyectos();
  const api = new ApiService();
  /// variables que usamos en todo el ciclo
  const [proyecto_, setProyecto] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [proyectoDeleteDialog, DeleteProyectoDialog] = useState(false);
  const [displayModalProyecto, setDisplayModalProyecto] = useState(false);

  /// mostrar el modal de edicion
  const showModalEdit = (rowData) => {
    setDisplayModalProyecto(true);
    setProyecto(rowData)
  }
  /// mostrar la alerta de borrado
  const Confirmacion = (rowData) => {
    DeleteProyectoDialog(true);
    setProyecto(rowData);
  }

  const hideDeleteProyectoDialog = () => {
    DeleteProyectoDialog(false);
    setProyecto(null);
  }
  /// footer del dialogo de confirmacion
  const deleteproyectoDialogFooter = (
    <React.Fragment>
      <Button label="No, Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProyectoDialog} />
      <Button label="Si, estoy seguro" icon="pi pi-check" className="p-button-text" onClick={() => DeleteProyecto()} />
    </React.Fragment>
  );
  /// esconder el modal y quitar el pais seleccionado
  const Hide = () => {
    setProyecto(null);
    setDisplayModalProyecto(false)
  }
  //#region 
  ///Items y encrustacion de codigo que servira para la funcionalidad
  /// la cabecera de la tabla
  const header = (
    <div className="table-header">
      <div className="login__field">
        <IoSearchOutline className="login__icon "></IoSearchOutline>
        <input
          onInput={(e) => setGlobalFilter(e.target.value)}
          className='login__input'
          type="email" placeholder="Buscar..." />
      </div>
    </div>
  );

  /// Apartado para mostrar la imagen en la tabla
  const imageBodyTemplate = (rowData) => {
    return (
      <LazyLoadImage
        effect="opacity"
        alt={rowData.Portada}
        src={rowData.Portada}// use normal <img> attributes as props
        key={rowData.Portada}
        className="shadow-2 border-round" style={{ width: '64px' }} />
    );
  };

  /// Acciones para interactuar con la fila
  const AccionesProyecto = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning me-2" onClick={() => showModalEdit(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => Confirmacion(rowData)} />
      </React.Fragment>
    );
  }

  ///funcion para hacer un delete a la categoria
  const DeleteProyecto = async () => {
    try {
      api.DeleteProyecto(proyecto_).then((response) => {
        toast.success(response);
        hideDeleteProyectoDialog();
      })
    } catch (error) {
      toast.error("Ha ocurrido un error a la hora de eliminar la categoría.")
    }
  }


  useEffect(() => {
    getDataProyecto();
  }, [])

  return (
    <div>
      <div className='mt-2 mb-3'>
        <div className='d-flex justify-content-end flex-column'>
          <h3 className='fw-bolder'>Administración de Proyectos</h3>
          <p className='select-text'>Agrega, modifica o elimina a los proyectos</p>
        </div>
        <div className="d-flex justify-content-end">
          <Button icon="pi pi-plus" label="Agregar proyecto" className="p-button-success" onClick={() => setDisplayModalProyecto(true)} />
        </div>
      </div>

      {/* Donde va la datatable y los datos */}
      <div className='w-100'>
        <div className="">
          {
            !proyectos ? <SkeletonProyectos></SkeletonProyectos> :
              <DataTable emptyMessage="Sin registros para mostrar" value={proyectos} stripedRows
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} proyectos" header={header} globalFilter={globalFilter}>
                <Column field="Portada" header="Portada" body={imageBodyTemplate}></Column>
                <Column field="Nombre" sortable header="Nombre"></Column>
                <Column header="Acciones" body={AccionesProyecto} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
          }

        </div>
      </div>
      {/* Apartado para modales y ventanas desplegables */}
      {/* Modal que servira para el mantenimiento de los Categorias*/}
      <Dialog header={proyecto_?.Id ? "Edición de proyecto" : "Mantenimiento de proyecto"} visible={displayModalProyecto} draggable={true} maximizable={true} className="modal-size" onHide={() => Hide()}>
        <FormProyecto Hide={Hide} proyecto_={proyecto_} setProyecto={setProyecto} />
      </Dialog>

      <Dialog visible={proyectoDeleteDialog} style={{ width: '450px' }} header="Mensaje de Confirmación" modal footer={deleteproyectoDialogFooter} onHide={hideDeleteProyectoDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle me-3" style={{ fontSize: '1rem' }} />
          {proyecto_ && <span>Estás seguro de eliminar el proyecto <b>{proyecto_.Nombre}</b>?</span>}
        </div>
      </Dialog>
    </div>
  )
}

export default Proyectos
