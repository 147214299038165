import { useContext, createContext } from "react";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    updateProfile,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    linkWithPopup
} from "firebase/auth";
import { auth } from '../Firebase/firebaseConfig';
import db from '../Firebase/firebaseConfig';
import { useEffect } from "react";
import { useState } from "react";
import toast from 'react-hot-toast';
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { useGlobalState } from "./GlobalState";
import GetWishList from "./GetWishList";

const AuthContext = createContext()

// nuestro provider context para los inicios de sesion y datos importantes.
export const AuthContextProvider = ({ children }) => {
    /// usuario que servira como pivote para las operaciones
    const [usuario, setUsuario] = useGlobalState('userActive');
    const [Lista, setLista] = useGlobalState('whishListProducts');
    const [user, setUser] = useState({});

    // logica de nuestro logueo con google, aqui iniciaremos el logueo
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then((result) => {
            toast.success("Bienvenido");
            const user = result.user;
            const usuario = {
                id: user?.uid,
                nombre: user?.displayName,
                email: user?.email,
                fotoPerfil: user?.photoURL,
                fotoPortada: "",
                creacion: new Date(),
                roles: [2],
            }
            const userRef = doc(db, "users", user?.uid);
            GetWishList(result.user).then((user) => {
                if (user) {
                    setUsuario(user)
                    localStorage.setItem('user', JSON.stringify(user));
                    if (user?.wishlist) {
                        setLista(user?.wishlist)
                        localStorage.setItem('whishProducts', JSON.stringify(user?.wishlist));
                    } else {
                        var colors = [];
                        setLista([]);
                        localStorage.setItem('whishProducts', JSON.stringify([]));
                    }
                    updateDoc(userRef, {
                        nombre: user?.displayName,
                        email: user?.email,
                        fotoPerfil: user?.photoURL,
                    });
                } else {
                    setUsuario(usuario)
                    localStorage.setItem('user', JSON.stringify(usuario));
                    if (usuario?.wishlist) {
                        setLista(user?.wishlist)
                        localStorage.setItem('whishProducts', JSON.stringify(user?.wishlist));
                    } else {
                        setLista([]);
                        localStorage.setItem('whishProducts', JSON.stringify([]));
                    }
                    setDoc(userRef, usuario,).then(() => {
                    }).catch((error) => {
                        toast.error('Ha ocurrido un error a la hora de guardar el usuario en la base de datos')
                        console.log(error);
                    });
                }
            }).catch(() => {
            })

        }).catch((error) => {
            toast.error("No se ha podido iniciar la sesión")
            console.log(error);
        })
    }
    // inicio de sesion con Facebook
    const facebookSignIn = () => {
        const providerFacebook = new FacebookAuthProvider();
        signInWithPopup(auth, providerFacebook).then((result) => {
            toast.success("Bienvenido");
            const user = result.user;
            const usuario = {
                id: user?.uid,
                nombre: user?.displayName,
                email: user?.email,
                fotoPerfil: user?.photoURL,
                fotoPortada: "",
                creacion: new Date(),
                roles: [2],
            }
            const userRef = doc(db, "users", user?.uid);
            GetWishList(result.user).then((user) => {
                if (user) {
                    setUsuario(user)
                    localStorage.setItem('user', JSON.stringify(user));
                    if (user?.wishlist) {
                        setLista(user?.wishlist)
                        localStorage.setItem('whishProducts', JSON.stringify(user?.wishlist));
                    } else {
                        var colors = [];
                        setLista([]);
                        localStorage.setItem('whishProducts', JSON.stringify([]));
                    }
                    updateDoc(userRef, {
                        nombre: user?.displayName,
                        email: user?.email,
                        fotoPerfil: user?.photoURL,
                    });
                } else {
                    setUsuario(usuario)
                    localStorage.setItem('user', JSON.stringify(usuario));
                    if (usuario?.wishlist) {
                        setLista(user?.wishlist)
                        localStorage.setItem('whishProducts', JSON.stringify(user?.wishlist));
                    } else {
                        setLista([]);
                        localStorage.setItem('whishProducts', JSON.stringify([]));
                    }
                    setDoc(userRef, usuario,).then(() => {
                    }).catch((error) => {
                        toast.error('Ha ocurrido un error a la hora de guardar el usuario en la base de datos')
                        console.log(error);
                    });
                }
            }).catch(() => {
            })

        }).catch((error) => {
            toast.error("No se ha podido iniciar la sesión")
            console.log(error);
        })
    }
    // Iniciar sesion con un usuario por correo y pass
    const loginUser = (email, pass) => {
        signInWithEmailAndPassword(auth, email, pass)
            .then((userCredential) => {
                GetWishList(userCredential.user).then((user) => {
                    setUsuario(user)
                    localStorage.setItem('user', JSON.stringify(user));
                    if (user.wishlist) {
                        setLista(user.wishlist)
                        localStorage.setItem('whishProducts', JSON.stringify(user.wishlist));
                    } else {
                        setLista([]);
                        localStorage.setItem('whishProducts', JSON.stringify([]));
                    }
                }).catch((error) => {
                    setLista([]);
                    localStorage.setItem('whishProducts', JSON.stringify([]));
                })
                setUser(userCredential.user)
                toast.success('Bienvenido, sesión iniciada')
            }).catch((err) => {
                switch (err.code) {
                    case "auth/user-not-found":
                        toast.error('La cuenta solicitada no existe.')
                        break;
                    case "auth/wrong-password":
                        toast.error('Su contraseña no es correcta.')
                        break;
                    case "auth/user-disabled":
                        toast.error('La cuenta ha sido suspendida.')
                        break;
                    default:
                        toast.error('Ha ocurrido un error.')
                        break;
                }
            })
    }

    // registrar un nuevo usuario por correo nombre y pass
    const registerUser = (name, email, pass) => {
        createUserWithEmailAndPassword(auth, email, pass).then((userCredential) => {
            updateProfile(userCredential.user, {
                displayName: name,
            }).then(() => {
                const usuario = {
                    id: userCredential?.user?.uid,
                    nombre: userCredential?.user?.displayName,
                    email: userCredential?.user?.email,
                    fotoPerfil: "",
                    fotoPortada: "",
                    creacion: new Date(),
                    roles: [2]
                }
                /// tendremos el apartado para ir guardando los usuarios en la base de datos para control de registro
                setDoc(doc(db, "users", userCredential?.user?.uid), usuario).then(() => {
                }).catch((error) => {
                    toast.error('Ha ocurrido un error a la hora de guardar el usuario en la base de datos')
                    console.log(error);
                });
                toast.success('¡Su cuenta se ha creado con éxito, ' + name + '!'
                )
                // enviamos el correo para que verifique su email
                sendEmailVerification(userCredential.user)
                setUsuario(usuario)
                localStorage.setItem('user', JSON.stringify(usuario));
                setLista([]);
                localStorage.setItem('whishProducts', JSON.stringify([]));
            }).catch((err) => {
                toast.error('Ha ocurrido un error al momento de registrar su usuario.')
                console.log(err.code);
                console.log(err.messsage);
            })
            loginUser(userCredential.user.email, pass)
        }).catch((error) => {
            switch (error.code) {
                case 'auth/email-already-in-use': {
                    toast.error('El correo que ingresó ya está en uso por otra cuenta.')
                }
            }
        }
        )
    }
    // para verificar la cuenta 
    const verifyAccount = () => {
        sendEmailVerification(auth.currentUser).then(() => {
            toast.success('Correo de verificación enviado, revisa tu correo para verificar tu cuenta.')
        }).catch((err) => {
            switch (err.code) {
                case "auth/too-many-requests":
                    toast.error('El correo ya fue enviado, vuelve a intentarlo en un par de minutos.')
                    break;
                default:
                    break;
            }

        })
    }
    // reseteo de contraseña atraves de un correo electronico enviado al email que se solicita
    const ForgetPassword = (email) => {
        sendPasswordResetEmail(auth, email).then(() => {
            toast.success('El correo de reestablecimiento de contraseña fue enviado con exito.')
        }).catch((err) => {
            switch (err.code) {
                case "auth/user-not-found":
                    toast.error('El correo ingresado no corresponde a ninguna cuenta.')
                    break;
                case "auth/too-many-requests":
                    toast.error('El correo ya fue enviado, vuelve a intentarlo en un par de minutos.')
                    break;
                default:
                    break;
            }
        })
    }

    // funcion para cerrar sesion
    const logOut = () => {
        toast.promise(
            signOut(auth),
            {
                loading: 'Cerrando Sesión...',
                success: <b>Se ha cerrado su sesión con éxito.</b>,
                error: <b>Ha ocurrido un error al intentar cerrar la sesión.</b>,
            }
        );
        localStorage.removeItem("user");
        localStorage.removeItem("whishProducts");
        setUsuario(null);
        setLista(null);
    }

    // setearemos nuestro actual usuario y cualquier cambio aqui se vera reflejado.
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            // GetWishList(currentUser).then((user) => {
            //     setUsuario(user)
            //     localStorage.setItem('user', JSON.stringify(user));
            //     if (user.wishlist) {
            //         setLista(user.wishlist)
            //         localStorage.setItem('whishProducts', JSON.stringify(user.wishlist));
            //     } else {
            //         setLista([]);
            //         localStorage.setItem('whishProducts', []);
            //     }
            // }).catch((error) => {
            //     setLista([]);
            //     localStorage.setItem('whishProducts', []);
            // })
            /// montamos un usuario en nuestro local storage el cual estara presente sin necesidad de peticiones
            /// desde el primer arranque de la app setearemos la lista de deseos de esta manera sera personalizada
            /// para cada usuario que ingrese sesion y si en caso no esta registrado no podra ver nada de esto
            if (currentUser?.displayName) {
                /// mostraremos un mensaje de bienvenida
                toast.success("Hola, " + currentUser?.displayName, {
                    icon: '👋',
                })
            } else {
                localStorage.removeItem("user");
                localStorage.removeItem("whishProducts");
                setUsuario(null);
                setLista(null);
            }
        })
        return unsubscribe;

    }, [])

    // regresaremos todos los metodos definirlos los cuales seran globales
    return (
        <AuthContext.Provider value={{
            googleSignIn, logOut, user, facebookSignIn, registerUser, loginUser, verifyAccount, ForgetPassword
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext);
}