import React, { useState } from 'react'
import { motion } from "framer-motion"
import { IoHeart } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGlobalState } from '../../services/GlobalState';
import DeleteWishList from '../../services/DeleteWishList';
/// Componente que mostrara cuandoo el filtro cambie
function LikedsSmall({ currentItems, currentPage, setCurrentPage }) {
    const [usuario, setUsuario] = useGlobalState('userActive');
    // El global context para el listado de productos favoritos
    const [ListaP, setListaP] = useGlobalState('whishListProducts');
    const [Favorito, setFavorito] = useState(false);
    /// seteamos los productos favcoritos para mostrarlos al usuario
    const Favoritoset = async (favorito, producto) => {
        /// si quitamos el favorito eliminamos de la wishlist
        if (favorito == false) {
            setListaP(await DeleteWishList(producto.Nombre, usuario, producto));
            setUsuario(JSON.parse(localStorage.getItem('user')))
        } else {
        }
        setFavorito(favorito);
        /// Si la paginacion nos arroja que nuestros actuales items se eliminan de la pagina, la paginacion retrocedera una vez
        if (currentItems.length == 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    return (
        <div className='container-likeds-small'>
            <>
                {
                    currentItems?.map(producto => {
                        return (
                            <motion.div layout className="" key={producto.key}>
                                <motion.div layout>
                                    <div className="card-product mt-5">
                                        {/* Se usara cuando se ponga el ecommerce */}
                                        {/* <div className="badge">10% OFF</div> */}
                                        {/* Validaremos si el elemento existe */}

                                        <div>
                                            <IoHeart className='badge-favorite' onClick={() => Favoritoset(false, producto)} />
                                        </div>

                                        <Link className="link-card-product" to={`/Product/${producto.key}`}>
                                            <LazyLoadImage
                                                effect="opacity"
                                                alt={producto.Imagen}
                                                src={producto.Imagen}// use normal <img> attributes as props
                                                key={producto.Imagen}
                                                className="img-cardp" /></Link>
                                        <p>{producto.Nombre}</p>
                                        <Link className="link-card-product" to={`/Product/${producto.key}`}>
                                            <p className="vermas">Ver producto</p></Link>
                                    </div>

                                </motion.div>
                            </motion.div>
                        )
                    })
                }
            </>
        </div>
    )
}

export default LikedsSmall