import React from 'react'
import ModalAds from './ModalAds'
import Ads from './Ads'

const Anuncios = () => {
  return (
    <>
      <ModalAds />
      <Ads />
    </>

  )
}

export default Anuncios
