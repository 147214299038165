import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import db from '../Firebase/firebaseConfig';

/// nos ayuda a eliminar elementos en nuestra lista de deseos
const DeleteWishList = (nombre, usuario, producto) => {
    return new Promise(async (resolve, reject) => {
        try {
            const userRef = doc(db, "users", usuario.id);
            updateDoc(userRef, {
                "wishlist": arrayRemove(producto),
            });
            /// buscamos el elemento y lo eliminamos de la lista
            let elements = JSON.parse(localStorage.getItem('whishProducts'));;
            elements = elements.filter(element => element?.Nombre !== nombre);
            localStorage.setItem('whishProducts', JSON.stringify(elements));
            /// seteamos el usuario con la nueva wishlist que tendra en todo su ciclo de vida 
            let user = { ...usuario, wishlist: elements };
            localStorage.setItem("user", JSON.stringify(user));
            toast.error('Te ha dejado de gustar este producto.', {
                icon: '🚫'
            })
            resolve(JSON.parse(localStorage.getItem('whishProducts')))
        } catch (error) {
            reject(error)
        }

    })
}

export default DeleteWishList