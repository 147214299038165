import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { ApiService } from '../../../../services/ApiService';
import { InputText } from 'primereact/inputtext';


const FormCategoria = ({ categoria_, setCategoria, Hide }) => {
    const api = new ApiService();
    /// variables a utilizar para hacer toggles y validar el formulario
    const [forme, setForme] = useState({});
    const [errors, setErrors] = useState({});
    /// funcion que seteara los campos del formulario
    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }
    /// funcion que validara los campos del formulario
    const validateForm = () => {
        const { nombre } = forme
        const newErrors = {}
        if (!nombre || nombre === '') newErrors.nombre = 'El nombre no puede ir vacío.'
        else if (nombre.length > 150) newErrors.nombre = "El nombre no puede llevar mas de 150 cáracteres."
        return newErrors;
    }

    ///funciones para guardar o editar segun convenga
    const sendData = async () => {
        const { nombre } = forme
        let CategoriaAdd = { id: "", nombre: nombre };
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            api.AddCategoria(CategoriaAdd).then((response) => {
                toast.success(response);
                Hide();
            }).catch((error) => {
                toast.error("Ha ocurrido un error a la hora de ingresar la categoría.")
                console.log(error)
            })
        }
    }
    /// para editar
    const sendDataEdit = async () => {
        const { nombre } = forme
        let CategoriaEdit = { id: categoria_.id, nombre: nombre };
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            api.EditCategoria(CategoriaEdit).then((response) => {
                toast.success(response);
                Hide();
            }).catch((error) => {
                toast.error("Ha ocurrido un error a la hora de editar la categoría.")
            })
        }
    }

    useEffect(() => {
        /// en caso de traerlo sabdremos que viene en modo edicion
        if (categoria_) {
            setField('nombre', categoria_.nombre);
            forme.nombre = categoria_?.nombre;
        }
    }, [])



    return (
        <>
            <div className='container-form-user'>
                <div className="field w-100">
                    <span className="p-float-label p-input-icon-right w-100">
                        <InputText className={!errors.nombre ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.nombre} onChange={e => setField('nombre', e.target.value)} required />
                        <label htmlFor="description">Nombre de la categoría</label>
                    </span>
                    <span className="invalid">{errors.nombre}</span>
                </div>
            </div>
            <div className='footer-modal'>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => Hide()} className="p-button-text p-button-danger" />
                <Button label={categoria_ ? 'Editar' : 'Guardar'} onClick={categoria_ ? () => sendDataEdit() : () => sendData()} icon="pi pi-check" autoFocus className='p-button-success' />
            </div>
        </>
    )
}

export default FormCategoria;