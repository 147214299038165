import React from 'react';
import './Footer.css'
import { IoLogoInstagram } from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io5';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { IoHeartOutline } from "react-icons/io5";
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div className='main-footer'>
                <div className='contenedor'>
                    <div className='right'>
                        <div className="enlaces">
                            <a href='https://www.instagram.com/luzycolorpinturas/?igshid=1erdkk0yqmlu8' target="_blank"><p> <IoLogoInstagram size="1.2em" className='ms-3' /> luzycolorpinturas</p>  </a>
                            <a href='https://www.facebook.com/luzycolorpinturas' target="_blank"><p> <IoLogoFacebook size="1.2em" className='ms-3' /> Luz Y Color</p></a>
                            <a href='https://wa.me/76048556' target='_blank' ><p><IoLogoWhatsapp size="1.2em" className='ms-3' /> 7604-8556 / 7604-8185</p></a>
                        </div>
                        <div className='aside-others-a'>
                            <a className='linked-aside'>Preguntas frecuentes</a>
                            <a Información className='linked-aside'>Información Legal</a>
                            <a href='https://protectopinturas.com.sv/' target="_blank" className='linked-aside'>Pinturas Protecto</a>
                            <a className='linked-aside'>Información personal</a>
                        </div>
                    </div>
                    <div className='center'>
                        <p>© {currentYear}, Luz & Color Todos los Derechos Reservados</p>
                    </div>
                    <div className='left'>
                        <img className='img-fluid img-footer' src={process.env.PUBLIC_URL + '/logo.png'}></img>
                        <h4 className='Luz'>Luz & Color</h4>
                    </div>

                </div>
                <p className='by'>Made with <IoHeartOutline className='me-1 ms-1' size="1rem"></IoHeartOutline> by Guerrero designs and developments.</p>
            </div>
        </>
    )
}

export default Footer;