import React, { useEffect, useState } from 'react'
import './Project.css'
// Firebase
import { doc, getDoc } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
import { useParams } from 'react-router-dom';
import { IoAlertCircleSharp } from 'react-icons/io5';
// react icons
import { IoArrowBackCircle } from 'react-icons/io5';
import { IoHeartOutline } from 'react-icons/io5';
import { IoHeart } from 'react-icons/io5';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReactTooltip from 'react-tooltip';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// import required modules
import { Pagination, Autoplay } from "swiper";
import SkeletonCard from '../../skeletons/SkeletonCard';
import SkeletonProyect from '../../skeletons/SkeletonProyect';
import Galeria from './Galeria';
import BotonScrollUp from '../../components/BotonScrollUp/BotonScrollUp';

// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function Project() {
    // Parametro que viene por la url
    const { id } = useParams();
    // variables que usaremos para el proyecto individual y tenerlo en memoria
    var proyecto = {};
    const [Proyecto, SetProyecto] = useState(null);
    /// la copia del proyecto para evitar tener multiples instancias de llamadas a la API
    const [Pcopy, setPcopy] = useState(null);
    // const [Favorito, setFavorito] = useState(false);

    // variable para regresar atras
    const history = useHistory();

    // funcion encargada de setear el proyecto en memoria y ademas seteare el nomnbre de la pagina
    function setp(object) {
        proyecto = object;
        document.title = 'Luz & Color Pinturas Protecto® ' + (object ? ' | ' + ' ' + object?.Nombre : '');
    }


    // Funcion asincrona que nos traera desde firebase el proyecto
    const fetchProyecto = async () => {
        const docRef = doc(db, "proyectos", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setp({
                ...docSnap.data(),
                key: id
            })
            /// seteamos el valor de copia para jugar con el y sus propiedades
            SetProyecto(proyecto);
            setPcopy(proyecto);
        } else {
            setPcopy(null);
        }
        // Nombramos el documento

    }

    /// funcion que tendra la imagen y estilo
    const estilo = {
        backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${Pcopy?.Portada})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    };


    /// funcion para agregar proyectos al listado de me gusta
    // function Favoritoset(favorito) {
    //     favorito == false ? toast.error('Te ha dejado de gustar este proyecto.', {
    //         icon: '🚫'
    //     }) : toast.success('Te ha gustado este proyecto.', {
    //         icon: '👍'
    //     })
    //     setFavorito(favorito);

    // }

    // en base al parametro cargaremos el proyecto directo de firebase
    useEffect(() => {
        fetchProyecto();
    }, [])


    return (

        <div className='contenedor-proyecto'>
            {
                Pcopy != null ?

                    <div>
                        <Toaster />
                        {/* Boton para subir  */}
                        <BotonScrollUp />
                        <div className=''>
                            <div className="header-project" style={estilo}>
                                <div className='tools-header'>
                                    <a className="herramientas" data-tip data-for="botonRegresar" onClick={history.goBack}>
                                        <IoArrowBackCircle className="me-1" size="22px"></IoArrowBackCircle>
                                        <ReactTooltip id="botonRegresar"
                                            type='light'
                                            place='right'
                                            effect='solid'
                                            className='tooltip-regresar'>
                                            Regresar hacia atrás
                                        </ReactTooltip>
                                        Regresar
                                    </a>
                                    {/* Apartado a implementar en un futuro */}
                                    {
                                        // Favorito ?
                                        //     <div>
                                        //         <a className="herramientas" onClick={() => Favoritoset(false)}>
                                        //             <IoHeart data-tip data-for="desmarcar" size="22px"></IoHeart >
                                        //             <ReactTooltip id="desmarcar"
                                        //                 type='light'
                                        //                 place='left'
                                        //                 effect='solid'
                                        //                 className='tooltip-regresar'
                                        //             >
                                        //                 No me gusta
                                        //             </ReactTooltip>
                                        //         </a>

                                        //     </div>
                                        //     : <div>
                                        //         <a className="herramientas" onClick={() => Favoritoset(true)}>
                                        //             <IoHeartOutline data-tip data-for="marcar" size="22px"></IoHeartOutline >
                                        //             <ReactTooltip id="marcar"
                                        //                 type='light'
                                        //                 place='left'
                                        //                 effect='solid'
                                        //                 className='tooltip-regresar'
                                        //             >
                                        //                 Me gusta
                                        //             </ReactTooltip>
                                        //         </a>

                                        //     </div>
                                    }

                                </div>
                                <div className="contenedor-nombre">
                                    <h1 className='nombre-p'>{Pcopy?.Nombre}</h1>
                                </div>
                                <div className="proyectoi container-xxl">
                                    <div className="contenidopDescripcion">
                                        <h3>Descripción del proyecto </h3>
                                        <p>{Pcopy?.Descripcion}</p>
                                    </div>
                                    <div className="contenidopDescripcion">
                                        <h3>Propósito del proyecto </h3>
                                        <p>{Pcopy?.Proposito}</p>
                                    </div>
                                </div>

                            </div>
                            {/* Contenedor donde estaran los productos del proyecto */}
                            <div className='PBody'>
                                <div className="PropositoP">
                                    <div className="container-xxl">
                                        <div>
                                            {
                                                Pcopy?.Productos.length <= 0 ?
                                                    <div className='rel-products mb-4'>
                                                        <h1>¡No se encontraron productos para este proyecto!</h1>
                                                        <p>Esto es posible a que este proyecto no tiene ningun producto vinculado.</p>
                                                        <IoAlertCircleSharp className="not-content" size="30px"></IoAlertCircleSharp>
                                                    </div>
                                                    :

                                                    <div className='rel-products'>
                                                        <h1>Productos utilizados en este proyecto</h1>
                                                        <p className='enbase p-b'>Estos productos formaron parte del suministro y aplicación: </p>
                                                        {/* Size Large */}
                                                        <Swiper
                                                            slidesPerView={3}
                                                            spaceBetween={10}
                                                            pagination={{
                                                                clickable: true,
                                                            }}
                                                            modules={[Autoplay, Pagination]} className="related-products"
                                                        >
                                                            {
                                                                /// traemos nuestra lista entera de categorias
                                                                Pcopy && Pcopy?.Productos.map(Producto => {
                                                                    return (
                                                                        <SwiperSlide key={Producto.Id} >
                                                                            <motion.div layout>
                                                                                <Link to={`/Product/${Producto.Id}`} className="text-decoration-none">
                                                                                    <div className="link-card-product " >
                                                                                        <div className="card-product">
                                                                                            <LazyLoadImage
                                                                                                effect="opacity"
                                                                                                alt={Producto.Imagen}
                                                                                                src={Producto.Imagen}
                                                                                                key={Producto.Imagen}
                                                                                                className="img-cardp" />
                                                                                            <p>{Producto.Nombre}</p>
                                                                                            <p className="vermas">Ver producto</p>
                                                                                        </div></div>
                                                                                </Link>

                                                                            </motion.div>
                                                                        </SwiperSlide>

                                                                    )
                                                                })
                                                            }
                                                            {
                                                                !Pcopy?.Productos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                                                    return (
                                                                        <SwiperSlide key={n}>
                                                                            <SkeletonCard key={n}></SkeletonCard>
                                                                        </SwiperSlide>
                                                                    )
                                                                })
                                                            }
                                                        </Swiper>
                                                        {/* Size Small */}
                                                        <Swiper
                                                            slidesPerView={1.5}
                                                            spaceBetween={10}
                                                            pagination={{
                                                                clickable: true,
                                                            }}
                                                            modules={[Pagination, Autoplay]} className="related-products-md"
                                                        >  {
                                                                /// traemos nuestra lista entera de categorias
                                                                Pcopy && Pcopy?.Productos.map(Producto => {
                                                                    return (
                                                                        <SwiperSlide key={Producto.id} >
                                                                            <motion.div layout >
                                                                                <Link to={`/Product/${Producto.id}`} className="text-decoration-none">
                                                                                    <div className="link-card-product" >
                                                                                        <div className="card-product">
                                                                                            <LazyLoadImage
                                                                                                effect="opacity"
                                                                                                alt={Producto.Imagen}
                                                                                                src={Producto.Imagen}
                                                                                                key={Producto.Imagen}
                                                                                                className="img-cardp" />
                                                                                            <p>{Producto.Nombre}</p>
                                                                                            <p className="vermas">Ver producto</p>
                                                                                        </div></div>
                                                                                </Link>
                                                                            </motion.div>
                                                                        </SwiperSlide>

                                                                    )
                                                                })
                                                            }
                                                            {
                                                                !Pcopy?.Productos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                                                    return (
                                                                        <SwiperSlide key={n}>
                                                                            <SkeletonCard key={n}></SkeletonCard>
                                                                        </SwiperSlide>
                                                                    )
                                                                })
                                                            }
                                                        </Swiper>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Galeria del proyecto */}
                                <div className="GaleriaP">
                                    <div className="container-xxl">
                                        <p className='encabezado-galeria'>Galería de imágenes de</p>
                                        <h1 className='tituloG'> {Pcopy?.Nombre}</h1>
                                        <p className='pGaleria'>Desliza entre las imágenes para ver toda la galería en un antes y un después de este proyecto, recuerda si tienes un proyecto en
                                            mente, dejáselo a los expertos. <br></br>¡Contátanos ya! Luz y Color Pinturas Protecto © .</p>
                                    </div>
                                    <Galeria galeria={Pcopy?.Galeria} />


                                </div>
                            </div>
                        </div>
                    </div>
                    : <SkeletonProyect></SkeletonProyect>
            }
            <BotonScrollUp />
        </div >

    )
}

export default Project