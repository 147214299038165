import React, { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion"
import { IoPeople, IoLockClosed, IoMailSharp, IoEyeOff, IoEye, IoCheckmarkCircle, IoArrowForwardCircle } from "react-icons/io5";
import SocialLogin from './SocialLogin';
import { UserAuth } from '../../services/AuthContext'
import toast from 'react-hot-toast';

const RegisterForm = ({ alternate, setAlternate }) => {
    const { registerUser } = UserAuth();
    const [forme, setForme] = useState({});
    const [errors, setErrors] = useState({});
    const [showpass, setShowPass] = useState(false);
    const [showpassConfirm, setShowPassConfirm] = useState(false);

    // usaremos para intercambiar el valor del captcha en un campo invisible
    const captcha = useRef(null);

    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    /// Funcion que valida los campos
    const validateForm = () => {
        const { nombre, email, password, confirmpassword, validacion } = forme
        const newErrors = {}
        // if (!validacion || validacion === '') newErrors.validacion = 'Verifique que no es un robot para continuar'
        if (!nombre || nombre === '') newErrors.nombre = 'El nombre no puede ir vacío.'
        if (!email || email === '') newErrors.email = 'El email no puede ir vacío.'
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) newErrors.email = 'Formato de email incorrecto.'
        if (!password || password === '') newErrors.password = 'La contraseña no puede ir vacía.'
        else if (password.length < 6) newErrors.password = "La contraseña debe tener al menos 6 carácteres."
        if (!confirmpassword || confirmpassword === '') newErrors.confirmpassword = 'La confirmacion de la contraseña no puede ir vacía'
        else if (confirmpassword != password) newErrors.confirmpassword = "Las contraseñas no coinciden."
        return newErrors;
    }

    const Alternar = () => {
        setAlternate(!alternate);
    }

    /// hacer un toggle de las contraseñas
    const tooglepass = () => {
        setShowPass(!showpass);
    }
    const tooglepassConfirm = () => {
        setShowPassConfirm(!showpassConfirm);
    }

    /// funcion que captara el valor del captcha
    function onChange() {
        /// seteamos el campo escondido con el captcha para el envio por correo 
        setField('validacion', captcha.current.getValue());
    }

    /// funcion que captara la validacion de los datos y registrara a un usuario.
    const Registrar = async () => {
        const { nombre, email, password } = forme
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            try {
                await registerUser(nombre, email, password);
            } catch (error) {
                toast.error('Ha ocurrido un error al tratar de crear esta cuenta.', {
                    icon: '🚫'
                })
                console.log(error);
            }
        }
    }

    return (
        <>
            <AnimatePresence>
                {
                    alternate &&
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 0.3
                            }
                        }}
                        exit={{
                            opacity: 1,
                            transition: {
                                delay: 0.3
                            }
                        }}
                        className="login-contenedor">
                        <motion.div
                            initial={{
                                x: -20,
                                opacity: 0
                            }}
                            animate={
                                {
                                    x: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.5
                                    }
                                }
                            }
                            exit={{
                                x: -20,
                                opacity: 0,
                                transition: {
                                    duration: 0.3,
                                }
                            }}
                            className="login">
                            <div className="header-form-login">
                                <h1>Registrarse</h1>
                            </div>
                            <div className="form-login">
                                <div className="login__field">
                                    <IoPeople className="login__icon "></IoPeople>
                                    <input
                                        value={forme.nombre}
                                        onChange={e => setField('nombre', e.target.value)}
                                        type="text"
                                        className={!errors.nombre ? 'login__input login__input' : 'error-borde-login login__input'}
                                        placeholder="Nombre completo" />
                                    <div className="invalid">{errors.nombre}</div>
                                </div>
                                <div className="login__field">
                                    <IoMailSharp className="login__icon "></IoMailSharp>
                                    <input type="email"
                                        className={!errors.nombre ? 'login__input login__input' : 'error-borde-login login__input'}
                                        value={forme.email}
                                        onChange={e => setField('email', e.target.value)}
                                        placeholder="Email" />
                                    <div className="invalid">{errors.email}</div>
                                </div>
                                <div className="login__field">
                                    <IoLockClosed className="login__icon fas fa-lock"></IoLockClosed>
                                    <input type={!showpass ? 'password' : 'text'}
                                        value={forme.pasword}
                                        onChange={e => setField('password', e.target.value)}
                                        className={!errors.nombre ? 'login__input login__input' : 'error-borde-login login__input'}
                                        placeholder="Contraseña" />
                                    {
                                        !showpass ?
                                            <IoEyeOff onClick={() => tooglepass()} size="1.2rem" className="login__icon-password" /> :
                                            <IoEye onClick={() => tooglepass()} size="1.2rem" className="login__icon-password" />
                                    }

                                    <div className="invalid">{errors.password}</div>
                                </div>
                                <div className="login__field">
                                    <IoCheckmarkCircle className="login__icon fas fa-lock"></IoCheckmarkCircle>
                                    <input type={!showpassConfirm ? 'password' : 'text'}
                                        className={!errors.confirmpassword ? 'login__input login__input' : 'error-borde-login login__input'}
                                        value={forme.confirmpassword}
                                        onChange={e => setField('confirmpassword', e.target.value)}
                                        placeholder="Confirmar contraseña" />
                                    {
                                        !showpassConfirm ?
                                            <IoEyeOff onClick={() => tooglepassConfirm()} size="1.2rem" className="login__icon-password" /> :
                                            <IoEye onClick={() => tooglepassConfirm()} size="1.2rem" className="login__icon-password" />
                                    }
                                    <div className="invalid">{errors.confirmpassword}</div>
                                </div>
                                <input type="hidden" name='g-recaptcha-response' value={forme.validacion}></input>
                                <button className="button login__submit" onClick={() => Registrar()}>
                                    <span className="button__text">Registrarse</span><IoArrowForwardCircle size="1.4rem"></IoArrowForwardCircle>
                                </button>
                                {/* <ReCAPTCHA
                                    name="g-recaptcha-response"
                                    className='Captcha'
                                    sitekey="6Lej914gAAAAAPFi2HTpcG0inM5WcJbGg0OruV1l"
                                    onChange={onChange}
                                    size='normal'
                                    ref={captcha}
                                    onExpired={onChange}
                                /> */}
                                <div className="invalid">{errors.validacion}</div>
                                <p className='already pt-2'>¿Ya posees una cuenta? <span onClick={() => Alternar()}>Inicia sesión aquí</span></p>
                            </div>
                        </motion.div>
                        <hr></hr>
                        <p className='continue-message'>ó</p>
                        <p className='continue-message'>Continúa através de estos medios</p>
                        <SocialLogin></SocialLogin>
                        <Link to="/" className='btn btn-success btn-logins nav-login mb-3'>Regresar al Inicio</Link>
                        <p className='terms'>*Registrate o accede desde los diferentes medios <br />
                            Al registrarte aceptas nuestros términos y condiciones*</p>
                    </motion.div>
                }
            </AnimatePresence>
        </>

    )
}

export default RegisterForm