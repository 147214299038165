import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import db from '../Firebase/firebaseConfig';

/// nos ayuda a agregar elementos a la lista de deseos
const AddWishList = (Producto, ListaP, usuario) => {
    return new Promise(async (resolve, reject) => {
        const userRef = doc(db, "users", usuario.id);
        let lista = [...ListaP];
        try {
            updateDoc(userRef, {
                "wishlist": arrayUnion(Producto),
            });
            lista?.push(Producto);
            toast.success('Te ha gustado este producto.', {
                icon: '👍'
            })
            localStorage.setItem("whishProducts", JSON.stringify(lista));
            /// seteamos el usuario directamente
            let user = { ...usuario, wishlist: lista };
            localStorage.setItem("user", JSON.stringify(user));
            console.log(user);
            resolve(JSON.parse(localStorage.getItem('whishProducts')));
        } catch (error) {
            reject(error)
        }

    })
}


export default AddWishList