import { useState } from 'react'
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import db from '../Firebase/firebaseConfig';

const GetProyectos = () => {
    const [proyectos, setProyectos] = useState(null);


    ///Funciones asincronas para traer los datos directamente de firebase
    const getDataProyecto = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "proyectos"), orderBy("Nombre"))
        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setProyectos(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    return {
        proyectos,
        setProyectos,
        getDataProyecto
    }
}

export default GetProyectos
