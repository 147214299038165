import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, linkWithCredential, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import React from 'react'
import { auth } from '../Firebase/firebaseConfig';
import { toast } from 'react-hot-toast'
/// enlazaremos alguna cuenta ya creada para que ingrese con contraseña y correo
const LinkPassword = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const user = usercred.user;
          toast.success("Las cuentas se han enlazado correctamente.")
          resolve(user);
        }).catch((error) => {
          switch (error.code) {
            case "auth/credential-already-in-use":
              toast.error("Las cuentas ya se encuentran enlazadas o esta cuenta pertenece a alguien mas.")
              break;
            default:
              toast.error("Ha ocurrido un error a la hora de enlazar las cuentas")
              break;
          }
        });
    } catch (error) {
      reject(error)
    }
  })
}

export default LinkPassword