import React from 'react'
import GetCategoriasProyectos from '../../../../Actions/getCategoriasProyectos'
import GetProductos from '../../../../Actions/getProductos';
import { useState } from 'react';
import { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { ApiService } from '../../../../services/ApiService';
import toast from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UploadFile from '../../../../services/UploadFile';
import DeleteFile from '../../../../services/DeleteFile';
import { Image } from 'primereact/image';
import { IoTrashBinSharp } from 'react-icons/io5';

const FormProyecto = ({ proyecto_, setProyecto, Hide }) => {
  const api = new ApiService();
  /// Traemos de nuestros custom hooks los valores que tendremos en selects o desplegables
  const { categorias, getDataCategorias, setCategorias } = GetCategoriasProyectos();
  const { productos, setProductos, getDataProducto } = GetProductos();
  ///seteamos los productos
  const [productos_, selectedProductos] = useState(proyecto_?.Productos ? proyecto_?.Productos : null)
  const [forme, setForme] = useState({});
  const [errors, setErrors] = useState({});

  ///Apartado para la imagen del proyecto
  let imagen = proyecto_?.Portada;
  const [photoURL, setPhotoURL] = useState(proyecto_?.Portada)
  const [fileImagen, setFileImagen] = useState(null);

  ///Apartado para la galeria del proyecto
  const [galeria, setGaleria] = useState(proyecto_?.Galeria ? proyecto_?.Galeria : []);
  const [archivos, setArchivos] = useState([]);
  const [url, setUrl] = useState(proyecto_?.Galeria ? proyecto_?.Galeria : []);
  var urls = [];

  /// funcion que seteara los campos del formulario
  const setField = (field, value) => {
    setForme({
      ...forme,
      [field]: value
    })

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  ///funcion que detectara los cambios en el arreglo de las categorias del produto
  const onCategoriaChange = (e) => {
    let _CategoriasProyecto = proyecto_?.Categorias ? [...proyecto_?.Categorias] : [];
    console.log(_CategoriasProyecto)

    if (e.checked) {
      _CategoriasProyecto.push(e.value);
    }
    else {
      _CategoriasProyecto = _CategoriasProyecto.filter(category => category !== e.value);
      console.log(_CategoriasProyecto)
    }
    setProyecto({ ...proyecto_, Categorias: [..._CategoriasProyecto] })
  };

  /// funcion que validara los campos del formulario
  const validateForm = () => {
    const { Nombre, Descripcion, Proposito } = forme
    const newErrors = {}
    if (!Nombre || Nombre === '') newErrors.Nombre = 'El nombre no puede ir vacío.'
    else if (Nombre.length > 250) newErrors.Nombre = "El nombre no puede llevar mas de 250 cáracteres."
    if (!Descripcion || Descripcion === '') newErrors.Descripcion = 'La descripción no puede ir vacía.'
    if (!Proposito || Proposito === '') newErrors.Proposito = 'El propósito no puede ir vacío.'
    return newErrors;
  }

  ///Evento para cambiar la imagen del producto
  const handleChangeImagen = (e) => {
    const file = e.target.files[0]
    /// verificiaremos si se ha seleccionado una foto para la subida
    if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      setFileImagen(file);
      /// seteamos la imagen para que esta se muestre al momento del cambio
      setPhotoURL(URL.createObjectURL(file));
    } else if (!file) {
      toast.error('Elige una fotografía para continuar.')
    }
    /// validamos si es una foto
    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      toast.error('Formato de archivo no válido')
      return false;
    }
  }

  ///Funcion para obtener las urls de un array de archivos
  async function subirYObtenerURLs(archivos) {
    try {
      const urls = await Promise.all(
        archivos.map(async (archivo) => {
          const imagen = await UploadFile(archivo, `Projects/${archivo?.name}`);
          return imagen;
        })
      );

      return urls;
    } catch (error) {
      console.error('Error al subir archivos:', error);
      throw error;
    }
  }


  const SubmitChangeUploads = async () => {
    const { Nombre, Descripcion, Proposito } = forme
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
    } else {

      ///Subida del archivo
      if (photoURL === proyecto_?.Portada) {

      } else {
        if (fileImagen) {
          const nombreImagen = fileImagen?.name;
          imagen = await UploadFile(fileImagen, `Projects/${nombreImagen}`);
          ///Borramos la antigua imagen 
          try {
            await DeleteFile(`Projects/${proyecto_?.Portada}`)
          } catch (error) {

          }

        }
      }
      ///haremos la subida de la galeria...
      if (archivos?.length == 0) {
        ///Si no hay archivos nuevos guardamos tal cual la galeria
      } else {
        //array de archivos
        urls = await subirYObtenerURLs(archivos);
      }

      const nuevaGaleria = urls.concat(url ? url : [])
      console.log(nuevaGaleria);
      /// Es edicion
      if (proyecto_?.Id) {
        let ProyectoEdit = {
          Id: proyecto_?.Id, Nombre: Nombre, Descripcion: Descripcion, Proposito: Proposito,
          Categorias: proyecto_?.Categorias, Productos: productos_, Portada: imagen, Galeria: nuevaGaleria
        }
        api.EditProyecto(ProyectoEdit).then((response) => {
          toast.success(response);
          Hide();
        }).catch((error) => {
          toast.error("Ha ocurrido un error a la hora de editar el proyecto.")
          console.log(error)
        })
      }
      ///Es creacion
      else {
        let ProyectoAdd = {
          Id: "", Nombre: Nombre, Descripcion: Descripcion, Proposito: Proposito,
          Categorias: proyecto_?.Categorias, Productos: productos_, Portada: imagen, Galeria: urls
        }
        await api.AddProyecto(ProyectoAdd).then((response) => {
          toast.success(response);
          Hide();
        }).catch((error) => {
          toast.error("Ha ocurrido un error a la hora de ingresar el proyecto.")
          console.log(error)
        })
      }

    }
  }

  ///Funcion para la galeria, que detecta los cambios
  const OnSelectFile = (event) => {
    const selectedFiles = event.target.files;
    ///este array recorremos para las nuevas imagenes
    const selectedFilesArray = Array.from(selectedFiles);
    ///seteamos esta variable que contendra los valoresde los archivos para su subida

    const filesArray = selectedFilesArray.map((file) => {
      return file;
    })

    setArchivos((previosarchivos) => previosarchivos.concat(filesArray));

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    })

    ///el proyecto trae imagenes en la galeria, tendremos que agregarselas
    if (proyecto_?.Galeria?.length > 0) {
      setGaleria((previasImagenes) => previasImagenes.concat(imagesArray));
    } else {
      ///sino son nuevas imagenes o un nuevo proyecto
      setGaleria(imagesArray)
    }
  }

  ///funcion para eliminar de los listados
  const EliminarLista = (imagen) => {
    setGaleria(galeria.filter((e) => e !== imagen))
    setUrl(url.filter((e) => e !== imagen))
  }



  useEffect(() => {
    ///Traemos las categorias
    getDataCategorias();
    getDataProducto();

    if (proyecto_) {
      setField('Nombre', proyecto_?.Nombre);
      forme.Nombre = proyecto_?.Nombre;
      setField('Descripcion', proyecto_?.Descripcion);
      forme.Descripcion = proyecto_?.Descripcion;
      setField('Proposito', proyecto_?.Proposito);
      forme.Proposito = proyecto_?.Proposito;
      setField('Productos', proyecto_?.Productos);
      forme.Productos = proyecto_?.Productos;
    }
  }, [])

  return (
    <>
      <div className='container-form-user container-xxl'>
        <div className="field w-100">
          <span className="p-float-label p-input-icon-right w-100">
            <InputText className={!errors.Nombre ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Nombre} onChange={e => setField('Nombre', e.target.value)} required />
            <label htmlFor="description">Nombre del proyecto</label>
          </span>
          <span className="invalid">{errors.Nombre}</span>
        </div>
        <div className='field w-100'>
          <span className="p-float-label">
            <InputTextarea className={!errors.Descripcion ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Descripcion} onChange={e => setField('Descripcion', e.target.value)} required rows={6} cols={30} />
            <label htmlFor="username">Descripcion del proyecto</label>
          </span>
          <span className="invalid">{errors.Descripcion}</span>
        </div>
        <div className='field w-100'>
          <span className="p-float-label">
            <InputTextarea className={!errors.Proposito ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Proposito} onChange={e => setField('Proposito', e.target.value)} required rows={6} cols={30} />
            <label htmlFor="username">Proposito del proyecto</label>
          </span>
          <span className="invalid">{errors.Proposito}</span>
        </div>
        <p className='fw-bold'>Categorías de proyectos</p>
        <div className='row gap-3 justify-content-center'>
          {categorias?.map((category) => {
            return (
              <div key={category.id} className="col-md-4">
                <div className="d-flex align-items-center">
                  <Checkbox
                    inputId={category.id}
                    name="category"
                    value={category.id}
                    onChange={onCategoriaChange}
                    checked={proyecto_?.Categorias?.some((item) => item === category.id)}
                  />
                  <label htmlFor={category.id} className="ms-2">{category.nombre}</label>
                </div>
              </div>
            );
          })}
        </div>
        <p className='fw-bold'>Productos para el proyecto</p>
        <div className='row gap-3 justify-content-center w-100'>
          <MultiSelect value={productos_} onChange={(e) => selectedProductos(e.value)} options={productos} optionLabel="Nombre"
            placeholder="Selecciona uno o varios productos" maxSelectedLabels={2} className="w-full md:w-20rem" />
        </div>

        {/* Apartado multimedia del proyecto */}
        <div className="col-md-12 text-center">
          <p className='fw-bold'>Imágen de portada del proyecto</p>
          <div>
            <label htmlFor="ProductoImagen">
              <input
                accept='image/*'
                id='ProductoImagen'
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={handleChangeImagen}
              />
              <LazyLoadImage
                effect="opacity"
                alt="Account-picture"
                src={photoURL ? photoURL : process.env.PUBLIC_URL + '/empty.webp'}
                key={proyecto_?.Id} width="350" height="350" />
            </label>
          </div>
        </div>
        {/* Apartado para la galeria */}
        <p className='fw-bold'>Galeria del proyecto</p>
        <span className='fw-light p-0'>{galeria.length ? galeria.length : "0"} imágenes</span>

        <section className='section-galeria'>
          <label htmlFor="images">
            + Agrega imágenes a la galería
            <br />
            <input
              type="file"
              id="images"
              onChange={(e) => OnSelectFile(e)}
              multiple
              accept='image/*'
            />
          </label>
        </section>

        <div className='row'>
          {
            galeria?.map((imagen, index) => {
              return (
                <>
                  <div className='col-md-4 mb-1 mt-1 d-flex justify-content-center'>
                    <Image
                      width="200" height="200"
                      src={imagen}
                      preview
                      alt='upload'
                      key={index}
                    />
                    <button className='btn btn-danger' onClick={() => EliminarLista(imagen)}><IoTrashBinSharp className='me-2' /> Eliminar</button>
                  </div>
                </>

              )
            })
          }
        </div>
      </div>
      <div className='footer-modal'>
        <Button label="Cancelar" icon="pi pi-times" onClick={() => Hide()} className="p-button-text p-button-danger" />
        <Button label={proyecto_?.Id ? 'Editar' : 'Guardar'} onClick={() => SubmitChangeUploads()} icon="pi pi-check" autoFocus className='p-button-success' />
      </div>
    </>

  )
}

export default FormProyecto
