import React from 'react'
import { useHistory } from 'react-router-dom';
import { UserAuth } from '../../services/AuthContext';

/// para proteccion de rutas en caso el usuario no se encuentre logueado
const Protected = ({ children }) => {
    const { user } = UserAuth();
    const history = useHistory();
    if (user) {
        return history.push('/');
    }
    return children;
}

export default Protected