import React from 'react'
import { useState } from 'react'
import db from '../Firebase/firebaseConfig';
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";

/// Custom hook para traer las categorias
const GetCategorias = () => {
    const [categorias, setCategorias] = useState(null);
    var unsubscribe;

    const getDataCategorias = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "categories"), orderBy("id"))
        unsubscribe = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setCategorias(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    return {
        categorias,
        setCategorias,
        getDataCategorias,
    }
}

export default GetCategorias
