import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
    /// lista de productos que iran a los me gusta de los usuarios
    whishListProducts: JSON.parse(localStorage.getItem('whishProducts')) || JSON.parse(localStorage.getItem('whishProducts')) !== [] ? JSON.parse(localStorage.getItem('whishProducts')) : null,
    userActive: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
});



export { useGlobalState, setGlobalState };