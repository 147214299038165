import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import Categories from './pages/Categories/Categories';
import NotFound from './components/NotFound/NotFound';
import Product from './pages/Product/Product';
import ScrollTop from './components/ScrollTop/ScrollTop';
import Projects from './pages/Projects/Projects';
import Likeds from './pages/Likeds/Likeds';
import { Toaster } from 'react-hot-toast';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer'
import Home from './pages/Home/Home';
import { useEffect, useState } from 'react';
import Login from './pages/Login/Login';
import { AuthContextProvider, UserAuth } from './services/AuthContext';
import Protected from './components/Protected/Protected';
import Project from './pages/Project/Project';
import ForgetPassword from './pages/Login/ForgetPassword';
import Account from './pages/Account/Account';
import ProtectedUser from './components/Protected/ProtectedWithUser';
import ModalAd from './components/ModalAd/ModalAd';
import { useGlobalState } from './services/GlobalState';
import { Administracion } from './pages/Administracion/Administracion';
        

/// Agregamos bootstrap 5 a nuestro proyecto

/// Definimos nuestra pagina principal de rutas para el sitio 
function App() {
  const [showModal, setShowModal] = useState(false);
  /// con esta variable veremos si el usuario ya ha entrado en esta sesion al sitio.
  const vista = sessionStorage.getItem('View')
  /// funcion que buscara el agrupador y le añadira la clase para desaparecerlo.
  const FadeOut = () => {
    const loaderWrapper = document.querySelector('.agrupador');
    loaderWrapper?.classList.add('fade');
  }
  /// funcion que abrira el modal
  const openModal = () => {
    setShowModal(prev => !prev)
  }

  ///Configuraciones generales
  const value = {

  }
    

  
  useEffect(() => {
    /// veremos si el sitio ya fue visitado, de ser asi no mostrar el modal y el caso contrario con lo mismo
    if (vista === true) {
      /// no se hara nada porque se sabra que el modal ya se abrio
      /// en caso ni encuentre la variable el modal se disparara y marcara como verdadero para indicar 
      /// que el sitio ya fue visitado
    } else if (vista === false || vista === null) {
      openModal();
      document.body.style.overflow = 'hidden';
    }
    /// usaremos esto para saber cuando se cargo por completo nuestro sitio 
    window.addEventListener('load', FadeOut);


  }, [])


  return (

      <AuthContextProvider>
        <div className="App">
          {/* El preloader cuando inicia la app */}
          <div className='agrupador'>
            <div className='cargador'>
              <div className='loading-spinner'></div>
            </div>
          </div>
          <Router>
            <div>
              <Route exact={true} path="/Login">
                {/* Ruta protegida en caso un usuario no este logueado si pueda acceder */}
                <Protected><Login></Login></Protected>
              </Route>
              <Route exact={true} path="/Forget-password">
                {/* Ruta protegida en caso un usuario no este logueado si pueda acceder */}
                <Protected><ForgetPassword /></Protected>
              </Route>
            </div>
            <Toaster
              reverseOrder={true} />
            <ScrollTop>
              {/* Seccion de rutas que no dependeran del navabar y footer */}
              {/* ----------------------- */}
              <div>
                {/* Modal que se mostrara cuando se cargue por primera vez */}
                <ModalAd showModal={showModal} setShowModal={setShowModal}></ModalAd>
                {/* Seccion que tendra el navbar y footer presentes */}
                <NavBar />
                <Switch>
                  <Route exact={true} path="/" component={Home} />
                  <Route exact path="/Categories" component={Categories} />
                  <Route exact path="/Projects" component={Projects} />
                  <Route exact path="/Project/:id" component={Project} />
                  <Route exact path="/Product/:id" component={Product} />
                  <Route exact path="/Likeds"  >
                    <ProtectedUser><Likeds></Likeds></ProtectedUser>
                  </Route>
                  <Route exact path="/Account" >
                    <ProtectedUser><Account /></ProtectedUser>
                  </Route>
                  {/* Ruta para ir a la administracion directa del sitio */}
                  <Route exact path="/Administracion" >
                    <ProtectedUser><Administracion /></ProtectedUser>
                  </Route>


                  {/* hacemos un redirect con las rutas que no estaran bajo un navbar y un footer */}
                  <Redirect from='/Login' to="/Login" />
                  <Redirect from='/Forget-password' to="/Forget-password" />
                  {/* Ruta para las paginas no encontradas. */}
                  <Route path='*' component={NotFound} />
                </Switch>
                <Footer />
              </div>
            </ScrollTop>
          </Router>
        </div>
      </AuthContextProvider>

  );
}

export default App;
