import React, { useState } from 'react'
import './Likeds.css'
import { useGlobalState } from '../../services/GlobalState';
import PaginationOwn from '../../components/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import LikedsFull from './LikedsFull';
import LikedsSmall from './LikedsSmall';
import { IoList } from 'react-icons/io5';
import { IoGrid } from 'react-icons/io5';
import { IoSearchCircle } from 'react-icons/io5';
import DataforRegister from '../../components/DataforRegister/DataforRegister';
import BotonScrollUp from '../../components/BotonScrollUp/BotonScrollUp';


function Likeds() {
    /// estas son las opciones que este elemento tendra para setear los registros por pagina.
    const arrayfiltered = [
        4, 8, 12, 16
    ]

    // variable que servira para realizar la busqueda
    const [Busqueda, SetBusqueda] = useState('');
    /// Seteamos nuestra lista de me gusta
    const [Likeds, setLikeds] = useGlobalState('whishListProducts');
    const [FiltroLista, setFiltroLista] = useState(true);
    /// registros filtrados traidos del input
    const registrosfiltrados = Likeds?.filter(
        producto =>
            producto.Nombre.toLocaleLowerCase().includes(Busqueda.toLocaleLowerCase()) || !Busqueda
    );
    //variables directas para la paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(8);
    /// para la paginacion
    const indexOfLastItem = currentPage * postsPerPage;
    const indexOfFirstItem = indexOfLastItem - postsPerPage;
    const currentItems = registrosfiltrados?.slice(indexOfFirstItem, indexOfLastItem);
    ///change page function scrolling top
    const paginate = pageNumber => {
        setCurrentPage(pageNumber)
    };

    function changeList() {
        if (FiltroLista == false) {
            setFiltroLista(true);
        } else {
            setFiltroLista(false)
        }
    }

    return (
        <div>
            {Likeds?.length === 0 ?
                <div className="contenedor-notfound">
                    <p>Vacío</p>
                    <h1>Tu lista de me gusta está vacía.</h1>
                    <span>Agrega tus productos favoritos.</span>
                    <Link to={`/`}>
                        Regresar al inicio</Link>
                </div>
                :
                <div className='container-likeds container-xxl'>
                    <div className='full-list'>
                        <h1 className='header-likeds'>LISTA DE ME GUSTA</h1>
                        <p className='description-likeds'>En esta sección encontrarás todos los productos a los cuales les has dado me gusta.</p>
                        <div className='likeds'>
                            <p className='num-likeds'>Tienes {Likeds?.length} producto que te ha(n) gustado</p>
                            <div className="filters-likeds">
                                <div className="finder-likeds">
                                    {/* Caja de busqueda para dispositovs grandes */}
                                    <div className="box">
                                        <input type="search" className="input" onChange={(event) => {
                                            SetBusqueda(event.target.value)
                                            setCurrentPage(1);
                                        }} placeholder='Ingrese su búsqueda...'></input>
                                        <span><IoSearchCircle size="3.2rem" /></span>

                                    </div>
                                </div>
                                <div className="finder-likeds">
                                    {/* Filtraremos los elementos */}
                                    <DataforRegister postsPerPage={postsPerPage} setPostPerPage={setPostPerPage} arrayfiltered={arrayfiltered}
                                        setCurrentPage={setCurrentPage} />
                                    {
                                        FiltroLista == false ?
                                            <span className='filter-change'><IoList onClick={changeList} size="1.8rem" /> </span> :
                                            <span className='filter-change'><IoGrid onClick={changeList} size="1.8rem" /> </span>
                                    }
                                </div>

                            </div>
                            {/* Caja de busqueda cuando el disposito sea un celular o un movil */}
                            <div className="box-min">
                                <input type="search" className="input" onChange={(event) => {
                                    SetBusqueda(event.target.value)
                                    setCurrentPage(1);
                                }} placeholder='Ingrese su búsqueda...'
                                ></input>
                                <span><IoSearchCircle size="3.2rem" /></span>

                            </div>
                            {
                                Busqueda != '' ?
                                    <div className='result-likeds'>
                                        <p>Resultado de tu búsqueda para '{Busqueda}'</p>
                                    </div> : ''
                            }

                            {/* Full list wishlist with components validando si los registros traen datos*/}
                            {
                                registrosfiltrados?.length === 0 ?
                                    <div className='empty-likeds'>
                                        <div>
                                            <h1>Sin registros para mostrar</h1>
                                            <p>Tu búsqueda no arrojó ningún resultado, prueba con un término diferente...
                                            </p>
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + '/nodata.png'} alt="" />
                                        </div>

                                    </div> : ''
                            }

                            {
                                FiltroLista == false ?
                                    <motion.div layout>
                                        <LikedsSmall currentPage={currentPage} currentItems={currentItems} setCurrentPage={setCurrentPage} />
                                    </motion.div> :
                                    <motion.div layout>
                                        <LikedsFull currentPage={currentPage} currentItems={currentItems} setCurrentPage={setCurrentPage} />
                                    </motion.div>
                            }

                        </div>
                        <PaginationOwn postsPerPage={postsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPosts={registrosfiltrados?.length} paginate={paginate} />
                    </div>
                </div>
            }
            <BotonScrollUp />
        </div>

    )
}

export default Likeds