import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";
import { motion } from "framer-motion"
const SkeletonCard = () => {
    return (
        <motion.div layout className="skeleton-wrapper-card light">
            <SkeletonElement type="card"></SkeletonElement>
            <SkeletonElement type="text-card"></SkeletonElement>
            <SkeletonElement type="button"></SkeletonElement>
            <Shimmer />
        </motion.div>

    )
}
export default SkeletonCard;