import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';

import React from 'react'

const SkeletonProductos = () => {
    const categorias = Array.from({ length: 5 });
    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }
    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText type="search" placeholder="Buscar..." />
            </span>
        </div>
    );
    return (
        <div>
            <DataTable value={categorias} className="p-datatable-striped" header={header}>
                <Column field="id" header="Imagen" style={{ width: '25%' }} body={bodyTemplate}></Column>
                <Column field="name" header="Nombre" style={{ width: '25%' }} body={bodyTemplate}></Column>
                <Column field="quantity" header="Acciones" style={{ width: '25%' }} body={bodyTemplate}></Column>
            </DataTable>
        </div>
    )
}

export default SkeletonProductos
