import { collection, setDoc, doc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from "firebase/firestore";
import db from '../Firebase/firebaseConfig';
import DeleteFile from "./DeleteFile";


/// Clase que contendra todos los metodos de logica de la base de datos
export class ApiService {
    constructor() {
    }

    //#region  Productos
    AddCategoria = async (categoria) => {
        return new Promise((resolve, reject) => {
            try {
                const CategoriaRef = doc(collection(db, "categories"));
                setDoc(CategoriaRef,
                    {
                        nombre: categoria.nombre, id: CategoriaRef.id,
                    }
                );
                resolve("La categoría ha sido agregada con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }
    EditCategoria = async (categoria) => {
        console.log(categoria)
        return new Promise((resolve, reject) => {
            try {
                const CategoriaRef = doc(db, "categories", categoria.id);
                updateDoc(CategoriaRef, {
                    nombre: categoria.nombre,
                });
                resolve("La categoría se ha editado con éxito.")
            } catch (error) {
                reject(error);
            }
        })

    }
    DeleteCategoria = async (categoria) => {
        return new Promise((resolve, reject) => {
            try {
                deleteDoc(doc(db, "categories", categoria.id));
                resolve("La categoría se ha eliminado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }
    AddProducto = async (producto) => {
        console.log(producto)
        return new Promise((resolve, reject) => {
            try {
                const ProductoRef = doc(collection(db, "products"));
                setDoc(ProductoRef,
                    {
                        Id: ProductoRef.id,
                        Nombre: producto.Nombre,
                        Uso: producto.Uso,
                        Descripcion: producto.Descripcion,
                        Categoria: producto.Categoria ? producto.Categoria : [],
                        Imagen: producto.Imagen ? producto.Imagen : "",
                        Ficha: producto.Ficha ? producto.Ficha : ""
                    }
                );
                resolve("El producto ha sido agregado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }
    EditProducto = async (producto) => {
        console.log(producto)
        return new Promise((resolve, reject) => {
            try {
                const ProductoRef = doc(db, "products", producto.Id);
                updateDoc(ProductoRef,
                    {
                        Nombre: producto.Nombre,
                        Uso: producto.Uso,
                        Descripcion: producto.Descripcion,
                        Categoria: producto.Categoria,
                        Imagen: producto.Imagen,
                        Ficha: producto.Ficha
                    }
                );
                resolve("El producto ha sido editado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }
    DeleteProducto = async (producto) => {
        console.log(producto)
        return new Promise((resolve, reject) => {
            try {
                deleteDoc(doc(db, "products", producto.Id));
                DeleteFile(`Fichas/${producto?.Ficha}`)
                DeleteFile(`Products/${producto?.Imagen}`)
                resolve("El producto se ha eliminado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }

    //#endregion

    //#region  Proyectos
    AddCategoriaProyecto = async (categoria) => {
        return new Promise((resolve, reject) => {
            try {
                const CategoriaRef = doc(collection(db, "proyects"));
                setDoc(CategoriaRef,
                    {
                        nombre: categoria.nombre, id: CategoriaRef.id,
                    }
                );
                resolve("La categoría ha sido agregada con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }
    EditCategoriaProyecto = async (categoria) => {
        return new Promise((resolve, reject) => {
            try {
                const CategoriaRef = doc(db, "proyects", categoria.id);
                updateDoc(CategoriaRef, {
                    nombre: categoria.nombre,
                });
                resolve("La categoría se ha editado con éxito.")
            } catch (error) {
                reject(error);
            }
        })

    }
    DeleteCategoriaProyecto = async (categoria) => {
        return new Promise((resolve, reject) => {
            try {
                deleteDoc(doc(db, "proyects", categoria.id));
                resolve("La categoría se ha eliminado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }


    AddProyecto = async (proyecto) => {
        return new Promise((resolve, reject) => {
            try {
                const ProyectoRef = doc(collection(db, "proyectos"));
                setDoc(ProyectoRef,
                    {
                        Nombre: proyecto.Nombre,
                        Descripcion: proyecto.Descripcion,
                        Proposito: proyecto.Proposito,
                        Categorias: proyecto.Categorias ? proyecto.Categorias : [],
                        Productos: proyecto.Productos ? proyecto.Productos : [],
                        Portada: proyecto.Portada ? proyecto.Portada : "",
                        Galeria: proyecto.Galeria ? proyecto.Galeria : [],
                        Id: ProyectoRef.id,
                    }
                );
                resolve("El proyecto ha sido agregado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }

    EditProyecto = async (proyecto) => {
        console.log(proyecto)
        return new Promise((resolve, reject) => {
            try {
                const ProyectoRef = doc(db, "proyectos", proyecto.Id);
                updateDoc(ProyectoRef,
                    {
                        Nombre: proyecto.Nombre,
                        Descripcion: proyecto.Descripcion,
                        Proposito: proyecto.Proposito,
                        Categorias: proyecto.Categorias ? proyecto.Categorias : [],
                        Productos: proyecto.Productos ? proyecto.Productos : [],
                        Portada: proyecto.Portada ? proyecto.Portada : "",
                        Galeria: proyecto.Galeria ? proyecto.Galeria : [],
                    }
                );
                resolve("El proyecto ha sido agregado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }

    DeleteProyecto = async (proyecto) => {
        return new Promise((resolve, reject) => {
            try {
                deleteDoc(doc(db, "proyectos", proyecto.Id));
                resolve("El proyecto se ha eliminado con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }

    //#endregion

    //#region Anuncios
    EditModalAd = async (modalAd) => {
        return new Promise((resolve, reject) => {
            try {
                const ModalAdRef = doc(db, "modal_ads", modalAd?.Id);
                updateDoc(ModalAdRef, {
                    url: modalAd?.url ? modalAd?.url : "",
                    doc: modalAd?.doc ? modalAd?.doc : ""
                });
                resolve("El modal de promociones se ha editado con éxito.")
            } catch (error) {
                reject(error);
            }
        })

    }

    AddAd = async (ad) => {
        return new Promise((resolve, reject) => {
            try {
                const AdRef = doc(collection(db, "ads"));
                setDoc(AdRef,
                    {
                        id: AdRef.id,
                        url: ad?.url ? ad?.url : ""
                    }
                );
                resolve("La promoción ha sido agregada con éxito.")
            } catch (error) {
                reject(error);
            }
        })
    }

    EditAd = async (ad) => {
        return new Promise((resolve, reject) => {
            try {
                const AdRef = doc(db, "ads", ad.id);
                updateDoc(AdRef, {
                    url: ad?.url ? ad?.url : ""
                });
                resolve("La promoción se ha editado con éxito.")
            } catch (error) {
                reject(error);
            }

        })
    }

    DeleteAd = async (ad) => {
        return new Promise((resolve, reject) => {
            try {
                deleteDoc(doc(db, "ads", ad.id));
                resolve("La promoción se ha eliminado con éxito.")
            } catch (error) {
                reject(error);
            }
        })

    }
    //#endregion
}