import React, { useEffect, useState } from 'react'
import './Projects.css'
import { IoSearchCircle } from 'react-icons/io5';
import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
import { motion } from "framer-motion"
import PaginationOwn from '../../components/Pagination/Pagination';
import CardProject from '../../components/CardProject/CardProject';
import SkeletonCard from '../../skeletons/SkeletonCard';
import FilterProject from './FilterProject';
import FiltersmallProject from './FiltersmallProject';
import SkeletonCardP from '../../skeletons/SkeletonCardP';
import BotonScrollUp from '../../components/BotonScrollUp/BotonScrollUp';

function Projects() {
    // Variables para usar
    const [ListaProyectos, setListaProyectos] = useState([]);
    const [filtrado, setFiltrado] = useState([]);
    const [Busqueda, SetBusqueda] = useState('');
    const [Categoria, SetCategoria] = useState('Todos los proyectos');
    const [Active, setActive] = useState('all');
    // variables para la paginacion
    //variables directas para la paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(6);

    const registrosfiltrados = filtrado.filter(
        proyecto =>
            proyecto.Nombre.toLocaleLowerCase().includes(Busqueda.toLocaleLowerCase()) || !Busqueda
    );

    /// para la paginacion
    const indexOfLastItem = currentPage * postsPerPage;
    const indexOfFirstItem = indexOfLastItem - postsPerPage;
    const currentItems = registrosfiltrados.slice(indexOfFirstItem, indexOfLastItem);


    ///change page function scrolling top
    const paginate = pageNumber => {
        setCurrentPage(pageNumber)
    };


    ///Funciones asincronas para traer los datos directamente de firebase
    const getDataProyecto = () => {

        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "proyectos"), orderBy("Nombre"))

        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setListaProyectos(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))

            setFiltrado(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    useEffect(() => {
        getDataProyecto();
        document.title = 'Luz & Color Pinturas Protecto®| ' + Categoria;
    }, [])

    return (
        <div className="container-projects pb-5">
            <div className="container-xxl">
                <div className="projects">
                    <h1>Proyectos de Pintado</h1>
                    <p>Mostrando los proyectos de: <span className='CategoriaProyectol'>{Categoria}</span></p>
                </div>
                <p className="number"> Mostrando {registrosfiltrados.length ? registrosfiltrados.length : '0'} resultado(s) disponible(s) </p>
                <div className="chipers">
                    {/* Filtros */}
                    <FilterProject listaProyectos={ListaProyectos} setFiltrado={setFiltrado} Active={Active} setActive={setActive} SetCategoria={SetCategoria} Categoria={Categoria} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    <FiltersmallProject listaProyectos={ListaProyectos} setFiltrado={setFiltrado} Active={Active} setActive={setActive} SetCategoria={SetCategoria} Categoria={Categoria} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                    <div className="wrapper">
                        <IoSearchCircle className="iconoBusqueda" size="3.5rem"></IoSearchCircle>
                        <input type="search"
                            onChange={(event) => {
                                SetBusqueda(event.target.value)
                                /// Seteamos la primera pagina
                                setCurrentPage(1);
                            }}
                            className="form-control barra-Busqueda" placeholder="Ingrese su búsqueda..."></input>
                    </div>

                </div>

                {/* Consultamos si trae resultados */}
                {
                    registrosfiltrados.length == 0 ? '' :

                        Busqueda != '' ? <p className="Finder-2">Buscando su término '{Busqueda}'</p> : ''
                }
                {
                    registrosfiltrados.length == 0 ? <p className="Finder-2">No hay resultados para mostrar</p> : ''
                }

                <motion.div layout className="listado-proyectos mb-4 mt-3">
                    {
                        registrosfiltrados.length > 0 ?
                            currentItems?.map(proyecto => {
                                return (
                                    <CardProject key={proyecto.key} Project={proyecto} />
                                )
                            })
                            :
                            ListaProyectos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                return (
                                    <>
                                        <SkeletonCardP key={n}></SkeletonCardP>
                                    </>
                                )
                            })
                    }
                </motion.div>
                <PaginationOwn postsPerPage={postsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPosts={registrosfiltrados.length} paginate={paginate} />
            </div>
            <BotonScrollUp />
        </div>
    )
}

export default Projects