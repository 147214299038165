import React from 'react'
import { motion } from "framer-motion"
import './CardProject.css'
import { IoBrushSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
/// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
function CardProject({ Project }) {
    return (
        <motion.div layout>
            <Link to={`/Project/${Project.key}`} className="card">
                {/* Componente adecuado para el lazy load */}
                <LazyLoadImage
                    effect="opacity"
                    alt={Project.Portada}
                    src={Project.Portada}
                    key={Project.key}
                    className="card__image" />
                <div className="card__overlay">
                    <div className="card__header">
                        <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                        <div className="card__header-text">
                            <h3 className="card__title">{Project.Nombre}</h3>
                            <span className="card__status">Ver más</span>
                        </div>
                    </div>

                    <p className="card__description">
                        {
                            Project.Proposito.length > 150 ? `${Project.Proposito.substring(0, 150)}...` : Project.Proposito
                        }
                    </p>
                </div>
            </Link>
        </motion.div>
    )
}

export default CardProject