import React, { useEffect, useRef, useState } from 'react'
import { UserAuth } from '../../services/AuthContext';
import './Account.css'
import Providers from './Left-side/Providers';
import { IoCheckmarkDoneSharp, IoClose } from "react-icons/io5";
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import { motion } from "framer-motion"
import toast from 'react-hot-toast';
import { updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db from '../../Firebase/firebaseConfig';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method primefaces
import { Messages } from 'primereact/messages';


import HeaderAccount from './HeaderAccount';
import { useGlobalState } from '../../services/GlobalState';
import ContactModal from '../../components/ContactModal/ContactModal';
import FormLinkPassword from './Form/FormLinkPassword';
// parametrizacion global de moment
Moment.globalMoment = moment;
Moment.globalLocale = 'es';

function Account() {
    /// usuario traido desde el contexto
    const { user, verifyAccount } = UserAuth();
    /// usuario traido desde el local storage
    const [usuario, setUsuario] = useGlobalState('userActive');
    const [usuariocopy, setUsuariocopy] = useState(null)
    const [toggle, setToggle] = useState(true);
    const [forme, setForme] = useState({});
    const [errors, setErrors] = useState({});
    const mensajeVerified = useRef(null);



    // variables que usaremos para el proyecto individual y tenerlo en memoria
    var userdb = {};
    /// Mostrar el mini menu antes del modal

    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    /// Funcion que valida los campos
    const validateForm = () => {
        const { name } = forme
        const newErrors = {}
        // if (!validacion || validacion === '') newErrors.validacion = 'Verifique que no es un robot para continuar'
        if (!name || name === '') newErrors.name = 'El nombre no puede ir vacío.'
        else if (name.length < 4) newErrors.name = 'El nombre es demasiado corto.'
        else if (name.length > 50) newErrors.name = 'El nombre es demasiado largo.'
        return newErrors;
    }

    function setuser(object) {
        userdb = object;
    }
    /// funcion que trae el usuario desde la db
    const getUserdb = async () => {
        const userRef = doc(db, "users", usuario.id);
        try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                setuser({
                    ...docSnap.data(),
                })
                setUsuariocopy(userdb);
            } else {
                setUsuariocopy(null);
            }
        } catch (error) {
            console.log(error);
        }
        showVerifyMessage();
    }

    /// hacer toggle entre el nombre y el input
    const dobleClickName = () => {
        // seteamos los campos a modificar
        setField('name', user?.displayName)
        forme.name = user?.displayName;
        setToggle(!toggle);
    }

    const CambiarNombre = async () => {
        if (user?.emailVerified) {
            const { name } = forme
            const formErrors = validateForm();
            if (Object.keys(formErrors).length > 0) {
                setErrors(formErrors)
            } else {
                /// nuestra referencia al usuario para la modificacion
                const userRef = doc(db, "users", user?.uid);
                try {
                    await updateProfile(user, {
                        displayName: name
                    });
                    /// modoficaremos el nombre en la base de datos
                    await updateDoc(userRef, {
                        "nombre": name,
                    });
                    /// actualizamos la variable de sesion y el estado global
                    const new_user = { ...usuario, nombre: name }
                    setUsuario(new_user);
                    localStorage.setItem("user", JSON.stringify(new_user));
                    /// mostramos un mensaje que si se cambio correctamente
                    toast.success('Se ha cambiado el nombre de la cuenta.')
                    setToggle(!toggle);
                } catch (error) {
                    toast.error('Ha ocurrido en el cambio del nombre.')
                }
            }
        } else {
            toast.error('Verifique su cuenta para modificar su nombre.')
        }
    }

    /// mostramos una ventana de dialogo para el cambio del nombre
    const confirmCambiarNombre = () => {
        confirmDialog({
            message: 'Cambiarás el nombre de tu cuenta, estás seguro?',
            acceptLabel: 'Si, estoy seguro',
            rejectLabel: 'No, cancelar',
            header: 'Alerta de Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => CambiarNombre(),
            reject: () => setToggle(!toggle),
        });
    }
    /// mostraremos un mensaje para que el usuario verfique su cuenta
    const showVerifyMessage = () => {
        mensajeVerified.current.show({
            severity: 'error', closable: false, summary: 'Error', sticky: true, content: (
                <React.Fragment>

                    <div className="error-message-verified">
                        <p><i className='pi pi-flag-fill me-2'></i>Su cuenta no se encuentra verificada. <span onClick={() => verify()}>Verifiquela aquí.</span></p>
                    </div>
                </React.Fragment>
            )
        });
    }
    /// funcion para verificar la cuenta
    const verify = async () => {
        try {
            await verifyAccount()
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        /// cargamos el usuario de la base de datos
        getUserdb();
        document.title = 'Luz & Color Pinturas Protecto® | Mi cuenta';
        /// desde el primer cargado de la pagina seteamos los datos
        setField('name', user?.displayName)
        forme.name = user?.displayName;
    }, [])

    return (
        <motion.div
            initial={{
                opacity: 0
            }
            }
            animate={{
                opacity: 1,
                transition: {
                    duration: 0.3
                }
            }}
            exit={{
                opacity: 1,
                transition: {
                    delay: 0.3
                }
            }}
            className='container-Account container-xxl' key={user?.uid} >
            <>
                <div className="account-details">
                    <motion.div
                        initial={{
                            x: -20,
                            opacity: 0
                        }}
                        animate={
                            {
                                x: 0,
                                opacity: 1,
                                transition: {
                                    duration: 0.5
                                }
                            }
                        }
                        exit={{
                            x: -20,
                            opacity: 0,
                            transition: {
                                duration: 0.3,
                            }
                        }}
                        className="card-account">
                        {/* Encabezado donde esta la foto de perfil y la portada */}
                        <HeaderAccount usuario={usuariocopy} setUsuario={setUsuariocopy}></HeaderAccount>
                        <div className="body-card-account">
                            {
                                toggle ? (
                                    <h1 onClick={() => dobleClickName()} >{user?.displayName} <span>{user?.emailVerified ? <IoCheckmarkDoneSharp size="1.8rem" color='green' /> : '*Sin verificar*'}</span> </h1>
                                ) : (
                                    <div className='container-inputName'>
                                        <div className="login__field">
                                            <input
                                                value={forme.name}
                                                onChange={e => setField('name', e.target.value)}
                                                className={!errors.name ? 'login__input login__input' : 'error-borde-login login__input'}
                                                type="text" placeholder="Nombre Completo" />
                                            <IoClose onClick={() => dobleClickName()} size="1.2rem" className="login__icon-password ms-3" />
                                            <div className="invalid">{errors.name}</div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                toggle ? '' : <button onClick={() => confirmCambiarNombre()} className='btn btn-success save-btn mb-4' >Guardar cambios</button>
                            }
                            <p>{user?.email}</p>
                            <div className="dates-account">
                                <div className="createdAt">
                                    <p>Creación de la cuenta</p>
                                    <Moment className='fecha-account' fromNow>{user?.metadata?.creationTime}</Moment>
                                </div>
                                <div className="last-login">
                                    <p>Último inicio de sesión</p>
                                    <Moment locale="es" className='fecha-account' fromNow>{user?.metadata?.lastSignInTime}</Moment>
                                </div>
                            </div>
                        </div>
                        <div className="footer-card-account">
                            <div className="services-footer">
                                <p className='services-account'>Servicios de inicio de sesión: </p>
                            </div>
                            <Providers providers={user?.providerData} ></Providers>
                        </div>
                    </motion.div>
                </div>
                <div className="account-things">
                    {
                        user?.emailVerified ? '' : <Messages ref={mensajeVerified}></Messages>
                    }
                    <h1>Complementos de la cuenta</h1>
                    <p>En construcción, espéranos...</p>
                </div>
            </>
            {/* Instanciamos un componente para mostar el modal de confirmacion */}
            <ConfirmDialog />
        </motion.div >
    )
}

export default Account