import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import './ModalmodPic.css'
import toast from 'react-hot-toast';
import { IoAddCircle, IoCloseCircle } from 'react-icons/io5'
import portada from '../portada2.jpg';
import { v4 as uuidv4 } from 'uuid';
import db from '../../../Firebase/firebaseConfig';
import UploadFile from '../../../services/UploadFile';
import DeleteFile from '../../../services/DeleteFile';
const ModalmodPortada = ({ usuario, estado, CambiarEstado, setUsuario }) => {
    /// estado que nos servira para hacer la subida y el cambio en el usuario
    const [file, setFile] = useState(null);
    /// seteamos la foto del usuario la cual se manipulará para la subida y el cambio
    const [photoURL, setPhotoURL] = useState(usuario?.fotoPortada)
    /// para cargar
    const [loading, setLoading] = useState(false);

    const estilo = {
        backgroundImage: `url(${photoURL ? photoURL : portada})`,
    }

    const handleChange = (e) => {
        const file = e.target.files[0]
        /// verificiaremos si se ha seleccionado una foto para la subida
        if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            setFile(file);
            /// seteamos la imagen para que esta se muestre al momento del cambio
            setPhotoURL(URL.createObjectURL(file));
        } else if (!file) {
            toast.error('Elige una fotografía para continuar.')
        }
        /// validamos si es una foto
        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            toast.error('Formato de archivo no válido')
            return false;
        }
    }

    /// funcion encargada para la subida de la foto de portada
    const SubmitChange = async () => {
        setLoading(true);
        let url;
        /// nuestra referencia al usuario para la modificacion
        const userRef = doc(db, "users", usuario?.id);
        try {
            /// si el archivo aun esta en memoria lo nombramos personalizado
            /// y lo subirmos, pasandole la path
            if (file) {
                const ImageName = uuidv4() + '.' + file?.name?.split(".")?.pop();
                url = await UploadFile(file, `profile/${usuario?.id}/${ImageName}`);
            };
            // /// en caso el usuario tenga una fotografia de portada la borraremos para sustituirla por la nueva
            if (usuario?.fotoPortada && usuario?.fotoPortada != '') {
                const prevImage = usuario?.fotoPortada?.split(`${usuario?.id}%2F`)[1]?.split('?')[0];
                if (prevImage) {
                    try {
                        await DeleteFile(`profile/${usuario?.id}/${prevImage}`)
                    } catch (error) {
                    }
                } else {
                    /// No hacer nada
                }
            }
            // actualizamos el usuario en la base de datos
            await updateDoc(userRef, {
                "fotoPortada": url,
            });

            /// seteamos un nuevo objeto con la copia que tenemos con la nueva foto de portada
            const new_user = { ...usuario, fotoPortada: url }
            setUsuario(new_user);
            localStorage.setItem("user", JSON.stringify(new_user))
            setLoading(false)
            /// damos el mensaje que todo salio bien y cerramos el modal
            toast.success('Portada actualizada con éxito')
            CambiarEstado(!estado);
            document.body.style.overflow = 'unset';
        } catch (error) {
            toast.error('Ha ocurrido un error con la operación.')
            console.log(error);
        }
    }

    useEffect(() => {
    }, [])


    return (
        <div className='modal-fotoPortada'>
            <label htmlFor="PictureProfile" className='container-mod-portada' style={estilo}>
                <div className='container-mod-portada' style={estilo}>
                    {
                        loading ?
                            <input
                                accept='image/*'
                                id='PictureProfile'
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleChange}
                                disabled
                            />
                            :
                            <input
                                accept='image/*'
                                id='PictureProfile'
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleChange}
                            />
                    }

                </div>
            </label>
            {
                loading ?
                    <p className='uploadingPortada pt-2'><i className="pi pi-spin pi-spinner me-2" />Espera, subiendo tu portada..</p>
                    : ''
            }
            <div className="buttons-modal-pic">
                {
                    /// definiremos si esta cargando el boton se deshabilita y si no hay un archivo el boton queda desactivado 
                    /// hasta que se elige uno, una fotografia como tal.
                    loading || !file ?
                        <div>
                            <button disabled onClick={() => SubmitChange()} className='btn btn-success save-btn'><IoAddCircle className='me-2' size="1.2rem"></IoAddCircle>Guardar</button>
                        </div>

                        :
                        <button onClick={() => SubmitChange()} className='btn btn-success save-btn'><IoAddCircle className='me-2' size="1.2rem"></IoAddCircle>Guardar</button>
                }
                <button onClick={() => {
                    CambiarEstado(!estado)
                    document.body.style.overflow = 'unset';
                }} className='btn btn-danger red-btn'><IoCloseCircle className='me-2' size="1.2rem"></IoCloseCircle>Cancelar</button>
            </div>
        </div>
    )
}

export default ModalmodPortada