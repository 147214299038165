import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonProduct = () => {
    return (
        <div className="skeleton-wrapper-Product light">
            <div className="product-container">
                <div>
                    <SkeletonElement type="product-name"></SkeletonElement>
                    <SkeletonElement type="product-img"></SkeletonElement>
                </div>
                <SkeletonElement type="product-data"></SkeletonElement>
            </div>
            <Shimmer />
        </div>

    )
}
export default SkeletonProduct;