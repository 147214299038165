import React from 'react'
import GetModalAds from '../../../Actions/getModalAds';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiService } from '../../../services/ApiService';
import SkeletonModalsAds from './components/SkeletonModalsAds';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoSearchOutline } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FormModalAds from './components/FormModalAds';


const ModalAds = () => {

    const { ads, setAds, getDataModalAds } = GetModalAds();
    const api = new ApiService();
    /// variables que usamos en todo el ciclo
    const [modalAds_, setModalAds] = useState(null);
    const [displayModalAds, setDisplayModalAds] = useState(false);

    /// mostrar el modal de edicion
    const showModalEdit = (rowData) => {
        setDisplayModalAds(true);
        setModalAds(rowData)
    }
    /// esconder el modal y quitar el pais seleccionado
    const Hide = () => {
        setModalAds(null);
        setDisplayModalAds(false)
    }
    /// Apartado para mostrar la imagen en la tabla
    const imageBodyTemplate = (rowData) => {
        return (
            <LazyLoadImage
                effect="opacity"
                alt={rowData.url}
                src={rowData.url}// use normal <img> attributes as props
                key={rowData.url}
                className="shadow-2 border-round" style={{ width: '164px' }} />
        );
    };
    /// mostrar el iframe en la tabla
    const DocBodyTemplate = (rowData) => {
        return (
            <iframe src={rowData.doc} style={{ width: '400px', height: "250px" }} frameborder="0"></iframe>
        );
    };

    /// Acciones para interactuar con la fila
    const AccionesModalAds = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning me-2" onClick={() => showModalEdit(rowData)} />
            </React.Fragment>
        );
    }


    useEffect(() => {
        getDataModalAds();
    }, [])


    return (
        <div>
            <div className='mt-2 mb-3'>
                <div className='d-flex justify-content-end flex-column'>
                    <h3 className='fw-bolder'>Administración de Modal de Promociones</h3>
                    <p className='select-text'>Modifica el modal de presentación de promoción</p>
                </div>
            </div>
            {/* Donde va la datatable y los datos */}
            <div className='w-100'>
                <div className="">
                    {
                        !ads ? <SkeletonModalsAds></SkeletonModalsAds> :
                            <DataTable emptyMessage="Sin registros para mostrar" value={ads} stripedRows
                                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} modales de promoción">
                                <Column field="url" header="Imagen" body={imageBodyTemplate}></Column>
                                <Column field="doc" header="Documento" body={DocBodyTemplate}></Column>
                                <Column header="Acciones" exportable={false} body={AccionesModalAds} style={{ minWidth: '2rem' }}></Column>
                            </DataTable>
                    }

                </div>
            </div>

            <Dialog header={modalAds_ ? "Edición de modal de promociones" : "Mantenimiento de modal de promiciones"} visible={displayModalAds} draggable={false} className="modal-size" onHide={() => Hide()}>
                <FormModalAds Hide={Hide} modalAds_={modalAds_} setModalAds={setModalAds} />
            </Dialog>

        </div>
    )
}

export default ModalAds
