import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import './CardProduct.css'
import { motion } from "framer-motion"
import { IoHeart } from 'react-icons/io5';
import { IoHeartOutline } from 'react-icons/io5';
/// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useGlobalState } from "../../services/GlobalState";
import AddWishList from "../../services/AddWishList";
import DeleteWishList from "../../services/DeleteWishList";
import { UserAuth } from "../../services/AuthContext";
import { toast } from "react-hot-toast";
import GetWishList from "../../services/GetWishList";

const CardProduct = (props) => {
    const history = useHistory();
    const { user } = UserAuth();
    const [usuario, setUsuario] = useGlobalState('userActive');
    // El global context para el listado de productos favoritos
    const [ListaP, setListaP] = useGlobalState('whishListProducts');
    const [Favorito, setFavorito] = useState(false);
    /// seteamos los productos favcoritos para mostrarlos al usuario
    const Favoritoset = async (favorito, producto) => {
        if (user) {
            /// si quitamos el favorito eliminamos de la wishlist
            if (favorito == false) {
                setListaP(await DeleteWishList(producto.Nombre, usuario, producto));
                setUsuario(JSON.parse(localStorage.getItem('user')))
            } else {
                /// agregamos el elemento
                setListaP(await AddWishList(producto, ListaP, usuario))
                setUsuario(JSON.parse(localStorage.getItem('user')))
            }
            setFavorito(favorito);
        } else {
            toast.error('Inicia sesión o regístrate para continuar')
            history.push('/Login')
        }


    }
    // validamos si el elemento existe
    const validateList = async (nombre) => {
        const filtrado = usuario.wishlist.filter((productos) => productos.Nombre.includes(nombre))
        if (filtrado.length > 0) {
            setFavorito(true);
        } else {
            setFavorito(false);
        }
    }

    useEffect(() => {
        validateList(props.producto.Nombre)
    }, [])


    return (
        <motion.div layout>
            <div className="card-product">
                {/* Se usara cuando se ponga el ecommerce * */}
                {/* /* <div className="badge">10% OFF</div> */}
                {/* Validaremos si el elemento existe */}
                {
                    Favorito ?
                        <div>
                            <IoHeart className='badge-favorite' onClick={() => Favoritoset(false, props.producto)} />
                        </div>
                        :
                        <div>
                            <IoHeartOutline className='badge-favorite' onClick={() => Favoritoset(true, props.producto)} />
                        </div>

                }
                <Link className="link-card-product" to={`/Product/${props.producto.key}`}>
                    <LazyLoadImage
                        effect="opacity"
                        alt={props.producto.Imagen}
                        src={props.producto.Imagen}// use normal <img> attributes as props
                        key={props.producto.Imagen}
                        className="img-cardp" /></Link>
                <p>{props.producto.Nombre}</p>
                <p className="price-product">$450.00</p>
                <Link className="link-card-product" to={`/Product/${props.producto.key}`}>
                    <p className="vermas">Ver producto</p></Link>
            </div>

        </motion.div>
    )
}

export default CardProduct;