import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
import './ModalAd.css'
import { IoCloseCircleOutline } from 'react-icons/io5';
/// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AnimatePresence, motion } from "framer-motion"

const ModalAd = ({ showModal, setShowModal }) => {
    const [Lista, setLista] = useState(null);
    const [Listacopy, setListaCopy] = useState(null)

    const getData = async () => {
        const listaAnuncios = [];
        const datos = await getDocs(collection(db, 'modal_ads'));
        datos.forEach((doc) => {
            listaAnuncios.push({
                ...doc.data(),
                key: doc.id
            });
        })
        setLista(listaAnuncios);
        setListaCopy(listaAnuncios);
    }
    /// al cerrar el modal pondremos de nuevo el scroll
    function setData() {
        sessionStorage.setItem('View', true);
        document.body.style.overflow = 'unset';
    };

    /// funcion que cerrara el modal y seteare que el usuario ya lo vio
    function closeModal() {
        setShowModal(prev => !prev)
        setData();
    }

    /// funcion que tendra la imagen y estilo
    // const estilo = {
    //     backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${Listacopy[0]?.Portada})`,
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    // };

    /// seteamos la data del modal
    useEffect(() => {
        getData();
    }, [])

    /// nuestro modal para mostrar promociones
    return (
        <>
            <AnimatePresence>
                {showModal ?
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 0.3
                            }
                        }}
                        exit={{
                            opacity: 1,
                            transition: {
                                delay: 0.3
                            }
                        }} className='Overlay'>
                        <motion.div
                            initial={{
                                y: -300,
                                opacity: 0
                            }}
                            animate={
                                {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.5
                                    }
                                }
                            }
                            exit={{
                                y: -300,
                                opacity: 0,
                                transition: {
                                    duration: 0.3,
                                }
                            }}
                            className='Contenedor-modal'>
                            <div className='Encabezado-modal'>
                                <h3>Promociones Vigentes</h3>
                                <IoCloseCircleOutline onClick={closeModal} className='BotonCerrar' size="1rem"></IoCloseCircleOutline>
                            </div>
                            <motion.div
                                initial={{
                                    x: 20,
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        delay: 0.3,
                                        duration: 0.3
                                    }
                                }}
                                exit={{
                                    x: 20,
                                    opacity: 0,
                                    transition: {
                                        delay: 0.3,
                                        duration: 0.3
                                    }
                                }} className='contenido-form'>
                                {
                                    Listacopy && Listacopy.map(anuncio => {
                                        return (
                                            <div className="modal-img" key={anuncio.key}>
                                                <a href={anuncio.doc} target="_blank" rel="noreferrer">
                                                    <LazyLoadImage
                                                        effect="blur"
                                                        alt={anuncio.key}
                                                        src={anuncio.url} // use normal <img> attributes as props
                                                        key={anuncio.key}
                                                        className="img-modal img-fluid" />
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    : null}
            </AnimatePresence>

        </>
    )
}

export default ModalAd;