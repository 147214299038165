import { useState } from 'react'
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import db from '../Firebase/firebaseConfig';

///Custom hook para traer los productos
const GetProductos = () => {
    const [productos, setProductos] = useState(null);


    ///Funciones asincronas para traer los datos directamente de firebase
    const getDataProducto = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "products"), orderBy("Nombre"))
        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setProductos(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
            })
            ))
        });
    }

    return {
        productos,
        setProductos,
        getDataProducto
    }
}

export default GetProductos
