import React from 'react'
import { ApiService } from '../../../../services/ApiService';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import toast from 'react-hot-toast';
import UploadFile from '../../../../services/UploadFile';
import DeleteFile from '../../../../services/DeleteFile';

const FormAd = ({ Hide, ad_, setAd }) => {
    const api = new ApiService();
    let imagen = ad_?.url;

    const [photoURL, setPhotoURL] = useState(ad_?.url)
    const [fileImagen, setFileImagen] = useState(null);

    ///Evento para cambiar la imagen del producto
    const handleChangeImagen = (e) => {
        const file = e.target.files[0]
        /// verificiaremos si se ha seleccionado una foto para la subida
        if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            setFileImagen(file);
            /// seteamos la imagen para que esta se muestre al momento del cambio
            setPhotoURL(URL.createObjectURL(file));
        } else if (!file) {
            toast.error('Elige una fotografía para continuar.')
        }
        /// validamos si es una foto
        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            toast.error('Formato de archivo no válido')
            return false;
        }
    }

    ///para subir los archivos luego de la actualizacion en la db
    const SubmitChangeUploads = async () => {
        if (ad_?.url === photoURL) {

        } else {
            if (fileImagen) {
                const nombreImagen = fileImagen?.name;
                imagen = await UploadFile(fileImagen, `ads/${nombreImagen}`);

                if (ad_?.url) {
                    ///Borramos la antigua imagen 
                    try {
                        const urlParts = ad_?.url?.split('/');

                        // Obtenemos la última parte de la URL que contiene el nombre del archivo
                        const filenameWithToken = urlParts[urlParts.length - 1];

                        // Usamos la función `split()` nuevamente para separar el nombre del archivo del token usando '?alt=media&token=' como separador
                        const prevImagen = filenameWithToken.split('?alt=media&token=')[0];
                        await DeleteFile(`ads/${prevImagen}`)
                    } catch (error) {

                    }
                }
            }
        }
        ///Agregamos o editamos
        if (ad_?.id) {
            await sendDataEdit();
        } else {
            await sendData();
        }
        Hide();
    }

    const sendData = async () => {
        let adAdd = { id: "", url: imagen }
        api.AddAd(adAdd).then((response) => {
            toast.success(response)
        }).catch((error) => {
            toast.error("Ha ocurrido un error a la hora de agregar la promoción")
            console.log(error);
        })
    }


    const sendDataEdit = async () => {
        let adEdit = { id: ad_?.id, url: imagen }
        api.EditAd(adEdit).then((response) => {
            toast.success(response)
        }).catch((error) => {
            toast.error("Ha ocurrido un error a la hora de editar la promoción")
            console.log(error);
        })
    }



    return (
        <div>
            <div className='container-form-user'>
                <div className='row mt-2 p-3'>
                    <div className="col-md-12 text-center">
                        <p className='fw-bold'>Imágen de la promoción</p>
                        <div>
                            <label htmlFor="ProductoImagen">
                                <input
                                    accept='image/*'
                                    id='ProductoImagen'
                                    type="file"
                                    style={{ display: 'none', cursor: 'pointer' }}
                                    onChange={handleChangeImagen}
                                />
                                <LazyLoadImage
                                    effect="opacity"
                                    alt="Account-picture"
                                    src={photoURL ? photoURL : process.env.PUBLIC_URL + '/vacio.jpg'}
                                    key={ad_?.id} width="300" height="300" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-modal'>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => Hide()} className="p-button-text p-button-danger" />
                <Button label={ad_?.id ? 'Editar' : 'Guardar'} onClick={() => SubmitChangeUploads()} icon="pi pi-check" autoFocus className='p-button-success' />
            </div>
        </div>
    )
}

export default FormAd
