import { IoMenu } from 'react-icons/io5';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './NavBar.css'

import ContactModal from '../ContactModal/ContactModal';
import { useGlobalState } from '../../services/GlobalState';
import { IoHeartOutline } from 'react-icons/io5';
import Form from '../ContactModal/Form';
import { UserAuth } from '../../services/AuthContext'
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
// nuestro navbar
const NavBar = () => {
    const { user, logOut, verifyAccount } = UserAuth();
    /// usaremos el hook usehistory para redirigir
    const history = useHistory();
    /// usaremos el global context
    const [ListaP] = useGlobalState('whishListProducts');
    ///traemos el usuario actual atraves del global state
    const userActual = useGlobalState('userActive');
    // const [ListaProjects, setListaProjects] = useGlobalState('whishListProjects');
    /// Estado para el modal, abrir y cerrar
    const [Estadomodal, setEstadoModal] = useState(false);

    /// Variable para saber donde estoy
    const path = useLocation();
    const menu = useRef(null);
    const menusmall = useRef(null)

    /// items que mostrara el menu
    const items = [
        {
            label: 'Ajustes',
            items: [
                {
                    label: 'Mi cuenta',
                    icon: 'pi pi-user',
                    command: () => {
                        redirectTo("/Account")
                    },
                },
                {
                    label: 'Salir',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        handleSignOut();
                    }
                }
            ]
        }
    ];


    /// traemos toda la informacion directa desde 
    function ActivarModal() {
        setEstadoModal(!Estadomodal)
        document.body.style.overflow = 'hidden';
    }
    /// funcion encargada de redirigir a la location solicitada
    const redirectTo = (location) => {
        history.push(location);
    }

    /// para cerrar sesion
    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    }

    /// funcion para verificar la cuenta
    const verify = async () => {
        try {
            await verifyAccount()
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        console.log(userActual[0])
    }, [])
    return (
        <>
            <nav>
                <div className="logo">
                    <img className='img-fluid me-2 pb-3' width="40px" height="40px" src={process.env.PUBLIC_URL + '/logo.png'}></img>
                    <Link className='text-decoration-none' to="/"><span>
                        Luz & Color</span></Link>
                    {
                        user ?
                            <div>
                                {
                                    user?.photoURL != null ?
                                        <Avatar image={user?.photoURL} className={path.pathname == "/Account" && !Estadomodal ? 'activeAccount avatar-user-small' : 'avatar-user-small'} size="large" shape="circle" onClick={() => redirectTo("/Account")} />
                                        :
                                        <Avatar className={path.pathname == "/Account" && !Estadomodal ? 'activeAccount avatar-user-small' : 'avatar-user-small'} label={user?.displayName?.charAt(0)} size="large" shape="circle" style={{ backgroundColor: '#28a745', color: '#ffffff' }} onClick={() => redirectTo("/Account")} />

                                }
                            </div>
                            :
                            ''
                    }
                </div>
                <div className="section-small-icon"></div>
                <label htmlFor="btn" className='icon'>
                    <span>
                        <IoMenu size="1.2em" />
                    </span>
                </label>
                <input type="checkbox" id='btn'></input>
                <ul>
                    {/* Mostramos la foto donde tendra acceso al usuario en pantallas pequeñas */}
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <li>
                        <label htmlFor="btn">
                            <p className={path.pathname == "/" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => redirectTo("/")}>Inicio</p>
                        </label>
                    </li>
                    <li>
                        <label htmlFor="btn">
                            <p className={path.pathname == "/Categories" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => redirectTo("/Categories")}>Productos</p>
                        </label>
                    </li>
                    <li>
                        <label htmlFor="btn">
                            <p className={path.pathname == "/Projects" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => redirectTo("/Projects")}>Proyectos</p>
                        </label>
                    </li>


                    {/* Lo usaremos para despues... */}
                    {/* <li>
                        <label htmlFor="btn-2" className="show">Proyectos +</label>
                        <a href="#">Proyectos</a>
                        <input type="checkbox" id="btn-2"></input>
                        <ListProjects listaProyectos={listaProyectos}></ListProjects>
                    </li> */}

                    {/* <li>
                        <a href="#">Nosotros</a>
                    </li> */}
                    <li>
                        <label htmlFor="btn">
                            <p className={Estadomodal == true ? 'active LinkMenu' : 'LinkMenu'} onClick={() => { ActivarModal() }}>Contáctanos</p>
                        </label>
                    </li>

                    {/* Haremos una validacion si el usuario es administrador */}
                    {
                        userActual[0]?.roles?.includes(1) ? <>
                            <li>
                                <label htmlFor="btn">
                                    <p className={path.pathname == "/Administracion" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => redirectTo("/Administracion")}>Administración</p>
                                </label>
                            </li>
                        </> : ''
                    }

                    {
                        /// Mostraremos si tenemos productos en nuestra whishlist
                        ListaP?.length >= 1 ?
                            <li>
                                <label htmlFor="btn">
                                    <p className={path.pathname == "/Likeds" && !Estadomodal ? 'active LinkMenu likeditembutton' : 'LinkMenu likeditembutton'} onClick={() => redirectTo("/Likeds")}>
                                        <IoHeartOutline size="1.7rem" />
                                        <span className='likeds-icon'>{ListaP?.length}</span>
                                    </p>
                                </label>
                            </li>
                            : ''
                    }
                    {/* Tendremos el apartado para la cuenta */}
                    {
                        user ?
                            <li>

                                <div className='section-avatar'>
                                    <div className='name-avatar'>
                                        <span>Hola,</span>
                                        <p>{user?.displayName?.substring(0, user?.displayName?.indexOf(' ')) ? user?.displayName?.substring(0, user?.displayName?.indexOf(' ')) : user?.displayName}</p>
                                    </div>
                                    {
                                        user?.photoURL != null ?
                                            <Avatar image={user?.photoURL} className={path.pathname == "/Account" && !Estadomodal ? 'activeAccount avatar-user' : 'avatar-user'} size="large" shape="circle" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                                            :
                                            <Avatar className={path.pathname == "/Account" && !Estadomodal ? 'activeAccount avatar-user' : 'avatar-user'} label={user?.displayName?.charAt(0)} size="large" shape="circle" style={{ backgroundColor: '#28a745', color: '#ffffff' }} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />

                                    }
                                </div>
                            </li>
                            :
                            <li>
                                <label htmlFor="btn">
                                    <p className={path.pathname == "/Login" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => redirectTo("/Login")}>Identificarse</p>
                                </label>
                            </li>
                    }

                    {
                        user ?
                            <li>
                                <label htmlFor="btn" className="sign-out-smal">
                                    <p className={path.pathname == "/Projects" && !Estadomodal ? 'active LinkMenu' : 'LinkMenu'} onClick={() => handleSignOut()}><i className="pi pi-sign-out me-2" />Cerrar sesión</p>
                                </label>
                            </li>
                            : ''
                    }

                </ul>
            </nav>

            {/* Apartado del Modal */}
            <ContactModal
                estado={Estadomodal}
                CambiarEstado={setEstadoModal}
                Footer={true}
                Titulo="Formulario de Contacto"
            >
                <Form CambiarEstado={setEstadoModal} />
            </ContactModal>
        </>
    )
}

export default NavBar;