import { useState } from 'react'
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import db from '../Firebase/firebaseConfig';
const GetModalAds = () => {

    const [ads, setAds] = useState(null);
    ///Funciones asincronas para traer los datos directamente de firebase
    const getDataModalAds = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "modal_ads"), orderBy("Id"))
        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setAds(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    return {
        ads,
        setAds,
        getDataModalAds
    }
}

export default GetModalAds
