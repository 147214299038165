import React, { useEffect, useState } from 'react'
import db from '../../Firebase/firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import { IoFilter } from 'react-icons/io5';
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SkeletonList from '../../skeletons/SkeletonList';
function FiltersmallProject({ Active, setActive, setFiltrado, listaProyectos, SetCategoria, Categoria, currentPage, setCurrentPage }) {
    ///variables
    const [lista, setLista] = useState(null);


    const fetchCategories = async () => {
        const listaCategorias = [];
        const datos = await getDocs(collection(db, 'proyects'));
        datos.forEach((doc) => {
            listaCategorias.push({
                ...doc.data(),
                key: doc.id
            });
        })
        setLista(listaCategorias);
    }

    function setValues(id, nombre) {
        setActive(id)
        SetCategoria(nombre);
        setCurrentPage(1);
    }

    useEffect(() => {
        ///Seteamos las categorias
        fetchCategories();
        document.title = 'Luz & Color Pinturas Protecto® | ' + Categoria;
        if (Active == 'all') {
            setFiltrado(listaProyectos)
            return;
        }
        const filtrado = listaProyectos.filter((proyectos) => proyectos.Categorias.includes(Active))
        setFiltrado(filtrado);
    }, [Active])

    return (
        <div>
            {/* Seccion encargada de un sliders de categorias cuando el disposito se haga pequeño */}
            <div className="cat-md">
                <Swiper slidesPerView={2.5}
                    spaceBetween={30}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                    }}
                    pag
                    modules={[Pagination,Autoplay]} className="Galeria-md">
                    <SwiperSlide>
                        <li onClick={() => setValues('all', 'Todos los proyectos disponibles')} className={Active == 'all' ? 'Active' : ''} >
                            <p>Todos </p>
                        </li>
                    </SwiperSlide>
                    {
                        /// traemos nuestra lista entera de categorias
                        lista && lista.map(categoria => {
                            return (
                                <SwiperSlide key={categoria.key}>
                                    <li onClick={() => setValues(categoria.key, categoria.nombre)} className={Active == categoria.key ? 'Active' : ''} key={categoria.key}>
                                        <p>{categoria.nombre}</p>
                                    </li>
                                </SwiperSlide>

                            )
                        })
                    }

                    {
                        !lista && [1, 2, 3, 4].map((n) => {
                            return (
                                <SwiperSlide key={n}>
                                    <SkeletonList key={n}></SkeletonList>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>



        </div>
    )
}

export default FiltersmallProject