import React from 'react'
import { UserAuth } from '../../services/AuthContext'
import { GoogleButton } from 'react-google-button'
import { IoLogoFacebook } from "react-icons/io5";
import toast from 'react-hot-toast';
import { AnimatePresence, motion } from "framer-motion"

function SocialLogin() {
    const { googleSignIn, facebookSignIn } = UserAuth();
    /// Inicio de Sesion con Google
    const handleGoogleSignInGoogle = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            toast.error('Ha ocurrido un error al tratar de iniciar sesión.', {
                icon: '🚫'
            })
            console.log(error);
        }
    }
    /// Inicio de Sesion con Facebook
    const handleGoogleSignInFacebook = async () => {
        try {
            await facebookSignIn();
        } catch (error) {
            toast.error('Ha ocurrido un error al tratar de iniciar sesión.', {
                icon: '🚫'
            })
            console.log(error);
        }
    }


    return (
        <>
            <motion.div
                initial={{
                    y: 20,
                    opacity: 0
                }}
                animate={
                    {
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.5
                        }
                    }
                }
                exit={{
                    y: 20,
                    opacity: 0,
                    transition: {
                        duration: 0.3,
                    }
                }}
                className='login-others-zone'>
                <GoogleButton label="Continuar con Google" type="light" onClick={handleGoogleSignInGoogle}></GoogleButton>
                <button className="btn btn-facebook mt-3 mb-3" onClick={handleGoogleSignInFacebook}><IoLogoFacebook size="1.5rem" className='me-3'></IoLogoFacebook> Continuar con Facebook</button>
            </motion.div>



        </>
    )
}

export default SocialLogin