import React, { useEffect, useState } from 'react'
import { ApiService } from '../../../../services/ApiService';
import toast from 'react-hot-toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import GetCategorias from '../../../../Actions/getCategorias';
import { Checkbox } from "primereact/checkbox";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoFileTraySharp } from 'react-icons/io5';
import UploadFile from '../../../../services/UploadFile';
import DeleteFile from '../../../../services/DeleteFile';


const FormProducto = ({ producto_, setProducto, Hide }) => {
  const api = new ApiService();
  let imagen = producto_?.Imagen;
  let archivo = producto_?.Ficha;
  ///Apartado para la imagen del producto
  const [photoURL, setPhotoURL] = useState(producto_?.Imagen)
  const [fileImagen, setFileImagen] = useState(null);

  ///Apartado para la ficha del producto
  const [AdjuntoUrl, setAdjuntoUrl] = useState(producto_?.Ficha);
  const [fileFicha, setFileFicha] = useState(null);

  /// traeremos las categorias para saber si el producto ya tiene una o varias
  const { getDataCategorias, categorias } = GetCategorias();
  /// variables a utilizar para hacer toggles y validar el formulario
  const [forme, setForme] = useState({});
  const [errors, setErrors] = useState({});
  /// funcion que seteara los campos del formulario
  const setField = (field, value) => {
    setForme({
      ...forme,
      [field]: value
    })

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }
  /// funcion que validara los campos del formulario
  const validateForm = () => {
    const { Nombre } = forme
    const newErrors = {}
    if (!Nombre || Nombre === '') newErrors.Nombre = 'El nombre no puede ir vacío.'
    else if (Nombre.length > 250) newErrors.Nombre = "El nombre no puede llevar mas de 250 cáracteres."
    return newErrors;
  }


  ///para subir los archivos luego de la actualizacion en la db
  const SubmitChangeUploads = async () => {
    if (producto_?.Id) {
      /// Haremos la validacion que si la imagen y la ficha siguen igual no se realice ninguna subida y nada mas
      if (photoURL === producto_?.Imagen) {

      } else {
        if (fileImagen) {
          const nombreImagen = fileImagen?.name;
          imagen = await UploadFile(fileImagen, `Products/${nombreImagen}`);
          ///Borramos la antigua imagen 
          try {
            await DeleteFile(`Products/${producto_?.Imagen}`)
          } catch (error) {

          }

        }
      }
      if (AdjuntoUrl === producto_?.Ficha) {
      } else {
        if (fileFicha) {
          const nombreFicha = fileFicha?.name;
          archivo = await UploadFile(fileFicha, `Fichas/${nombreFicha}`);
          ///Borramos la ficha antigua
          try {
            await DeleteFile(`Fichas/${producto_?.Ficha}`)
          } catch (error) {

          }

        }
      }
      const { Nombre, Uso, Descripcion } = forme
      let ProductoEdit = {
        Id: producto_?.Id, Nombre: Nombre, Uso: Uso, Descripcion: Descripcion, Categoria: producto_?.Categoria,
        Imagen: imagen, Ficha: archivo
      };
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors)
      } else {
        api.EditProducto(ProductoEdit).then((response) => {
          toast.success(response);
          Hide();
        }).catch((error) => {
          toast.error("Ha ocurrido un error a la hora de editar el producto.")
          console.log(error)
        })
      }
    } else {
      /// Haremos la validacion que si la imagen y la ficha siguen igual no se realice ninguna subida y nada mas
      if (fileImagen) {
        const nombreImagen = fileImagen?.name;
        imagen = await UploadFile(fileImagen, `Products/${nombreImagen}`);
        ///Borramos la antigua imagen 
        try {
          await DeleteFile(`Products/${producto_?.Imagen}`)
        } catch (error) {

        }

      }

      if (fileFicha) {
        const nombreFicha = fileFicha?.name;
        archivo = await UploadFile(fileFicha, `Fichas/${nombreFicha}`);
        ///Borramos la ficha antigua
        try {
          await DeleteFile(`Fichas/${producto_?.Ficha}`)
        } catch (error) {

        }

      }
      const { Nombre, Uso, Descripcion } = forme
      let ProductoAdd = {
        Id: "", Nombre: Nombre, Uso: Uso, Descripcion: Descripcion, Categoria: producto_?.Categoria,
        Imagen: imagen, Ficha: archivo
      };
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors)
      } else {
        api.AddProducto(ProductoAdd).then((response) => {
          toast.success(response);
          Hide();
        }).catch((error) => {
          toast.error("Ha ocurrido un error a la hora de ingresar el producto.")
          console.log(error)
        })
      }

    }
  }

  ///funcion que detectara los cambios en el arreglo de las categorias del produto
  const onCategoriaChange = (e) => {
    let _CategoriasProducto = producto_?.Categoria ? [...producto_?.Categoria] : [];
    console.log(_CategoriasProducto)

    if (e.checked) {
      _CategoriasProducto.push(e.value);
    }
    else {
      _CategoriasProducto = _CategoriasProducto.filter(category => category !== e.value);
      console.log(_CategoriasProducto)
    }
    setProducto({ ...producto_, Categoria: [..._CategoriasProducto] })
  };

  ///Evento para cambiar la imagen del producto
  const handleChangeImagen = (e) => {
    const file = e.target.files[0]
    /// verificiaremos si se ha seleccionado una foto para la subida
    if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      setFileImagen(file);
      /// seteamos la imagen para que esta se muestre al momento del cambio
      setPhotoURL(URL.createObjectURL(file));
    } else if (!file) {
      toast.error('Elige una fotografía para continuar.')
    }
    /// validamos si es una foto
    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      toast.error('Formato de archivo no válido')
      return false;
    }
  }
  /// para manipular la ficha tecnica
  const handleChangeFicha = (e) => {
    const file = e.target.files[0]
    /// verificiaremos si se ha seleccionado una foto para la subida
    if (file && file.name.match(/\.(pdf)$/)) {
      setFileFicha(file);
      /// seteamos la imagen para que esta se muestre al momento del cambio
      setAdjuntoUrl(URL.createObjectURL(file));
    } else if (!file) {
      toast.error('Elige un pdf para continuar.')
    }
    /// validamos si es una foto
    if (!file.name.match(/\.(pdf)$/)) {
      toast.error('Formato de archivo no válido')
      return false;
    }
  }


  useEffect(() => {
    getDataCategorias();
    /// en caso de traerlo sabdremos que viene en modo edicion
    if (producto_) {
      setField('Nombre', producto_.Nombre);
      forme.Nombre = producto_?.Nombre;
      setField('Uso', producto_.Uso);
      forme.Uso = producto_?.Uso;
      setField('Descripcion', producto_.Descripcion);
      forme.Descripcion = producto_?.Descripcion;
      console.log(producto_)
    }
  }, [])

  return (
    <>
      <div className='container-form-user container-xxl'>
        <div className="field w-100">
          <span className="p-float-label p-input-icon-right w-100">
            <InputText className={!errors.Nombre ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Nombre} onChange={e => setField('Nombre', e.target.value)} required />
            <label htmlFor="description">Nombre del producto</label>
          </span>
          <span className="invalid">{errors.Nombre}</span>
        </div>
        <div className='field w-100'>
          <span className="p-float-label">
            <InputTextarea className={!errors.Descripcion ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Descripcion} onChange={e => setField('Descripcion', e.target.value)} required rows={6} cols={30} />
            <label htmlFor="username">Descripcion del producto</label>
          </span>
        </div>
        <div className='field w-100'>
          <span className="p-float-label">
            <InputTextarea className={!errors.Uso ? 'login__input w-100' : 'error-borde-login login__input'} id="nombre" value={forme.Uso} onChange={e => setField('Uso', e.target.value)} required rows={6} cols={30} />
            <label htmlFor="username">Uso del producto</label>
          </span>
        </div>
        <p className='fw-bold'>Categorías de productos</p>
        <div className='row gap-3 justify-content-center'>
          {categorias?.map((category) => {
            return (
              <div key={category.id} className="col-md-4">
                <div className="d-flex align-items-center">
                  <Checkbox
                    inputId={category.id}
                    name="category"
                    value={category.id}
                    onChange={onCategoriaChange}
                    checked={producto_?.Categoria?.some((item) => item === category.id)}
                  />
                  <label htmlFor={category.id} className="ms-2">{category.nombre}</label>
                </div>
              </div>
            );
          })}
        </div>
        {/* Apartados multimedia del producto */}
        <div className='row mt-2 p-3'>
          <div className="col-md-6 text-center">
            <p className='fw-bold'>Imágen del producto</p>
            <div>
              <label htmlFor="ProductoImagen">
                <input
                  accept='image/*'
                  id='ProductoImagen'
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={handleChangeImagen}
                />
                <LazyLoadImage
                  effect="opacity"
                  alt="Account-picture"
                  src={photoURL ? photoURL : process.env.PUBLIC_URL + '/vacio.jpg'}
                  key={producto_?.id} width="300" height="300" />
              </label>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <p className='fw-bold texto-ficha'>Ficha ténica del producto</p>
            <label htmlFor='ProductoFicha'>
              <input
                accept="application/pdf"
                id='ProductoFicha'
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={handleChangeFicha}
              />
              <p className='text-success fw-bolder'><IoFileTraySharp className='me-2' /> Ficha técnica</p>
            </label>
            {
              AdjuntoUrl ? <div>
                <iframe src={AdjuntoUrl} style={{ width: '400px', height: "300px" }} frameborder="0"></iframe>
              </div> : ''
            }
          </div>
        </div>


      </div>
      <div className='footer-modal'>
        <Button label="Cancelar" icon="pi pi-times" onClick={() => Hide()} className="p-button-text p-button-danger" />
        <Button label={producto_?.Id ? 'Editar' : 'Guardar'} onClick={() => SubmitChangeUploads()} icon="pi pi-check" autoFocus className='p-button-success' />
      </div>
    </>
  )
}

export default FormProducto
