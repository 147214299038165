import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { IoArrowForwardCircle, IoCheckmarkCircle, IoEye, IoEyeOff, IoLockClosed, IoMailSharp } from 'react-icons/io5';
import { auth } from '../../../Firebase/firebaseConfig';
import LinkPassword from '../../../services/LinkPassword';

const FormLinkPassword = ({ email, CambiarEstado, provider, setuser, user, setProveedoresActive }) => {
    const [forme, setForme] = useState({});
    const [usuario, setusuario] = useState(null)
    const [errors, setErrors] = useState({});
    const [showpass, setShowPass] = useState(false);
    const [showpassConfirm, setShowPassConfirm] = useState(false);

    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    /// Funcion que valida los campos
    const validateForm = () => {
        const { email, password, confirmpassword } = forme
        const newErrors = {}
        if (!email || email === '') newErrors.email = 'El email no puede ir vacío.'
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) newErrors.email = 'Formato de email incorrecto.'
        if (!password || password === '') newErrors.password = 'La contraseña no puede ir vacía.'
        else if (password.length < 6) newErrors.password = "La contraseña debe tener al menos 6 carácteres."
        if (!confirmpassword || confirmpassword === '') newErrors.confirmpassword = 'La confirmacion de la contraseña no puede ir vacía'
        else if (confirmpassword != password) newErrors.confirmpassword = "Las contraseñas no coinciden."
        return newErrors;
    }

    /// hacer un toggle de las contraseñas
    const tooglepass = () => {
        setShowPass(!showpass);
    }
    const tooglepassConfirm = () => {
        setShowPassConfirm(!showpassConfirm);
    }

    /// enlazar las cuentas con correo y contraseña para el inicio de sesion
    const LinkAccount = async () => {
        const { email, password } = forme;
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            let proveedor;
            if (provider == 1) {
                proveedor = new GoogleAuthProvider();
            } else if (provider == 2) {
                proveedor = new FacebookAuthProvider();
            }
            signInWithPopup(auth, proveedor).then((result) => {
                try {
                    LinkPassword(email, password).then((user_) => {
                        setuser(user_);
                        CerrarModal();
                    })
                } catch (error) {
                    toast.error("Ha ocurrido un error en la operación.")
                    console.log(error);
                }
            }).catch((error) => {
                toast.error("Ha ocurrido un error al tratar de verificar el inicio de sesión.")
            })
            setProveedoresActive();
        }
    }

    /// nos mostrara un mensaje de confirmacion
    const ShowconfirmLink = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estás seguro de enlazar las cuentas?',
            acceptLabel: "Si, estoy seguro",
            acceptIcon: 'pi pi-check',
            rejectLabel: 'No, cancelar',
            icon: 'pi pi-exclamation-triangle',
            accept: () => LinkAccount(),
            reject: () => CerrarModal(),
        });
    }

    function CerrarModal() {
        CambiarEstado(false)
        document.body.style.overflow = 'unset';
        setuser(usuario);
    }

    useEffect(() => {
        /// seteamos los datos para que ya vayan llenos con la informacion del usuario
        setField('email', email)
        forme.email = email;
    }, [])


    return (
        <div className='login container-form-link'>
            {/* Un popup que saldra para la operacion y confirmar que si */}
            <ConfirmPopup />
            <div className="header-form-login">
                <h1>Enlazar cuenta con contraseña</h1>
            </div>
            <div className="form-login">
                <div className="login-formulario">
                    <div className="login__field">
                        <IoMailSharp className="login__icon "></IoMailSharp>
                        <input
                            value={forme.email}
                            readOnly
                            onChange={e => setField('email', e.target.value)}
                            className={!errors.email ? 'login__input login__input' : 'error-borde-login login__input'}
                            type="email" placeholder="Email" />
                        <div className="invalid">{errors.email}</div>
                    </div>
                    <div className="login__field">
                        <IoLockClosed className="login__icon fas fa-lock"></IoLockClosed>
                        <input type={!showpass ? 'password' : 'text'}
                            value={forme.pasword}
                            onChange={e => setField('password', e.target.value)}
                            className={!errors.nombre ? 'login__input login__input' : 'error-borde-login login__input'}
                            placeholder="Contraseña" />
                        {
                            !showpass ?
                                <IoEyeOff onClick={() => tooglepass()} size="1.2rem" className="login__icon-password" /> :
                                <IoEye onClick={() => tooglepass()} size="1.2rem" className="login__icon-password" />
                        }

                        <div className="invalid">{errors.password}</div>
                    </div>
                    <div className="login__field">
                        <IoCheckmarkCircle className="login__icon fas fa-lock"></IoCheckmarkCircle>
                        <input type={!showpassConfirm ? 'password' : 'text'}
                            className={!errors.confirmpassword ? 'login__input login__input' : 'error-borde-login login__input'}
                            value={forme.confirmpassword}
                            onChange={e => setField('confirmpassword', e.target.value)}
                            placeholder="Confirmar contraseña" />
                        {
                            !showpassConfirm ?
                                <IoEyeOff onClick={() => tooglepassConfirm()} size="1.2rem" className="login__icon-password" /> :
                                <IoEye onClick={() => tooglepassConfirm()} size="1.2rem" className="login__icon-password" />
                        }
                        <div className="invalid">{errors.confirmpassword}</div>
                    </div>
                    <button id='btn-link' className="button login__submit" onClick={ShowconfirmLink} >
                        <span className="button__text">Enlazar cuenta</span><IoArrowForwardCircle size="1.4rem"></IoArrowForwardCircle>
                    </button>
                </div>
            </div>

        </div>
    )
}

export default FormLinkPassword