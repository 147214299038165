import React, { useState } from 'react'
import { IoCaretDown } from "react-icons/io5";
import './DataforRegister.css'



function DataforRegister({ setPostPerPage, postsPerPage, arrayfiltered, setCurrentPage }) {
    const [mostrar, setMostrar] = useState(false);

    const Showmenu = () => {
        setMostrar(!mostrar);
    }
    const detectChange = (number) => {
        setPostPerPage(number);
        setMostrar(!mostrar);
        setCurrentPage(1)
    }
    /// en este arreglo guardaremos los elementos que nos envien para poder parametrizar el filtrado
    const filtered = [...arrayfiltered]

    return (
        <div className="select-menu" >
            <div className="select-btn" onClick={() => Showmenu()}>
                <span className="sBtn-text">
                    Filtrar los registros ({postsPerPage})
                </span>
                <IoCaretDown size="1rem" className='ms-2' onClick={() => Showmenu()} />
            </div>
            {
                mostrar == false ? '' :
                    <ul className="options">
                        {
                            filtered.map(filtrado => {
                                return (
                                    <div className={postsPerPage == filtrado ? ' option optionActive' : ' option-text option'}>
                                        <span className="option-text" onClick={() => detectChange(filtrado)}>
                                            Ver {filtrado} registros
                                        </span>
                                    </div>
                                )
                            })
                        }

                    </ul>
            }

        </div>

    )
}

export default DataforRegister