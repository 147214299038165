import React, { useEffect, useRef, useState } from 'react';
import './ContactModal.css'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import toast from 'react-hot-toast';
import { UserAuth } from '../../services/AuthContext';
function Form(props) {
    const { user } = UserAuth();
    const form = useRef();
    const [forme, setForme] = useState({});
    const [errors, setErrors] = useState({});
    const captcha = useRef(null);

    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    /// Funcion que valida los campos
    const validateForm = () => {
        const { name, email, mensaje, validacion } = forme
        const newErrors = {}
        if (!validacion || validacion === '') newErrors.validacion = 'Verifique que no es un robot para continuar'
        if (!name || name === '') newErrors.name = 'El nombre no puede ir vacío.'
        if (!email || email === '') newErrors.email = 'El email no puede ir vacío.'
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) newErrors.email = 'Formato de email incorrecto.'
        if (!mensaje || mensaje === '') newErrors.mensaje = 'El mensaje no puede ir vacío.'
        else if (mensaje.length > 150) newErrors.mensaje = 'El mensaje es demasiado largo, se admiten 150 carácteres.'
        else if (mensaje.length < 10) newErrors.mensaje = 'El mensaje es demasiado corto.'
        return newErrors;
    }

    /// enviamos el email
    const sendEmail = (e) => {
        /// validamos si el formulario no viene vacio
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            /// en un toast enviamos la promesa respectiva para indicar que el correo se envio.
            toast.promise(
                emailjs.sendForm('service_6yn28xa', 'template_k2b27nr', form.current, 'PlkQzd-uqSHrDeYL7'),
                {
                    loading: 'Enviando correo...',
                    success: <b>Correo enviado exitosamente!</b>,
                    error: <b>El correo no se ha podido enviar.</b>,
                }

            );
            /// Reseteamos el formulario y cerramos el modal  
            setForme({});
            /// Cerramos el modal
            CerrarModal();
        }

    };
    function CerrarModal() {
        props.CambiarEstado(false)
        document.body.style.overflow = 'unset';
    }

    function onChange() {
        /// seteamos el campo escondido con el captcha para el envio por correo 
        setField('validacion', captcha.current.getValue());
    }

    useEffect(() => {
        if (user) {
            /// seteamos los datos para que ya vayan llenos con la informacion del usuario
            setField('name', user?.displayName)
            forme.name = user?.displayName;
            setField('email', user?.email)
            forme.email = user?.email;
        } else {

        }
    }, [])
    return (
        <>
            <h1>Rellena tu información en el siguiente formulario para comunicarte con nosotros.</h1>

            <form className='Contenido' ref={form} onSubmit={sendEmail} noValidate>
                <label className='mt-2'>Nombre Completo:</label>
                <input
                    value={forme.name}
                    onChange={e => setField('name', e.target.value)}
                    placeholder='Juan perez '
                    required
                    type="text"
                    className={!errors.name ? 'form-control' : 'error-borde form-control'}
                    name="from_name" />
                <div className="invalid">{errors.name}</div>
                <label>Email - Correo Electrónico:</label>
                <input
                    value={forme.email}
                    onChange={e => setField('email', e.target.value)}
                    placeholder='test@prueba.com'
                    required
                    className={!errors.email ? 'form-control' : 'error-borde form-control'}
                    type="email"
                    name="user_email" />
                <div className="invalid">{errors.email}</div>
                <label>Tu mensaje:</label>
                <textarea
                    value={forme.mensaje}
                    onChange={e => setField('mensaje', e.target.value)}
                    placeholder='Mensaje de prueba'
                    required
                    rows="4"
                    className={!errors.mensaje ? 'form-control' : 'error-borde form-control'}
                    name="message" />
                <div className="invalid">{errors.mensaje}</div>
                <input type="hidden" name='to_name' value="Luz & Color Protecto"></input>
                <p>*Esta información servirá para contactarnos contigo y ayudarte mejor en tu búsqueda del color perfecto o
                    algún proyecto, para comunicarte todos los campos son reglamentarios y dar como resultado que no eres un robot o un software de terceros*
                    <div className="invalid">{errors.validacion}</div>
                </p>
                <input type="hidden" name='g-recaptcha-response' value={forme.validacion}></input>
                <div className='enviar-btn'>
                    <input type="submit" onClick={sendEmail} className='btn btn-success mt-3' value="Enviar" />
                </div>

            </form>
            <ReCAPTCHA
                name="g-recaptcha-response"
                className='Captcha'
                sitekey="6Lej914gAAAAAPFi2HTpcG0inM5WcJbGg0OruV1l"
                onChange={onChange}
                size='normal'
                ref={captcha}
                onExpired={onChange}
            />
        </>
    )
}

export default Form