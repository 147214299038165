import React, { useState } from 'react'
import GetAds from '../../../Actions/getAds'
import SkeletonModalsAds from './components/SkeletonModalsAds';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { ApiService } from '../../../services/ApiService';
import { Dialog } from 'primereact/dialog';
import FormAd from './components/FormAd';
import toast from 'react-hot-toast';

const Ads = () => {

  const { ads, setAds, getDataAds } = GetAds();
  const api = new ApiService();
  /// variables que usamos en todo el ciclo
  const [ad_, setAd] = useState(null);
  const [adDeleteDialog, DeleteAdDialog] = useState(false);
  const [displayModalAd, setDisplayModalAd] = useState(false);


  /// mostrar el modal de edicion
  const showModalEdit = (rowData) => {
    setDisplayModalAd(true);
    setAd(rowData)
  }
  /// mostrar la alerta de borrado
  const Confirmacion = (rowData) => {
    DeleteAdDialog(true);
    setAd(rowData);
  }
  const hideDeleteAdDialog = () => {
    DeleteAdDialog(false);
    setAd(null);
  }
  /// footer del dialogo de confirmacion
  const deleteadDialogFooter = (
    <React.Fragment>
      <Button label="No, Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdDialog} />
      <Button label="Si, estoy seguro" icon="pi pi-check" className="p-button-text" onClick={() => DeleteAd()} />
    </React.Fragment>
  );
  /// Apartado para mostrar la imagen en la tabla
  const imageBodyTemplate = (rowData) => {
    return (
      <LazyLoadImage
        effect="opacity"
        alt={rowData.url}
        src={rowData.url}// use normal <img> attributes as props
        key={rowData.url}
        className="shadow-2 border-round" style={{ width: '164px' }} />
    );
  };

  /// Acciones para interactuar con la fila
  const AccionesModalAds = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning me-2" onClick={() => showModalEdit(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => Confirmacion(rowData)} />
      </React.Fragment>
    );
  }

  /// esconder el modal y quitar el objeto seleccionado
  const Hide = () => {
    setAd(null);
    setDisplayModalAd(false)
  }

  ///funcion para hacer un delete a la promición
  const DeleteAd = async () => {
    try {
      api.DeleteAd(ad_).then((response) => {
        toast.success(response);
        hideDeleteAdDialog();
      })
    } catch (error) {
      toast.error("Ha ocurrido un error a la hora de eliminar la promoción")
    }
  }

  useEffect(() => {
    getDataAds();
  }, [])


  return (
    <div className='mt-5'>
      <div className='mt-2 mb-3'>
        <div className='d-flex justify-content-end flex-column'>
          <h3 className='fw-bolder'>Administración de Promociones</h3>
          <p className='select-text'>Agrega, modifica o elimina promociones</p>
        </div>
        <div className="d-flex justify-content-end">
          <Button icon="pi pi-plus" label="Agregar promoción" className="p-button-success" onClick={() => setDisplayModalAd(true)} />
        </div>
      </div>
      {/* Donde va la datatable y los datos */}
      <div className='w-100'>
        <div className="">
          {
            !ads ? <SkeletonModalsAds></SkeletonModalsAds> :
              <DataTable emptyMessage="Sin registros para mostrar" value={ads} stripedRows
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} promociones">
                <Column field="url" header="Imagen" body={imageBodyTemplate}></Column>
                <Column header="Acciones" exportable={false} body={AccionesModalAds} style={{ minWidth: '2rem' }}></Column>
              </DataTable>
          }

        </div>
      </div>
      {/* Apartado para modales y ventanas desplegables */}
      {/* Modal que servira para el mantenimiento de los Categorias*/}
      <Dialog header={ad_ ? "Edición de promoción" : "Mantenimiento de promoción"} visible={displayModalAd} draggable={false} className="modal-size" onHide={() => Hide()}>
        <FormAd Hide={Hide} ad_={ad_} setAd={setAd} />
      </Dialog>

      <Dialog visible={adDeleteDialog} style={{ width: '450px' }} header="Mensaje de Confirmación" modal footer={deleteadDialogFooter} onHide={hideDeleteAdDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle me-3" style={{ fontSize: '1rem' }} />
          {ad_ && <span>Estás seguro de eliminar la promoción?</span>}
        </div>
      </Dialog>
    </div>
  )
}

export default Ads
