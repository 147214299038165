import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Product.css'
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
/// Traemos la libreria de swipper para los sliders
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SkeletonCard from '../../skeletons/SkeletonCard';
import SkeletonProduct from '../../skeletons/SkeletonProduct';
import Recomend from "./Recomend";
import ReactTooltip from 'react-tooltip';
import { IoHeart } from 'react-icons/io5';
import { IoHeartOutline, IoDocumentAttach } from 'react-icons/io5';
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../services/GlobalState";
import BotonScrollUp from "../../components/BotonScrollUp/BotonScrollUp";
/// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import DeleteWishList from "../../services/DeleteWishList";
import AddWishList from "../../services/AddWishList";
import { UserAuth } from "../../services/AuthContext";
import toast from "react-hot-toast";
/// Pagina que mostrara los detalles de un producto
const Product = () => {
    const { user } = UserAuth();
    const [usuario, setUsuario] = useGlobalState('userActive');
    const [ListaP, setListaP] = useGlobalState('whishListProducts');
    const { id } = useParams();
    /// variable usadada para contener el producto
    var p = {};
    const [Producto, SetProducto] = useState(null);
    const [Pcopy, setPcopy] = useState(null);
    const [ListaProductos, setListaProductos] = useState(null);
    const [Favorito, setFavorito] = useState(false);
    const history = useHistory();
    /// funcion para setear el producto
    function setp(object) {
        p = object;
    }

    /// En caso traiga un id de parametro se ejecutara esta funcion
    const getDataProductoParam = async () => {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setp({
                ...docSnap.data(),
                key: id
            })
            /// seteamos el valor de copia para jugar con el y sus propiedades
            SetProducto(p);
            setPcopy(p);
        } else {
            setPcopy(null);
        }
    }
    /// funcion para eliminar los elementos
    const removeElement = async (nombre) => {
        if (user) {
            /// seteamos la lista 
            setListaP(await DeleteWishList(nombre, usuario, Producto));
            setUsuario(JSON.parse(localStorage.getItem('user')))
        } else {
            toast.error('Inicia sesión o regístrate para continuar')
            history.push('/Login')
        }

    }

    /// funcion para agregar a la wishlist
    const addElemento = async (Producto) => {
        if (user) {
            setListaP(await AddWishList(Producto, ListaP, usuario))
            setUsuario(JSON.parse(localStorage.getItem('user')))
        } else {
            toast.error('Inicia sesión o regístrate para continuar')
            history.push('/Login')
        }

    }


    /// seteamos los productos favcoritos para mostrarlos al usuario
    function Favoritoset(favorito, producto) {
        /// si quitamos el favorito eliminamos de la wishlist
        if (favorito == false) {
            removeElement(producto.Nombre)
        } else {
            /// agregamos el elemento
            addElemento(producto);
        }
        setFavorito(favorito);
    }



    useEffect(() => {
        /// traemos los datos de firebase
        getDataProductoParam();
    }, [])
    return (
        <>
            {
                Pcopy != null ?
                    <div className='contenedor-producto'>
                        <BotonScrollUp />
                        <div className='header-producto'>
                            <div className='tool-header'>
                                <a onClick={history.goBack} data-tip data-for="botonRegresar" className='btn-back'><IoArrowBackCircle className="me-1" size="22px"></IoArrowBackCircle>Regresar
                                    <ReactTooltip id="botonRegresar"
                                        type='light'
                                        place='right'
                                        effect='solid'
                                        className='tooltip-regresar'>
                                        Regresar hacia atrás
                                    </ReactTooltip>
                                </a>
                                {
                                    Favorito ?
                                        <div>
                                            <a className="herramientas" onClick={() => Favoritoset(false, Pcopy)}>
                                                <IoHeart data-tip data-for="desmarcar" size="22px"></IoHeart >
                                            </a>

                                        </div>
                                        : <div>
                                            <a className="herramientas" onClick={() => Favoritoset(true, Pcopy)}>
                                                <IoHeartOutline data-tip data-for="marcar" size="22px"></IoHeartOutline >
                                            </a>

                                        </div>
                                }
                            </div>
                            <ReactTooltip id={Favorito ? 'desmarcar' : 'marcar'}
                                type='light'
                                place='left'
                                effect='solid'
                                className='tooltip-regresar'
                            >
                                {Favorito ? 'No me gusta' : 'Me gusta'}
                            </ReactTooltip>
                        </div>
                        <div className='producto-all container-xxl'>
                            <div className='producto'>
                                <div className="img">
                                    <LazyLoadImage
                                        effect="blur"
                                        alt={Pcopy?.Imagen}
                                        src={Pcopy?.Imagen}// use normal <img> attributes as props
                                        key={Pcopy?.key} />
                                </div>
                                <h1>{Pcopy?.Nombre}</h1>
                            </div>
                            <div className='producto-2'>
                                <h1>Descripción</h1>
                                <p>{Pcopy?.Descripcion}</p>
                                <h1>Uso</h1>
                                <p>{Pcopy?.Uso}</p>

                            </div>
                        </div>
                        <div className='things-product'>
                            <p>Datos técnicos y fichas de seguridad</p>
                            <div className='Fichas container-xxl'>
                                <a target="_blank" rel="noreferrer" href={Pcopy?.Ficha}> <IoDocumentAttach size="22px" className="me-1" color="#ddd"></IoDocumentAttach> Ficha Técnica</a>
                            </div>
                        </div>
                        {/* Elemento que servira para los resultados recomendados segun el producto que el usuario vea */}
                        <Recomend Pcopy={Pcopy} setPcopy={setPcopy} ListaP={ListaP} setFavorito={setFavorito} />

                    </div>
                    /// En caso no encuentre el producto
                    :
                    <div className='contenedor-producto'>
                        <div className="header-producto">
                            <div className='tool-header'>
                                <Link to="/Categories/all" className='btn-back'><IoArrowBackCircle className="me-1" size="22px"></IoArrowBackCircle>Regresar</Link>
                            </div>
                        </div>
                        <div className='producto-all'>
                            <SkeletonProduct></SkeletonProduct>
                        </div>

                        <div className='rel-products'>

                            <Swiper
                                slidesPerView={4}
                                spaceBetween={20}
                                modules={[Pagination]} className="related-products"
                            >
                                {
                                    !ListaProductos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                        return (
                                            <SwiperSlide key={n}>
                                                <SkeletonCard key={n}></SkeletonCard>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                modules={[Pagination]} className="related-products-md"
                            >
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                        return (
                                            <SwiperSlide key={n}>
                                                <SkeletonCard key={n}></SkeletonCard>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>

            }
        </>
    )
}

export default Product;