import React from 'react'
import { useHistory } from 'react-router-dom';
import { UserAuth } from '../../services/AuthContext';

/// este componente bloqueara el acceso a apartados que el usuario no este logueado
const ProtectedUser = ({ children }) => {
    const { user } = UserAuth();
    const history = useHistory();
    if (!user || user === null) {
        return history.push('/');
    }
    return children;
}

export default ProtectedUser