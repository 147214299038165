import React from 'react'
import './Pagination.css'
const PaginationOwn = ({ postsPerPage, totalPosts, paginate, currentPage, setCurrentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    /// funcion que nos servira para paginar e ir a la parte superior
    const paginar = (numero) => {
        paginate(numero);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <> <div className='pagination-container '>
            <ul className="pagination list-pagination">
                {
                    pageNumbers.map(number => (
                        <li key={number} className="page-item item-pagination">
                            <a onClick={() => paginar(number)} className={number == currentPage ? 'Active-Pag page-link linkpage-item' : 'page-link linkpage-item '}>
                                {number}
                            </a>
                        </li>
                    ))
                }
            </ul>
            <br />
        </div>
            <p className='pagina-actual mb-3'>Página {currentPage} de {pageNumbers.length}</p>
        </>


    )
}

export default PaginationOwn;
