import GetCategorias from '../../../Actions/getCategorias';
import SkeletonCategorias from './components/SkeletonCategorias';
import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { ApiService } from '../../../services/ApiService';
import FormCategoria from './components/FormCategoria';
import { IoSearchOutline } from 'react-icons/io5';


const CategoriasProductos = () => {
    /// Traeremos los valores directamente del custom hook creado para traer las categorias
    const { categorias, setCategorias, getDataCategorias } = GetCategorias();
    const api = new ApiService();
    /// variables que usamos en todo el ciclo
    const [categoria_, setCategoria] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [categoriaDeleteDialog, DeleteCategoriaDialog] = useState(false);
    const [displayModalCategoria, setDisplayModalCategoria] = useState(false);


    /// mostrar el modal de edicion
    const showModalEdit = (rowData) => {
        setDisplayModalCategoria(true);
        setCategoria(rowData)
    }
    /// mostrar la alerta de borrado
    const Confirmacion = (rowData) => {
        DeleteCategoriaDialog(true);
        setCategoria(rowData);
    }

    const hideDeleteCategoriaDialog = () => {
        DeleteCategoriaDialog(false);
        setCategoria(null);
    }
    /// footer del dialogo de confirmacion
    const deletecategoriaDialogFooter = (
        <React.Fragment>
            <Button label="No, Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCategoriaDialog} />
            <Button label="Si, estoy seguro" icon="pi pi-check" className="p-button-text" onClick={() => DeleteCategoria()} />
        </React.Fragment>
    );
    /// esconder el modal y el objeto seleccionado
    const Hide = () => {
        setCategoria(null);
        setDisplayModalCategoria(false)
    }
    //#region 
    ///Items y encrustacion de codigo que servira para la funcionalidad
    /// la cabecera de la tabla
    const header = (
        <div className="table-header">
          <div className="login__field">
                <IoSearchOutline className="login__icon "></IoSearchOutline>
                <input
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    className='login__input'
                    type="email" placeholder="Buscar..." />
            </div>
        </div>
    );
    /// Acciones para interactuar con la fila
    const AccionesCategoria = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning me-2" onClick={() => showModalEdit(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => Confirmacion(rowData)} />
            </React.Fragment>
        );
    }

    ///funcion para hacer un delete a la categoria
    const DeleteCategoria = async () => {
        try {
            api.DeleteCategoria(categoria_).then((response) => {
                toast.success(response);
                hideDeleteCategoriaDialog();
            })
        } catch (error) {
            toast.error("Ha ocurrido un error a la hora de eliminar la categoría.")
        }
    }


    useEffect(() => {
        getDataCategorias();
    }, [])

    return (
        <div>
            <div className='mt-2 mb-3'>
                <div className='d-flex justify-content-end flex-column'>
                    <h3 className='fw-bolder'>Administración de Categorías</h3>
                    <p className='select-text'>Agrega, modifica o elimina a las categorías de los productos</p>
                </div>
                <div className="d-flex justify-content-end">
                    <Button icon="pi pi-plus" label="Agregar categoría" className="p-button-success" onClick={() => setDisplayModalCategoria(true)} />
                </div>
            </div>

            {/* Donde va la datatable y los datos */}
            <div className='w-100'>
                <div className="">
                    {
                        !categorias ? <SkeletonCategorias></SkeletonCategorias> :
                            <DataTable emptyMessage="Sin registros para mostrar" value={categorias} stripedRows
                                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} categorías" header={header} globalFilter={globalFilter}>
                                <Column field="nombre" sortable header="Nombre"></Column>
                                <Column header="Acciones" body={AccionesCategoria} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            </DataTable>
                    }

                </div>
            </div>
            {/* Apartado para modales y ventanas desplegables */}
            {/* Modal que servira para el mantenimiento de los Categorias*/}
            <Dialog header={categoria_ ? "Edición de categoría" : "Mantenimiento de categoría"} visible={displayModalCategoria} draggable={false} className="modal-size" onHide={() => Hide()}>
                <FormCategoria Hide={Hide} categoria_={categoria_} setCategoria={setCategoria} />
            </Dialog>

            <Dialog visible={categoriaDeleteDialog} style={{ width: '450px' }} header="Mensaje de Confirmación" modal footer={deletecategoriaDialogFooter} onHide={hideDeleteCategoriaDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle me-3" style={{ fontSize: '1rem' }} />
                    {categoria_ && <span>Estás seguro de eliminar la categoría <b>{categoria_.nombre}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}

export default CategoriasProductos
