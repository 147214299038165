import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import GetProductos from '../../../Actions/getProductos'
import { ApiService } from '../../../services/ApiService';
import FormProducto from './components/FormProducto';
import SkeletonProductos from './components/SkeletonProductos';
import { IoSearchOutline } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Productos = () => {
  const { productos, setProductos, getDataProducto } = GetProductos();
  const api = new ApiService();
  /// variables que usamos en todo el ciclo
  const [producto_, setProducto] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [productoDeleteDialog, DeleteProductoDialog] = useState(false);
  const [displayModalProducto, setDisplayModalProducto] = useState(false);


  /// mostrar el modal de edicion
  const showModalEdit = (rowData) => {
    setDisplayModalProducto(true);
    setProducto(rowData)
  }
  /// mostrar la alerta de borrado
  const Confirmacion = (rowData) => {
    DeleteProductoDialog(true);
    setProducto(rowData);
  }

  const hideDeleteProductoDialog = () => {
    DeleteProductoDialog(false);
    setProducto(null);
  }
  /// footer del dialogo de confirmacion
  const deleteproductoDialogFooter = (
    <React.Fragment>
      <Button label="No, Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductoDialog} />
      <Button label="Si, estoy seguro" icon="pi pi-check" className="p-button-text" onClick={() => DeleteProducto()} />
    </React.Fragment>
  );
  /// esconder el modal y quitar el pais seleccionado
  const Hide = () => {
    setProducto(null);
    setDisplayModalProducto(false)
  }
  //#region 
  ///Items y encrustacion de codigo que servira para la funcionalidad
  /// la cabecera de la tabla
  const header = (
    <div className="table-header">
      <div className="login__field">
        <IoSearchOutline className="login__icon "></IoSearchOutline>
        <input
          onInput={(e) => setGlobalFilter(e.target.value)}
          className='login__input'
          type="email" placeholder="Buscar..." />
      </div>
    </div>
  );

  /// Apartado para mostrar la imagen en la tabla
  const imageBodyTemplate = (rowData) => {
    return (
      <LazyLoadImage
        effect="opacity"
        alt={rowData.Imagen}
        src={rowData.Imagen}// use normal <img> attributes as props
        key={rowData.Imagen}
        className="shadow-2 border-round" style={{ width: '64px' }} />
    );
  };

  /// Acciones para interactuar con la fila
  const AccionesProducto = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning me-2" onClick={() => showModalEdit(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => Confirmacion(rowData)} />
      </React.Fragment>
    );
  }

  ///funcion para hacer un delete a la categoria
  const DeleteProducto = async () => {
    console.log(producto_)
    try {
      api.DeleteProducto(producto_).then((response) => {
        toast.success(response);
        hideDeleteProductoDialog();
      })
    } catch (error) {
      toast.error("Ha ocurrido un error a la hora de eliminar el producto.")
    }
  }


  useEffect(() => {
    getDataProducto();
  }, [])




  return (
    <div>
      <div className='mt-2 mb-3'>
        <div className='d-flex justify-content-end flex-column'>
          <h3 className='fw-bolder'>Administración de Productos</h3>
          <p className='select-text'>Agrega, modifica o elimina a los productos</p>
        </div>
        <div className="d-flex justify-content-end">
          <Button icon="pi pi-plus" label="Agregar producto" className="p-button-success" onClick={() => setDisplayModalProducto(true)} />
        </div>
      </div>

      {/* Donde va la datatable y los datos */}
      <div className='w-100'>
        <div className="">
          {
            !productos ? <SkeletonProductos></SkeletonProductos> :
              <DataTable emptyMessage="Sin registros para mostrar" value={productos} stripedRows
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} productos" header={header} globalFilter={globalFilter}>
                <Column field="Imagen" header="Imagen" body={imageBodyTemplate}></Column>
                <Column field="Nombre" sortable header="Nombre"></Column>
                <Column header="Acciones" body={AccionesProducto} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
          }

        </div>
      </div>
      {/* Apartado para modales y ventanas desplegables */}
      {/* Modal que servira para el mantenimiento de los Categorias*/}
      <Dialog header={producto_?.Id ? "Edición de producto" : "Mantenimiento de producto"} visible={displayModalProducto} draggable={true} maximizable={true} className="modal-size" onHide={() => Hide()}>
        <FormProducto Hide={Hide} producto_={producto_} setProducto={setProducto} />
      </Dialog>

      <Dialog visible={productoDeleteDialog} style={{ width: '450px' }} header="Mensaje de Confirmación" modal footer={deleteproductoDialogFooter} onHide={hideDeleteProductoDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle me-3" style={{ fontSize: '1rem' }} />
          {producto_ && <span>Estás seguro de eliminar el producto <b>{producto_.Nombre}</b>?</span>}
        </div>
      </Dialog>
    </div>
  )
}

export default Productos
