import React, { useState } from 'react'
import { IoMailSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast';
import { motion } from "framer-motion"
import { useEffect } from 'react';
import { UserAuth } from '../../services/AuthContext';


function ForgetPassword() {
    const [forme, setForme] = useState({});
    const [errors, setErrors] = useState({});
    const { ForgetPassword } = UserAuth();

    const setField = (field, value) => {
        setForme({
            ...forme,
            [field]: value
        })

        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    /// Funcion que valida los campos
    const validateForm = () => {
        const { email } = forme
        const newErrors = {}
        if (!email || email === '') newErrors.email = 'El email no puede ir vacío.'
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) newErrors.email = 'Formato de email incorrecto.'
        return newErrors;
    }

    const handleReset = async () => {
        const formErrors = validateForm();
        const { email } = forme
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            try {
                await ForgetPassword(email);
            } catch (error) {
                toast.error('Ha ocurrido un error al enviar el correo de restauración de contraseña.')
                console.log(error);
            }
        }
    }

    useEffect(() => {
        document.title = 'Olvidé mi contraseña | Luz & Color Pinturas Protecto®'
    }, [])


    return (
        <>
            <div
                className="contenedor-reset">
                <div
                    className="aside-left-reset">
                    <h1>Luz & Color</h1>
                    <p>Tu tienda de Pinturas Protecto <br />
                    </p>
                    <Link to="/Login">  <button className='btn btn-success btn-logins'>Regresar al inicio de sesión</button></Link>
                </div>
                <motion.div
                    initial={{
                        x: -20,
                        opacity: 0
                    }}
                    animate={
                        {
                            x: 0,
                            opacity: 1,
                            transition: {
                                duration: 0.5
                            }
                        }
                    }
                    exit={{
                        x: -20,
                        opacity: 0,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    className="input-container">
                    <h1>Olvidé mi contraseña</h1>
                    <p className='message-reset'>Ingresa tu email, te enviaremos un correo donde podras restaurar tu contraseña para acceder nuevamente a nuestro sitio.</p>
                    <div className="form-login">
                        <div className="login__field">
                            <IoMailSharp className="login__icon "></IoMailSharp>
                            <input type="email"
                                className={!errors.email ? 'login__input__reset' : 'error-borde-login login__input__reset'}
                                value={forme.email}
                                onChange={e => setField('email', e.target.value)}
                                placeholder="Email" />
                            <div className="invalid">{errors.email}</div>
                        </div>
                        <button className='btn-send-reset' onClick={() => handleReset()}>Enviar restauración de contraseña</button>
                        <Link to="/Login" className='btn btn-success btn-logins nav-login mb-3'>Regresar al inicio de sesión</Link>
                    </div>
                </motion.div>
            </div>
        </>

    )

}

export default ForgetPassword