import React, { useEffect, useState } from "react";
import { IoSearchCircle } from 'react-icons/io5';
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
import './Categories.css'
import SkeletonCard from "../../skeletons/SkeletonCard";
import ListCardProduct from "../../components/ListCardProduct/ListCardProduct";
import PaginationOwn from "../../components/Pagination/Pagination";
import Filter from "./Filter";
import Filtersmall from "./Filtersmall";
import { motion } from "framer-motion"
import BotonScrollUp from "../../components/BotonScrollUp/BotonScrollUp";
const Categories = () => {
    ///Nuestras constantes para tener control de las variables y eventos
    const [listaProductos, setListaProductos] = useState([]);
    const [Categoria, SetCategoria] = useState('Todos los productos disponibles');
    const [Busqueda, SetBusqueda] = useState('');
    //variables directas para la paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(6);

    /// usaremos la filtracion local para cambiar los elementos
    const [filtrado, setFiltrado] = useState([]);
    ///listado de todos los elementos
    const [Active, setActive] = useState('all');
    /// Actual Page

    const registrosfiltrados = filtrado.filter(
        producto =>
            producto.Nombre.toLocaleLowerCase().includes(Busqueda.toLocaleLowerCase()) || !Busqueda
    );
    ///Funciones asincronas para traer los datos directamente de firebase
    const getDataProducto = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "products"), orderBy("Nombre"))
        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setListaProductos(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))

            setFiltrado(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }
    /// para la paginacion
    const indexOfLastItem = currentPage * postsPerPage;
    const indexOfFirstItem = indexOfLastItem - postsPerPage;
    const currentItems = registrosfiltrados.slice(indexOfFirstItem, indexOfLastItem);

    ///change page function scrolling top
    const paginate = pageNumber => {
        setCurrentPage(pageNumber)
    };

    /// funcion que estara a la escucha si cambia algo de la lista de los productos
    useEffect(() => {
        setCurrentPage(1);
        document.title = 'Luz & Color Pinturas Protecto® | ' + Categoria;
        /// traemos nuestros productos los cuales se actualizaran dependiendo de los cambios en vivo
        getDataProducto();
    }, [])

    return (
        <div className="container-categories">
            <div className="container-xxl">

                <div className="row">
                    {/* aside categories */}
                    <div className="col-xl-3 col-lg-3 categories-others">
                        {/* Filtro para mostrar las respectivas categorias de los productos */}
                        <Filter listaProductos={listaProductos} setFiltrado={setFiltrado} Active={Active} setActive={setActive} SetCategoria={SetCategoria} Categoria={Categoria} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                    {/* Seccion dedicada a los productos y su funcion de buscador */}
                    <div className="col-xl-9 col-lg-9">
                        <Filtersmall listaProductos={listaProductos} setFiltrado={setFiltrado} Active={Active} setActive={setActive} SetCategoria={SetCategoria} Categoria={Categoria} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        {/* Contenido donde estara el buscador y los demas elementos graficos */}
                        <div className="head-cat">
                            {
                                Busqueda != '' ? <h1 className="header-cats"> {registrosfiltrados.length == 0 ? 'Sin resultados disponibles' : 'Resultados encontrados'} </h1> : <h1 className="header-cats"> {Categoria ? Categoria : 'Todos los productos disponibles'}</h1>
                            }

                            <p className="number"> Mostrando {registrosfiltrados.length ? registrosfiltrados.length : '0'} resultado(s) disponible(s) </p>

                        </div>
                        {/* Consultamos si trae resultados */}
                        <div>
                            {
                                registrosfiltrados.length == 0 ? '' :

                                    Busqueda != '' ? <p className="Finder">Buscando su término '{Busqueda}'</p> : ''
                            }
                            {
                                registrosfiltrados.length == 0 ? <p className="Finder">No hay resultados para mostrar</p> : ''
                            }
                        </div>


                        {/* Buscador */}
                        <div className="wrapper">
                            <IoSearchCircle className="iconoBusqueda" size="3.5rem"></IoSearchCircle>
                            <input type="search" onChange={(event) => {
                                SetBusqueda(event.target.value)
                                /// Seteamos la primera pagina
                                setCurrentPage(1);
                            }} className="form-control barra-Busqueda" placeholder="Ingrese su búsqueda..."></input>
                        </div>



                        {/* Mostraremos los productos o un skeleton en caso sea necesario filtrados o traidos directamente*/}
                        <motion.div layout className="row ">

                            {
                                registrosfiltrados.length > 0 ?
                                    // Componente que trae el listado de los productos
                                    <div className="contenedor-lista">
                                        <ListCardProduct registrosfiltrados={currentItems}></ListCardProduct>
                                    </div>


                                    :
                                    listaProductos && [1, 2, 3, 4, 5, 6].map((n) => {
                                        return (
                                            <>
                                                <div key={n} className="col-xl-4 col-lg-6 mb-2">
                                                    <SkeletonCard key={n}></SkeletonCard>
                                                </div>
                                            </>
                                        )
                                    })

                            }
                        </motion.div>
                        <PaginationOwn postsPerPage={postsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPosts={registrosfiltrados.length} paginate={paginate} />
                    </div>
                </div>
            </div>
            <BotonScrollUp />
        </div>

    )
}



export default Categories;