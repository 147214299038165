import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { IoLogoGoogle, IoMail, IoLogoFacebook } from "react-icons/io5";
import ContactModal from '../../../components/ContactModal/ContactModal';
import { UserAuth } from '../../../services/AuthContext';
import LinkAccountFacebook from '../../../services/LinkAccountFacebook';
import LinkAccountGoogle from '../../../services/LinkAccountGoogle';
import FormLinkPassword from '../Form/FormLinkPassword';

function Providers({ providers }) {
    const { user } = UserAuth();
    const [usuario, setuser] = useState(user)
    const [googlecheck, setGoogleCheck] = useState(false);
    const [emailcheck, setEmailCheck] = useState(false);
    const [facebookcheck, setFacebookCheck] = useState(false);
    const [proveedor, setproveedor] = useState(null);
    ///para el modal a desplegar
    const [Estadomodal, setEstadoModal] = useState(false);
    /// validaremos si el usuario actual trae uno o mas providers, esto con motivo de linkear las cuentas en caso no sea asi
    /// vincularemos nuestra cuenta con facebook
    const LinkFb = async () => {
        try {
            setuser(await LinkAccountFacebook())
        } catch (error) {
            toast.error("Ha ocurrido un error inesperado")
        }
        setProveedoresActive();
    }
    /// vincularemos nuestra cuenta con Google
    const linkgg = async () => {
        try {
            setuser(await LinkAccountGoogle());
        } catch (error) {
            toast.error("Ha ocurrido un error inesperado")
        }
        /// colocamos las cuentas activas que se acaban de enlazar
        setProveedoresActive();
    }
    /// funcion que determinara si los proveedores se encuentran ya enlazdos
    const setProveedoresActive = () => {
        const filtradoGoogle = usuario?.providerData?.filter((provider) => provider?.providerId?.includes("google.com"));
        if (filtradoGoogle?.length > 0) {
            setGoogleCheck(true);
        }
        const filtradoMail = usuario?.providerData?.filter((provider) => provider?.providerId?.includes("password"));
        if (filtradoMail?.length > 0) {
            setEmailCheck(true);
        }
        const filtradoFacebook = usuario?.providerData?.filter((provider) => provider?.providerId?.includes("facebook.com"));
        if (filtradoFacebook?.length > 0) {
            setFacebookCheck(true);
        }
    }
    /// confimacion para el enlace con facebook
    const ConfirmLinkFb = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estás seguro de enlazar tu cuenta con Facebook?',
            acceptLabel: "Si, estoy seguro",
            acceptIcon: 'pi pi-check',
            rejectLabel: 'No, cancelar',
            icon: 'pi pi-exclamation-triangle',
            accept: () => LinkFb(),
            reject: () => '',
        });


    }
    /// confimacion para el enlace con google
    const ConfirmLinkGoogle = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estás seguro de enlazar tu cuenta con Google?',
            acceptLabel: "Si, estoy seguro",
            acceptIcon: 'pi pi-check',
            rejectLabel: 'No, cancelar',
            icon: 'pi pi-exclamation-triangle',
            accept: () => linkgg(),
            reject: () => '',
        });

    }
    /// abrir el modal para ir al formulario de enlace
    const openModal = (type) => {
        if (type == 1) {
            setproveedor(1)
        } else {
            setproveedor(2)
        }
        setEstadoModal(!Estadomodal)
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        setProveedoresActive();
    }, [usuario])


    return (
        <>
            {
                googlecheck ?
                    <IoLogoGoogle size="1.5rem"></IoLogoGoogle>

                    :
                    <i class="fab fa-google" onClick={ConfirmLinkGoogle}></i>
            }

            {
                emailcheck ?
                    <IoMail size="1.5rem"></IoMail>
                    :
                    <IoMail className='provider-email-unliked'
                        /// veremos si el usuario este chequeado con google en caso no se le envia otro provider
                        onClick={googlecheck ? () => openModal(1) : () => openModal(2)} size="1.5rem"></IoMail>
            }

            {
                facebookcheck ?
                    <IoLogoFacebook size="1.5rem" />
                    :
                    <IoLogoFacebook id='LinkFacebook' className='provider-facebook-unliked' onClick={ConfirmLinkFb} size="1.5rem" />
            }
            <ConfirmPopup />

            {/* Apartado del Modal */}
            <ContactModal
                estado={Estadomodal}
                CambiarEstado={setEstadoModal}
                Footer={false}
                Titulo="Formulario de enlace de cuenta "
            >
                <FormLinkPassword email={user?.email} CambiarEstado={setEstadoModal} setuser={setuser} user={usuario} provider={proveedor} setProveedoresActive={setProveedoresActive} />
            </ContactModal>
        </>
    )
}

export default Providers