import React from 'react'
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";
import { motion } from "framer-motion"

function SkeletonCardP() {
    return (
        <motion.div layout className="skeleton-wrapper-card light mt-1">
            <SkeletonElement type="cardp"></SkeletonElement>
            <SkeletonElement type="text-cardp"></SkeletonElement>
            <SkeletonElement type="text-cardp"></SkeletonElement>
            <Shimmer />
        </motion.div>
    )
}

export default SkeletonCardP