import React from 'react'
import './Login.css'
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { UserAuth } from '../../services/AuthContext'
import LoginForm from './LoginForm';
import { useState } from 'react';
import RegisterForm from './RegisterForm';
import { IoArrowBackOutline } from 'react-icons/io5';

const Login = () => {
    const { user } = UserAuth();
    const [alternate, setAlternate] = useState(false)

    /// usaremos el hook usehistory para redirigir
    const history = useHistory();
    useEffect(() => {
        document.title = 'Iniciar Sesión | Luz & Color Pinturas Protecto®'
    }, [])

    return (
        <>
            <div className="contenedor-login">
                <div className="aside-left">
                    <h1>Luz & Color</h1>
                    <p>Tu tienda de Pinturas Protecto <br />
                    </p>
                    <Link to="#" onClick={history.goBack}><button className='btn btn-success btn-logins'><IoArrowBackOutline className='me-1' size='1.2rem'></IoArrowBackOutline> Regresar</button></Link>
                </div>
                {
                    !alternate ?
                        <LoginForm alternate={alternate} setAlternate={setAlternate} /> :
                        <RegisterForm alternate={alternate} setAlternate={setAlternate} />
                }

            </div>
        </>
    )
}

export default Login