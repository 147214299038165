import React, { memo } from "react";
import CardProduct from "../CardProduct/CardProduct";
import { motion } from "framer-motion"

const ListCardProduct = memo(({ registrosfiltrados }) => {
    return (
        <>
            {
                registrosfiltrados?.map(producto => {
                    return (
                        <motion.div layout className="" key={producto.key}>
                            <CardProduct key={producto.key} producto={producto}></CardProduct>
                        </motion.div>
                    )
                })
            }
        </>
    )
})

export default ListCardProduct;