import React, { useState } from 'react'
import './Project.css'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// import required modules
import { Lazy, EffectCoverflow, Pagination } from "swiper";
// lazy load


/// Componente que servira de galeria para los proyectos pasando como props la galeria de cada proyecto
function Galeria({ galeria }) {
    return (
        <>

            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                lazy={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Lazy]}
                className="mySwiper"
            >
                {
                    galeria.map(Imagen => {
                        return (
                            <SwiperSlide key={Imagen} className='slid'>
                                {/* Agregamos el componente de lazy load para optimizar la carga */}
                                <img src={Imagen} alt={Imagen} key={Imagen} className="swiper-lazy" />
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

        </>
    )
}

export default Galeria