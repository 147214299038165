import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import db from '../../Firebase/firebaseConfig';
import './Add.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/lazy";
import "swiper/css";
import "swiper/css/effect-cards";

import { Lazy, Autoplay, EffectCards } from "swiper";
import SkeletonCardAd from "../../skeletons/SkeletonCardAd";


const Ads = () => {
    const [listaAds, setListaAds] = useState(null);

    const getDataAds = () => {
        /// ocuparemos el onsnapshot por si se hace un camnbio este sea instanteano
        const q = query(collection(db, "ads"))

        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setListaAds(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }
    /// traemos toda la informacion directa desde firebase
    useEffect(() => {
        getDataAds();
    }, [])
    return (
        <>
            <Swiper
                spaceBetween={30}
                effect={"cards"}
                grabCursor={true}
                loop={true}
                lazy={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                modules={[Lazy, EffectCards, Autoplay]}
                className="Galeria">
                {
                    /// traemos nuestra lista entera de categorias
                    listaAds && listaAds.map(ads => {
                        return (
                            <SwiperSlide key={ads.key} className="slide-ad">
                                <img
                                    src={ads.url}
                                    className="swiper-lazy imgAd"
                                    key={ads.key}
                                    alt={ads.url}
                                />
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </SwiperSlide>
                        )
                    })
                }

                {
                    !listaAds && [1, 2, 3].map((n) => {
                        return (
                            <SwiperSlide key={n}>
                                <SkeletonCardAd key={n}></SkeletonCardAd>
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>



        </>

    )
}

export default Ads;