import { deleteObject, ref } from 'firebase/storage'
import { storage } from '../Firebase/firebaseConfig'

/// para borrar archivos en el storage
const DeleteFile = (filePath) => {
    return new Promise(async (resolve, reject) => {
        const storageRef = ref(storage, filePath)
        try {
            await deleteObject(storageRef)
            resolve('si')
        } catch (error) {
            reject(error);
        }
    })
}

export default DeleteFile