import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { IoHeart } from 'react-icons/io5';
import toast from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import { useGlobalState } from '../../services/GlobalState';
import DeleteWishList from '../../services/DeleteWishList';

function LikedsFull({ currentItems, currentPage, setCurrentPage }) {
    const [usuario, setUsuario] = useGlobalState('userActive');
    const [Likeds, setLikeds] = useGlobalState('whishListProducts');
    /// funcion para eliminar elementos de la lista
    const removeElements = async (nombre, producto) => {
        setLikeds(await DeleteWishList(nombre, usuario, producto))
        setUsuario(JSON.parse(localStorage.getItem('user')))
        /// Si la paginacion nos arroja que nuestros actuales items se eliminan de la pagina, la paginacion retrocedera una vez
        if (currentItems.length == 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    return (
        <motion.div layout>
            {
                currentItems?.map((producto => {
                    return (
                        <motion.div layout className="producto-liked" key={producto.key}>
                            <IoHeart className='badge-liked' data-tip data-for="quitar" onClick={() => removeElements(producto.Nombre, producto)} />
                            <div layout className="contenido-Liked">
                                <div className="img-liked">
                                    <div className="contenedor-img">
                                        <LazyLoadImage
                                            effect="opacity"
                                            alt={producto.Imagen}
                                            src={producto.Imagen}// use normal <img> attributes as props
                                            key={producto.Imagen}
                                            className='img-liked-img' />
                                    </div>
                                </div>
                                <div className="info-liked">
                                    <h2>{producto.Nombre} </h2>
                                    <div className="liked-details">
                                        <h5>Descripción: </h5>
                                        <p>{producto.Descripcion}</p>
                                        <ReactTooltip id="quitar"
                                            type='light'
                                            place='left'
                                            effect='solid'
                                            className='tooltip-regresar'>
                                            Quitar de mis me gusta
                                        </ReactTooltip>
                                    </div>
                                    <div className="footer-liked">
                                        <Link className='btn-liked' to={`/Product/${producto.key}`}>Ver producto</Link>
                                    </div>

                                </div>

                            </div>

                        </motion.div>


                    )
                }))
            }  </motion.div>
    )
}

export default LikedsFull