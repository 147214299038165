import React from "react";
import { Link } from "react-router-dom";
import './NotFound.css'
const NotFound = () => {
    return (
        <>
            <div className="contenedor-notfound">
                <p>404</p>
                <h1>Página no Encontrada...</h1>
                <span>Esto es posible a que escribiste mal una dirección o esta página no existe.</span>
                <Link to={`/`}>
                    Regresar al inicio</Link>
            </div>
        </>
    )
}

export default NotFound;