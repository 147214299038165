import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { UserAuth } from '../../../services/AuthContext';
import { IoAddCircle, IoCloseCircle } from "react-icons/io5";
import './ModalmodPic.css'
import { useState } from 'react';
import toast from 'react-hot-toast';
/// para un id unico importamos la libreria uuid
import { v4 as uuidv4 } from 'uuid'
import UploadFile from '../../../services/UploadFile';
import { updateProfile } from 'firebase/auth';
import DeleteFile from '../../../services/DeleteFile';
import { doc, updateDoc } from 'firebase/firestore';
import db from '../../../Firebase/firebaseConfig';
import { useGlobalState } from '../../../services/GlobalState';
/// Componente donde se realizara la modificacion de la foto de la cuenta
function ModalmodPic({ setEstadoModal, Estadomodal }) {
    const [usuario, setUsuario] = useGlobalState('userActive');
    const { user } = UserAuth();
    /// estado que nos servira para hacer la subida y el cambio en el usuario
    const [file, setFile] = useState(null);
    /// seteamos la foto del usuario la cual se manipulará para la subida y el cambio
    const [photoURL, setPhotoURL] = useState(user?.photoURL)
    /// para cargar
    const [loading, setLoading] = useState(false);
    /// funcion para detectar la presencia de una fotografia
    const handleChange = (e) => {
        const file = e.target.files[0]
        /// verificiaremos si se ha seleccionado una foto para la subida
        if (file && file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            setFile(file);
            /// seteamos la imagen para que esta se muestre al momento del cambio
            setPhotoURL(URL.createObjectURL(file));
        } else if (!file) {
            toast.error('Elige una fotografía para continuar.')
        }
        /// validamos si es una foto
        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            toast.error('Formato de archivo no válido')
            return false;
        }
    }

    const SubmitChange = async () => {
        setLoading(true);
        let url;
        /// nuestra referencia al usuario para la modificacion
        const userRef = doc(db, "users", user?.uid);
        try {
            /// si el archivo aun esta en memoria lo nombramos personalizado
            /// y lo subirmos, pasandole la path
            if (file) {
                const ImageName = uuidv4() + '.' + file?.name?.split(".")?.pop();
                url = await UploadFile(file, `profile/${user?.uid}/${ImageName}`);
            };

            /// en caso el usuario tenga una fotografia la borraremos para sustituirla por la nueva
            if (user?.photoURL) {
                const prevImage = user?.photoURL?.split(`${user?.uid}%2F`)[1]?.split('?')[0];
                if (prevImage) {
                    try {
                        await DeleteFile(`profile/${user?.uid}/${prevImage}`)
                    } catch (error) {
                    }
                } else {
                    /// No hacer nada
                }
            }

            /// hacemos la subida del archivo y el cambio de fotografia
            await updateProfile(user, {
                photoURL: url
            });
            await updateDoc(userRef, {
                "fotoPerfil": url,
            });
            /// actualizamos la variable de sesion y el estado global
            const new_user = { ...usuario, fotoPerfil: url }
            setUsuario(new_user);
            localStorage.setItem("user", JSON.stringify(new_user));
            setLoading(false)
            toast.success('Fotografía actualizada con éxito')
            setEstadoModal(!Estadomodal)
            document.body.style.overflow = 'unset';
        } catch (error) {
            toast.error('Ha ocurrido un error con la operación.')
            console.log(error);
        }
    }

    const cerrarmodal = () => {
        setEstadoModal(!Estadomodal)
        document.body.style.overflow = 'unset';
    }

    return (
        <div className='container-modal-pic'>
            <label htmlFor="PictureProfile">
                {
                    loading ?
                        <input
                            accept='image/*'
                            id='PictureProfile'
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                            disabled
                        />
                        :
                        <input
                            accept='image/*'
                            id='PictureProfile'
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                        />
                }

                <LazyLoadImage
                    effect="opacity"
                    alt="Account-picture"
                    src={photoURL ? photoURL : process.env.PUBLIC_URL + '/default-user.png'}
                    key={user?.uid} className="account-photo-modal" />
            </label>
            <p>{user?.displayName}</p>
            <p className='advertice'>Este cambio puede tomar un rato en surtir efecto, sé paciente.</p>
            {
                loading ?
                    <p className='uploading pt-2'><i className="pi pi-spin pi-spinner me-2" />Espera, subiendo tu fotografía..</p>
                    : ''
            }
            <div className="buttons-modal-pic">
                {
                    /// definiremos si esta cargando el boton se deshabilita y si no hay un archivo el boton queda desactivado 
                    /// hasta que se elige uno, una fotografia como tal.
                    loading || !file ?
                        <div>
                            <button disabled onClick={() => SubmitChange()} className='btn btn-success save-btn'><IoAddCircle className='me-2' size="1.2rem"></IoAddCircle>Guardar</button>
                        </div>

                        :
                        <button onClick={() => SubmitChange()} className='btn btn-success save-btn'><IoAddCircle className='me-2' size="1.2rem"></IoAddCircle>Guardar</button>
                }

                <button onClick={() => cerrarmodal()} className='btn btn-danger red-btn'><IoCloseCircle className='me-2' size="1.2rem"></IoCloseCircle>Cancelar</button>
            </div>
        </div>

    )
}

export default ModalmodPic