import './Home.css'
import { IoLogoInstagram } from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io5';
import { IoCallOutline } from 'react-icons/io5';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import Ads from '../../components/Ads/Ads';
import { useEffect, useState } from 'react';
import ContactModal from '../../components/ContactModal/ContactModal';
import BotonScrollUp from '../../components/BotonScrollUp/BotonScrollUp';
import Form from '../../components/ContactModal/Form';

const Home = () => {
    /// modal de contacto
    const [Estadomodal, setEstadoModal] = useState(false);
    /// Modal de contacto
    const AbrirModal = () => {
        setEstadoModal(!Estadomodal);
        document.body.style.overflow = 'hidden';
    }
    // utilizaremos un useEffect para lanzar el modal al inicio
    /// validaremos si el usuario ya vio el modal una vez para evitar que lo vea multiples veces
    useEffect(() => {
        document.title = 'Luz & Color Pinturas Protecto® '
    }, [])
    return (
        <>
            <div className='contenedor-index'>
                {/* Pagina de inicio para luz y color mostrando toda la informacion llamativa y demas */}


                {/* Primera seccion */}
                <div className='First container-xxl' id='First'>
                    <div className='principal'>
                        <p className='bienvenida'>Bienvenidos a</p>
                        <h1>Tu tienda de Pinturas <span className='Protecto'>Protecto</span> <span className='LuzColor'>Luz & Color</span>  </h1>
                        <p className='descripcion'>Tu tienda sonsonateca con el mejor producto en pinturas y accesorios, en este portal encontrarás
                            toda la información relacionada a nuestro trabajo de pintado y asesoría personalizada.
                            Pintamos a todo Color <br /> <span className='El-Salvador fw-bolder'>El Salvador.</span>
                        </p>
                        <a href='#Second'>
                            <button className='btn btn-success comenzar-btn'>Comenzar ahora</button>
                        </a>
                    </div>
                    <div className='img-principal'>
                        <img src={process.env.PUBLIC_URL + '/principal.png'}></img>
                    </div>
                </div>
                {/* Segunda seccion */}
                <div className='Second' id='Second'>
                    <div className='container-xxl d-flex justify-content-center'>
                        <h1>¡Infórmate de nuestros servicios y promociones a través  de nuestras redes sociales y números de teléfono!</h1>
                    </div>
                    <a href='https://www.instagram.com/luzycolorpinturas/?igshid=1erdkk0yqmlu8' target="_blank"><p> <IoLogoInstagram size="1.2em" className='me-2' /> luzycolorpinturas</p>  </a>
                    <a href='https://www.facebook.com/luzycolorpinturas' target="_blank"><p> <IoLogoFacebook size="1.2em" className='me-2' /> Luz Y Color</p></a>
                    <a href="tel:24016550" onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"><p><IoCallOutline size="1.2em" className='me-2' /> 2401-6550 </p></a>
                    <a href='https://wa.me/76048556' target='_blank' ><p><IoLogoWhatsapp size="1.2em" className='me-2' /> 7604-8556 / 7604-8185</p></a>
                    <a href="https://www.google.com/maps/place/Pinturas+Luz+Y+Color+Sonsonate+(Protecto)/@13.710688,-89.730161,16z/data=!4m5!3m4!1s0x0:0xf85f364c271f8482!8m2!3d13.7106881!4d-89.730161?hl=es-419" target="_blank"> <p><IoLocationOutline size="1.2em" className='me-2' /> Nuestra ubicación</p></a>
                    <a href='#Third'>
                        <button className='btn btn-success comenzar-btn mt-3'>Nuestras Promociones</button>
                    </a>
                </div>
                {/* Tercera Seccion */}
                <div className='Third' id='Third'>

                    <Ads></Ads>
                    <div className='headerThird '>
                        <div className='container-xxl'>
                            <h1>Promociones vigentes</h1>
                            <p className='prom'>¡Explora nuestras promociones y noticias exclusivas que Pinturas Luz & Color Protecto tiene para tí, si alguna es de tu interés comunicate con nosotros!</p>
                            <br></br>
                            <p className='proyecto'>Con Luz & Color Pinturas Protecto obtienes los mejores precios y promociones para tu proyecto personal o de trabajo.</p>
                            <div className='row mt-3'>
                                <a href='#Fourth'>
                                    <button className='btn btn-success comenzar-btn'>¿Porqué Nosotros?</button>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Cuarta Seccion */}
                <div className='Fourth ' id='Fourth'>
                    <div className='nosotros'>
                        <div className=''>
                            <p className='count-you'>Pero, te preguntarás...</p>
                            <h1>¿Porqué nosotros somos tu mejor elección?</h1>
                            <p className='principal-p'>Luz & Color es una empresa sonsonateca dedicada a la comercialización, distribución, suministro y aplicación en servicio de pinturas marca Protecto.
                                Buscando ofrecer lo mejor a nuestros clientes nos complace informarle que se posee diferentes líneas de productos: industriales, epoxicos, anticorrosivos, tráfico, látex acrílico, látex satinado, esmaltes, aceites, barnices para madera e impermeabilizantes etc.
                                Todos ellos con el aval y asesoría técnica de la fábrica Pintuco, distribuidor exclusivo para Centroamérica de pinturas Protecto.</p>

                            <a href='#Fifth'>
                                <button className='btn btn-success comenzar-btn'>Nuestros Servicios</button>
                            </a>
                        </div>

                    </div>
                    <div className='nosotros-img  img-us'>
                        <img className='img-reference ' src={process.env.PUBLIC_URL + '/us.png'}></img>
                    </div>

                </div>

                <div className='Fifth container-xxl' id='Fifth'>
                    <div className='services-ours'>
                        <h1>Nuestros Servicios Especializados</h1>
                        <p className='services-luz'>En Luz & Color Pinturas Protecto:</p>
                        <p className='in'>Estamos comprometidos con la calidad de nuestros servicios y todo lo que tenemos para ofrecerte, con precios competitivos y producto de la mejor gama
                            los cuales se detallan a continuación:
                        </p>
                        <a href='#Final'>
                            <button className='btn btn-success comenzar-btn'>¿Qué Esperas?</button>
                        </a>
                    </div>
                    <div className='services'>
                        <ol>
                            <li>Asesoría en la combinación de colores e igualación de colores por computadora.</li>
                            <li>Amplio portafolios de pinturas arquitectónicas (Acrílicas, satinadas, esmaltes base solvente, pintura de tráfico, maderas entre otros).</li>
                            <li>Cotizaciones sin compromiso.</li>
                            <li>Suministro y Aplicación de pinturas Industriales. Acorde a proyectos y acorde a su necesidad.</li>
                            <li>Capacitación técnica personal.</li>
                            <li>Elaboración de especificaciones técnicas.</li>
                            <li>Asesoría técnica antes, durante y post aplicación.</li>
                        </ol>
                    </div>
                </div>

                <div className='Final ' id='Final'>
                    <div className='container d-flex flex-column justify-content-cente'>
                        <h1>¿Qué esperas para empezar a disfrutar de un mundo lleno de colores?</h1>
                        <p className='mt-3'>Con Luz & Color Pinturas Protecto puedes iniciar desde ya, con una llamada o un correo electrónico
                            llevamos color y alegría hasta la puerta de tu casa <br /> asegurate de llenar todo el formulario para la mejor información posible para contactarnos contigo.
                        </p>

                    </div>
                    <span className='LuzColor-2'>Luz & Color</span>
                    {/* Aperturamos y cerramos el modal de contacto */}
                    <p className='contact-btn' onClick={() => { AbrirModal() }}>¡Contáctanos Ya!</p>
                </div>

                {/* Apartado del Modal */}
                <ContactModal
                    estado={Estadomodal}
                    CambiarEstado={setEstadoModal}
                    Footer={true}
                    Titulo="Formulario de Contacto"
                >
                    <Form CambiarEstado={setEstadoModal} />
                </ContactModal>
                <BotonScrollUp />
            </div>

        </>
    )
}

export default Home;