import React, { useEffect, useState } from 'react'
import { collection, doc, getDoc, getDocs, query, where, limit, orderBy } from "firebase/firestore";
import db from '../../Firebase/firebaseConfig';
/// Traemos la libreria de swipper para los sliders
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import SkeletonCard from '../../skeletons/SkeletonCard';
import { motion } from "framer-motion"
import { IoAlertCircleSharp } from 'react-icons/io5';
/// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import GetWishList from '../../services/GetWishList';
import { useGlobalState } from '../../services/GlobalState';

function Recomend({ Pcopy, setPcopy, ListaP, setFavorito }) {
    const [ListaProductos, setListaProductos] = useState(null);
    const [usuario] = useGlobalState('userActive');
    // traemos los productos dependiendo de la categoria que nuestro producto tenga
    const getDataProductoParamCat = async () => {
        const listaProductos = [];
        const q = query(collection(db, "products"), where("Categoria", "array-contains-any", Pcopy.Categoria), limit(8))
        const datos = await getDocs(q);
        datos.forEach((doc) => {
            listaProductos.push({
                ...doc.data(),
                key: doc.id
            });
        })
        setListaProductos(listaProductos);
        setPcopy(Pcopy);
    }

    /// funcion para setear nuevamente al producto principal
    const getDataProductoParam = async (ide) => {
        const docRef = doc(db, "products", ide);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setPcopy({
                ...docSnap.data(),
                key: docSnap.id
            })
        } else {
            setPcopy(null);
        }
    }

    /// validar si existe en la lista de me gusta y whishlist
    const validateList = async (nombre) => {
        /// traemos el usuario directamente del que esta en sesion y no hacemos una peticion para cada verificacion
        const filtrado = usuario.wishlist.filter((productos) => productos.Nombre.includes(nombre))
        if (filtrado.length > 0) {
            setFavorito(true);
        } else {
            setFavorito(false);
        }
    }

    /// Funcion encargada de renderizar nuevamente el producto mediante el click al elemento
    function Datos(id) {
        window.scrollTo(0, 0);
        getDataProductoParam(id);
        return;
    }


    useEffect(() => {
        validateList(Pcopy?.Nombre)
        document.title = 'Luz & Color | ' + (Pcopy?.Nombre);
        getDataProductoParamCat();
    }, [Pcopy])

    return (
        <>
            {
                // Incluimos esta condicional para que nos traiga solo productos relacionados y en caso no tenga notificar al usuario
                ListaProductos?.length <= 1 ?
                    <div className='not-rel'>
                        <h1>No se encontraron productos relacionados!</h1>
                        <p>Esto es posible a que este producto no tiene ningún otro producto relacionado para su uso.</p>
                        <IoAlertCircleSharp className="not-content" size="30px"></IoAlertCircleSharp>
                    </div>
                    :
                    <div className='rel-products'>
                        <h1>Productos relacionados</h1>
                        <p className='enbase'>En base al producto seleccionado te mostramos estos resultados: </p>
                        {/* Size Large */}
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={10}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]} className="related-products"
                        >
                            {
                                /// traemos nuestra lista entera de categorias
                                ListaProductos && ListaProductos.map(Producto => {
                                    return (
                                        <SwiperSlide key={Producto.key} >
                                            {/* Evento onclick encargado de setear los datos nuevamente del producto */}
                                            <motion.div layout onClick={() => Datos(Producto.key)}>
                                                <div className="link-card-product" >
                                                    <div className="card-product">
                                                        {/* Se usara cuando se active el ecommerce */}
                                                        {/* <div className="badge">10% OFF</div> */}
                                                        <LazyLoadImage
                                                            effect="opacity"
                                                            alt={Producto.Imagen}
                                                            src={Producto.Imagen}// use normal <img> attributes as props
                                                            key={Producto.key}
                                                            className="img-cardp" />

                                                        <p>{Producto.Nombre}</p>
                                                        <p className="vermas">Ver producto</p>
                                                    </div></div>
                                            </motion.div>
                                        </SwiperSlide>

                                    )
                                })
                            }
                            {
                                !ListaProductos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                    return (
                                        <SwiperSlide key={n}>
                                            <SkeletonCard key={n}></SkeletonCard>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        {/* Size Small */}
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={10}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination, Autoplay]} className="related-products-md"
                        >  {
                                /// traemos nuestra lista entera de categorias
                                ListaProductos && ListaProductos.map(Producto => {
                                    return (
                                        <SwiperSlide key={Producto.key} >
                                            <motion.div layout onClick={() => Datos(Producto.key)}>
                                                <div className="link-card-product" >
                                                    <div className="card-product">
                                                        <LazyLoadImage
                                                            effect="opacity"
                                                            alt={Producto.Imagen}
                                                            src={Producto.Imagen}// use normal <img> attributes as props
                                                            key={Producto.key}
                                                            className="img-cardp" />
                                                        <p>{Producto.Nombre}</p>
                                                        <p className="vermas">Ver producto</p>
                                                    </div></div>
                                            </motion.div>
                                        </SwiperSlide>

                                    )
                                })
                            }
                            {
                                !ListaProductos && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                                    return (
                                        <SwiperSlide key={n}>
                                            <SkeletonCard key={n}></SkeletonCard>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
            }

        </>
    )
}

export default Recomend