import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import SkeletonCard from "./SkeletonCard";
import { Pagination } from "swiper";
const SkeletonProyect = () => {
    return (
        <div className="skeleton-wrapper-Proyect light">
            <div className="proyect-container ">
                <SkeletonElement type="proyect-data "></SkeletonElement>
                <SkeletonElement type="proyect-data2 "></SkeletonElement>
            </div>
            <div className="producto-skeleton">
                <SkeletonElement type="text-cardproyecto"></SkeletonElement>
                <SkeletonElement type="text-cardproyectom"></SkeletonElement>
            </div>
            <Swiper
                slidesPerView={4}
                spaceBetween={20}
                modules={[Pagination]} className="related-products productoskeleton"
            >
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                        return (
                            <SwiperSlide key={n}>
                                <SkeletonCard key={n}></SkeletonCard>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <Swiper
                slidesPerView={1}
                spaceBetween={20}
                modules={[Pagination]} className="related-products-md productoskeleton"
            >
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                        return (
                            <SwiperSlide key={n}>
                                <SkeletonCard key={n}></SkeletonCard>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <Shimmer />
        </div>

    )
}
export default SkeletonProyect;