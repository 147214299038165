import React from 'react'
import { useState } from 'react'
import db from '../Firebase/firebaseConfig';
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
/// Custom hook para traer las categorias de los proyectos
const GetCategoriasProyectos = () => {
    const [categorias, setCategorias] = useState([]);

    const getDataCategorias = () => {
        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "proyects"), orderBy("nombre"))
        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setCategorias(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    return {
        categorias,
        setCategorias,
        getDataCategorias,
    }
}

export default GetCategoriasProyectos
