import React, { useEffect, useState } from "react";
import SkeletonList from '../../skeletons/SkeletonList'
import db from '../../Firebase/firebaseConfig';
import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import "swiper/css/pagination";

const Filter = ({ Active, setActive, setFiltrado, listaProductos, SetCategoria, Categoria, currentPage, setCurrentPage }) => {
    ///variables
    const [lista, setLista] = useState(null);
    /// nuestra funcion que traera el listado
    const fetchCategories = () => {

        /// on snapshot hace actualizaciones en tiempo real
        const q = query(collection(db, "categories"), orderBy("nombre"))

        const unsub = onSnapshot(q, (snapshot) => {
            /// seteamos los arrays contenedores de las listas de los objetos de producto
            setLista(snapshot.docs.map((doc) =>
            ({
                ...doc.data(),
                key: doc.id
            })
            ))
        });
    }

    function setValues(id, nombre) {
        setActive(id)
        SetCategoria(nombre);
        setCurrentPage(1);
    }

    useEffect(() => {
        ///Seteamos las categorias
        fetchCategories();
        document.title = 'Luz & Color Pinturas Protecto® | ' + Categoria;
        if (Active == 'all') {
            setFiltrado(listaProductos)
            return;
        }
        const filtrado = listaProductos.filter((productos) => productos.Categoria.includes(Active))
        setFiltrado(filtrado);
    }, [Active])


    return (
        <div>
            <div>
                {/* Div encargado del comportamiento cuando el dispositivo sea grande */}
                <p className="others">Otras categorías para explorar</p>
                <ul className="categories-aside">
                    <li onClick={() => setValues('all', 'Todos los productos disponibles')} className={Active == 'all' ? 'Active' : ''} >
                        <p>Todos los productos</p>
                    </li>
                    {
                        /// traemos nuestra lista entera de categorias
                        lista && lista.map(categoria => {
                            return (
                                <li key={categoria.key} onClick={() => setValues(categoria.key, categoria.nombre)} className={Active == categoria.key ? 'Active' : ''}>
                                    <p>{categoria.nombre}</p>
                                </li>
                            )
                        })
                    }
                    {/* lista imaginaria de elementos para el skeleton */}
                    {
                        !lista && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                            return (
                                <SkeletonList key={n}></SkeletonList>
                            )
                        })
                    }
                </ul>
            </div>
        </div >

    )
}

export default Filter
