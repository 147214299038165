import React from 'react'
import './Administracion.css'
import { TabView, TabPanel } from 'primereact/tabview';
import { motion } from "framer-motion"
import Productos from './Productos/Productos';
import CategoriasProyectos from './Proyectos/Categorias';
import CategoriasProductos from './Productos/Categorias'
import Anuncios from './Anuncios/Anuncios';
import Proyectos from './Proyectos/Proyectos';

///Componente que contendra todos los mantenimientos de los apartados de la pagina
export const Administracion = () => {
    return (
        <motion.div
            initial={{
                x: -20,
                opacity: 0
            }}
            animate={
                {
                    x: 0,
                    opacity: 1,
                    transition: {
                        duration: 0.5
                    }
                }
            }
            exit={{
                x: -20,
                opacity: 0,
                transition: {
                    duration: 0.3,
                }
            }} className='container-Administracion container-fluid pb-3'>
            <TabView className='card-Administracion-contenedor p-3'>
                <TabPanel header="Categorías de Productos">
                    <CategoriasProductos />
                </TabPanel>
                <TabPanel header="Productos">
                    <Productos />
                </TabPanel>
                <TabPanel header="Categorías de Proyectos">
                    <CategoriasProyectos />
                </TabPanel>
                <TabPanel header="Proyectos">
                    <Proyectos />
                </TabPanel>
                <TabPanel header="Promociones">
                    <Anuncios />
                </TabPanel>
            </TabView>
        </motion.div>
    )
}
